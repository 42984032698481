import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { LiquidRequestDetails } from "../../../data/formdata/liquid-request-details.data";
import { LiquidTruckRequestFormModel } from "../../../liquid-request-form.model";
import { RequestFormModel } from "../../../request-form.model";
import { RequestDetailsComponent } from "../.././request-details-form.component";
import { Subscription } from "rxjs";
import { AppServiceService } from "src/app/services/app-service.service";

@Component({
    selector: 'app-liquid-request-na-details',
    templateUrl: '..\\..\\..\\shared-templates\\request-details-form-template.html',
    styleUrls: ['..\\..\\..\\shared-templates\\request-details-form-template.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [LoggedInUserInfoModel,
        { provide: RequestFormModel, useClass: LiquidTruckRequestFormModel }
    ]
})
export class LiquidRequestDetailsNAComponent extends RequestDetailsComponent implements OnInit,OnDestroy
{

 private subscriptionName: Subscription; //important to create a subscription
 messageReceived: any;
 pageLanguage:string="";
 override requestData:any;
    constructor(userAccessModel: LoggedInUserInfoModel,  requestModel: RequestFormModel,
        toast: ToastrService, router: Router, spinner: NgxSpinnerService, route: ActivatedRoute,private _appServ: AppServiceService) {
        super(userAccessModel,requestModel, toast, router, spinner,route);
        this.subscriptionName= this._appServ.getUpdate().subscribe(message => { //message contains the data sent from service
        this.messageReceived = message;        
        let newLang=sessionStorage.getItem('selectedLanguage');
        if(this.pageLanguage!=newLang && this.pageLanguage!=""){
          this.ngOnInit();
        }
        });
      }

    ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
        this.subscriptionName.unsubscribe();
    }
    ngOnInit(): void {
      this.pageLanguage=sessionStorage['selectedLanguage']; 
        this.requestData = new LiquidRequestDetails();
        this.transportType=1
        this.validateloggedInUser();
        this.readQueryString();
        this.retrieveHeaderValues();
        this.bindfiledVisiblity();
        
    }
    downLoadFISPQFile(){console.log('')}
    bindfiledVisiblity()
    {
      this.fieldVisiblity.requestType=true;
      this.fieldVisiblity.naCountry=true;
      this.fieldVisiblity.startDate=true;
      this.fieldVisiblity.requestor=true;
      this.fieldVisiblity.bussinessunit=true;
      this.fieldVisiblity.articleNumber=true;
      this.fieldVisiblity.product=true;
      this.fieldVisiblity.mfg=true;
      this.fieldVisiblity.freightClass=true;
      this.fieldVisiblity.interModel=true;
  
      // Service Provider
      this.fieldVisiblity.monthlyVolume=false;

      this.fieldVisiblity.volumeType=true;
      this.fieldVisiblity.centerRearUnload=true;
      this.fieldVisiblity.tempRange=true;
      this.fieldVisiblity.tempRequirement=true;
      this.fieldVisiblity.priorProductRestriction=true;
      this.fieldVisiblity.hazardous=true;
      this.fieldVisiblity.kosher=true;
      this.fieldVisiblity.materialTopLoad=true;
      this.fieldVisiblity.foodGradeWash=true;     
      this.fieldVisiblity.nitrogenBlanket=true;
      this.fieldVisiblity.groundVapourRecovery=true;
      this.fieldVisiblity.centerRear=false;
  
      //Shipping->Orgin
      this.fieldVisiblity.originPlant=true;
      this.fieldVisiblity.shippingName=true;
      this.fieldVisiblity.orginCity=true;
      this.fieldVisiblity.orginState=true;
      this.fieldVisiblity.orginCountry=true;
      this.fieldVisiblity.orginZipCode=true;
      //shipping-> destination
      this.fieldVisiblity.destinationPlant=true;
      this.fieldVisiblity.consigneeName=true;
      this.fieldVisiblity.destinationCity=true;
      this.fieldVisiblity.destinationState=true;
      this.fieldVisiblity.destinationCountry=true;
      this.fieldVisiblity.destinationZipCode=true;
  
      //Additional Info
      this.fieldVisiblity.customerPreferedCarrier=false;
      this.fieldVisiblity.costVsService=false;
      this.fieldVisiblity.comments=true;
  
      this.fieldVisiblity.materialDoc=true;
      this.fieldVisiblity.materialLink=true;
      this.fieldVisiblity.sdsLink=true;
      this.fieldVisiblity.sdsAttachment=true;
      this.fieldVisiblity.additionalAttachment=true;
      this.fieldVisiblity.additionalAttachment2=true;
      if(this.isVendorUser){
        this.fieldVisiblity.requestor=false;
        this.fieldVisiblity.customerPreferedCarrier=false;
        this.fieldVisiblity.costVsService=false;
      }
      
    }

    bindConditionalFields(){
      let LiquidReqData=this.requestData as LiquidRequestDetails;
      if(LiquidReqData.naCountry=="Mexico"){
        this.fieldVisiblity.interModel=false;
        this.fieldVisiblity.cleaninCost=false;
        this.fieldVisiblity.dropTrail=false;
        this.fieldVisiblity.equipmentType=true;
        this.fieldVisiblity.typeOfShipment=true;
        this.fieldVisiblity.monthlyKms=LiquidReqData.typeOfShipment=="Dedicated";
        this.fieldVisiblity.totalWeightShipment=true;
        this.fieldVisiblity.materialTopLoad=false;
        this.fieldVisiblity.groundVapourRecovery=false;
        this.fieldVisiblity.isSpecialRequirement=true;
        this.fieldVisiblity.specailRequirementAttachment=LiquidReqData.isSpecialRequirement=="Yes";
        this.fieldVisiblity.multipleDestination=true;

      }
      else{
        this.fieldVisiblity.cleaninCost=true;
        this.fieldVisiblity.dropTrail=true;
      }
    }

    downLoadSpecialReqAttachment(){
      this.fileDownloaded = false;
      this.openpdfEdge((this.requestData as LiquidRequestDetails).specialRequirementAttachment, (this.requestData as LiquidRequestDetails).specialRequirementFileName, (this.requestData as LiquidRequestDetails).specialRequirementFileExtension);
      this.fileDownloaded = true;
    }
    
    retrieveHeaderValues() {
        this.title = "Liquid Truck";
    }

    
      
}