import { UserManagementData } from "./user-management.data";

export class UserManagementForm extends UserManagementData
{
    edit:boolean=false;
    editing:boolean=false;
    status:string="";

    validUserName:boolean=true;
    validFirstName:boolean=true;
    validLastName:boolean=true;
    validUserEmailId:boolean=true;
    validRole:boolean=true;
    validRegion:boolean=true;
}