import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { CurrencyDropDownMaster, VendorDropDownMaster, VendorUserDropdownMaster } from "../shared/vendor-dropdown-master.data";
import { VendorResponseMasterService } from "./vendor-response-master.services";

@Injectable()

export class VendorResponseMasterModel {

    constructor(private service: VendorResponseMasterService) {
        console.log("")

    }

    retrieveVendorMaster(userId: number,transportType:number): Observable<VendorDropDownMaster[]> {
        let subject = new Subject<VendorDropDownMaster[]>();
        this.service.RetrieveVendorMaster(userId,transportType).subscribe(response => {
            let result: VendorDropDownMaster[] = [];

            let apiResponse: any = response;
            let responseResult: any = apiResponse.results;
            if (responseResult.status_id === 200) {

                result=this.convertResponsetoArray(apiResponse.data, false);
                subject.next(result);
            }

        });
        return subject.asObservable();
    }

    retrieveVendorUserMaster(transportType:number):Observable<VendorUserDropdownMaster[]>{
        let subject=new Subject<VendorUserDropdownMaster[]>();
        this.service.RetrieveVendorUserMaster(transportType).subscribe(response=>{
            let result: VendorUserDropdownMaster[]=[];
            let apiResponse:any = response;           
            if(apiResponse.result.status_id ===200){                
                apiResponse.data.forEach((row:any)=>{
                    let resultdata=new VendorUserDropdownMaster();
                    resultdata.Id=Number(row.id);
                    resultdata.Name=row.name;
                    resultdata.VendorId=row.vendor_id;
                    result.push(resultdata);
                }) 
                subject.next(result);
            }
        })
        return subject.asObservable()
    }
    
    retrieveVendorCurrencyMaster(): Observable<CurrencyDropDownMaster[]> {
        let subject = new Subject<CurrencyDropDownMaster[]>();
        this.service.RetrieveVendorCurrencyMaster().subscribe(response => {
            let result: CurrencyDropDownMaster[] = [];

            let apiResponse: any = response;
            let responseResult: any = apiResponse.results;
            if (responseResult.status_id === 200) {

                result=this.convertResponsetoArrayCurrency(apiResponse.data, false);
                subject.next(result);
            }

        });
        return subject.asObservable();
    }


    protected  convertResponsetoArray(response:any, isIsasString:boolean):VendorDropDownMaster[]
{
   let result:VendorDropDownMaster[]=[];
   response.forEach((element:any) => {
      let resultdata=new VendorDropDownMaster();
      if(!isIsasString)
      {
       resultdata.value=Number(element.masterId);
      }
      else
      {
        resultdata.value=(element.masterId);
      }
       resultdata.label=element.masterValue;
       resultdata.email=element.vendorEmail;
       result.push(resultdata);

    });
    return result;
}  

protected  convertResponsetoArrayCurrency(response:any, isIsasString:boolean):CurrencyDropDownMaster[]
{
   let result:CurrencyDropDownMaster[]=[];
   response.forEach((element:any) => {
      let resultdata=new CurrencyDropDownMaster();
      if(!isIsasString)
      {
       resultdata.value=Number(element.masterId);
      }
      else
      {
        resultdata.value=(element.masterId);
      }
       resultdata.label=element.masterValue;
       resultdata.country=element.masterName;
       result.push(resultdata);

    });
    return result;
} 


}




