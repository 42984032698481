import { DropDownMaster } from "src/app/shared/dropdown-master.data";

export class DeliveryTypeData {
    delivery_type_id: number = 0;
    delivery_type_name: string = "";
    created_by: number = 0;
    created_date: Date;

    modified_by: number = 0;
    modified_date: Date;
    is_active: boolean = true;
    transport_type_master: DropDownMaster[] = []
    transport_type: string = "";
}