import { SiteAccessPermission } from "./site-access-permission.data";

export class LoggecInUserInfo {
    
    username!: string;
    firstname!: string;
    lastname!: string;
    email!: string;
    business_unit: any;
    site_access: SiteAccessPermission
    roleId: any;
    userId!: number;
    constructor() {
        this.site_access = new SiteAccessPermission();
    }
}