import { RequestFormData } from "./request-common.data";

export  class ISODryageTruckFormData extends RequestFormData {
    articleNumber: string="";
    internModel: string="";
    mfg: string="";

    //Service provider
    monthlyVolume: number=0;
    monthlyVolumeUnitId: number=0;
    hazardous: string="";
    loadingHours:string="";
    nitrogenBlanket: string="";
    tempRequirementId: number=0;
    tempRange: string="";
    weekendDelivery: string="";

    deliveryType:number=0;
}