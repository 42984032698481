import { RailRateRequestVendorResponseData } from "./rail-rate-vendor-response.data";

export class RailRateVendorResponseForm extends RailRateRequestVendorResponseData
{
    disable:boolean=false;
    validVendor:boolean=true;
    validBidAmount:boolean=true;
    validDueDate:boolean=true;
    validComments:boolean=true;
    validRank:boolean=true;
    validCurrency:boolean=true;
    valiRouteCode:string="";
    validTransmitTime:number=0;
    validResponseNeededBy:string="";
    validSegment:string="";
    validSMail:string="";
    validRateoffer:string=""
    validInterchange:string=""
}