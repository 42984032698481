import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { DropDownMaster } from "../dropdown-master.data";
import { TransportTypeMasterServices } from "./transport-type-master.service";

@Injectable()

/** Handles the funcanality for Transport type master  **/
export class TransportTypeMasterModel
{

    constructor(private service:TransportTypeMasterServices)
    {

    }


    ///Retrieve Transport type Master from the webAPI
    public RetrieveTransportTypeMaster():Observable<DropDownMaster[]>
    {
        let subject=new Subject<DropDownMaster[]>();
        this.service.RetrieveTransportTypeMaster().subscribe(response=>
            {
                let apiResponse:any =response;
                let apiResponseResult:any=apiResponse.results;
                if(apiResponseResult.status_id===200)
                {
                    let  resultdata:any=apiResponse.data;
                    let datacollection=this.convertResponsetoArray(resultdata);
                    subject.next(datacollection);
                }
               
            });

            return subject.asObservable();
    }

   
 //Convert  Response to DropDownMaster Array
 private  convertResponsetoArray(response:any):DropDownMaster[]
 {
    let result:DropDownMaster[]=[];
    response.forEach((element:any) => {
       let resultdata=new DropDownMaster();
        resultdata.value=Number(element.masterId);
      
        resultdata.label=element.masterValue;
        result.push(resultdata);

     });
     return result;
 }
}