import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root"
})
export class UserMasterDataServices {
    constructor(private client: HttpClient) { }

    retrieveRoles(requestedBy: number) {
        let link = environment.getRoles.replace("{requestedby}", requestedBy.toString())

        //admin/roles/requestedby/{requestedby}
        return this.client.get(link).pipe(map((response) => response));
    }

    
}