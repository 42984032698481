import { DeliveryTypeData } from "./delivery-type.data";

export class DeliveryTypeFormData extends DeliveryTypeData 
 {
    edit:boolean=true;
    editing:boolean=false;
    status:string="";
    
    validateDeliveryType:boolean=true;
    validTransportType:boolean=true;
}