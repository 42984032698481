import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/internal/operators/map";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn:"root"
})
export class TempRequirementService
{
    constructor(private client: HttpClient) { }
    
    retrieveTempRequirementList(requestedBy: number){
        let link=environment.getTempRequirement.replace("{requestedby}", requestedBy.toString())
     
        return this.client.get(link).pipe(map((response)=>response));
      } 
      
      submitTempRequirement(data: any) {
        return this.client.post(environment.submitTempRequirement, data).pipe(map((response) => response));
      }
}