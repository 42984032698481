import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { RequestVendorResponseForm } from "./data/request-vendor-response-form.data";
import { TruckLoadVendorResponseData } from "./data/truck-load-vendor-response.data";
import { VendorResponseData } from "./data/vendor-response.data";
import { VendorResponseModel } from "./vendor-response.model";
import {VendorResponseServices } from "./vendor-response.services";
import { VendorApiSubmit } from "./data/vendor-api-submit.data";

@Injectable()
export class TruckVendorResponseModel extends VendorResponseModel{

  constructor( service: VendorResponseServices) {
    super(service)
    console.log("constructor")

  }

  
   onretrieveVendorResponseSuccess(apiResponse: any, subject: Subject<VendorResponseData[]>) {
    let result: VendorResponseData[] = [];
    let reapdata: any = apiResponse.data;
    reapdata.forEach((element:any) => {
        result.push(element as TruckLoadVendorResponseData);
    });

    subject.next(result);
}

  submitvendorResponse(vendorApiSubmitData:VendorApiSubmit,rateReqCalData:string,rateReqCalFileName:string,rateReqCalFileExtn:string): Observable<string> {

    let apiRequest: RequestVendorResponseForm =new RequestVendorResponseForm();
    
    apiRequest = this.onBindRequestVendorResponse(apiRequest,vendorApiSubmitData,rateReqCalData,rateReqCalFileName,rateReqCalFileExtn);
    return this.onSubmitVendorResponse(apiRequest);
  }

  notifyVendorResponse(vendorApiSubmitData:VendorApiSubmit): Observable<string> {

    let apiRequest: RequestVendorResponseForm =new RequestVendorResponseForm();
    apiRequest = this.onBindRequestVendorResponse(apiRequest,vendorApiSubmitData,"","","");
    return this.onNotifyVendorResponse(apiRequest);
  } 

  submitBidByVendor(vendorApiSubmitData:VendorApiSubmit): Observable<string> {

    let apiRequest: RequestVendorResponseForm =new RequestVendorResponseForm();
    apiRequest = this.onBindRequestVendorResponse(apiRequest,vendorApiSubmitData,"","","");
    return this.onSubmitBidByVendor(apiRequest);
  } 
  
  
validateRank(ranks: any, vendorsData: any) {
  let rankSelected:boolean=true;
  vendorsData.forEach((v:any)=>{
      v.validRank=true;
      v.validRank2=true;
      if(v.isSelected && v.rankAssigned==0){
          rankSelected=false;
          v.validRank=false;
      }
      if(v.isSelected2 && v.rankAssigned2==0){
          rankSelected=false;
          v.validRank2=false;
      }   
  })        
  vendorsData=this.validateRankSelected(ranks,vendorsData,rankSelected)
  return vendorsData;
}

validateRankSelected(ranks: any, vendorsData: any,rankSelected:boolean){
  if(rankSelected){
      ranks.forEach((r:any) => {
          this.checkDuplicateRankAssigned(r,vendorsData);
      });
  }
  return vendorsData;
}

checkDuplicateRankAssigned(r:any,vendorsData: any){
  let count = 0;
  vendorsData.forEach((v:any) => {
      if (v.rankAssigned == r.value) {
          if (count > 0) {
              v.validRank = false;
          }
          count++;
      }
      if(v.rankAssigned2 == r.value){
          if(count>0){
              v.validRank2=false;
          }
          count++;
      }
  });
  return vendorsData
}


}