import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { LoggecInUserInfo } from "src/app/site-permissions/data/loggedin-user-info";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { RailCarTypeFormData } from "../data/railcar-type-form.data";
import { RailCarTypeModel } from "../railcar-type.model";


@Component({
    selector: 'app-admin-railcar-type-management',
    templateUrl: './admin-railcar-type.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [LoggedInUserInfoModel,RailCarTypeModel]
  })

export class AdminRailCarTypeManagementComponent implements OnInit
{

    @Input() loggedinUserinfo!: LoggecInUserInfo;
    railCarTypeCollection:RailCarTypeFormData[]=[]
    railCarTypeData:RailCarTypeFormData=new RailCarTypeFormData();
    statusOpts: any;

  railCarTypeTableColumns: any;
  constructor(private railCarTypeModel: RailCarTypeModel,
    private spinner: NgxSpinnerService,
    private toast: ToastrService) {

  }
    ngOnInit(): void {
    
      this.retrieveRailCarTypeTableColumn();
    this.retrieveRailCarTypeStatus();
    this.retrieveRailCarTypeCollection();

    }
    
  retrieveRailCarTypeTableColumn() {
    this.railCarTypeTableColumns = this.railCarTypeModel.retrieveRailCarTypeColumn();
    const screenWidth = window.innerWidth;
    if(screenWidth < 750) {
      this.railCarTypeTableColumns.forEach((col:any) => {
          col.width = (parseInt(col.width.replace('%','')) * 10).toString() + 'px';
      });
    }
  }
  updateRailCarType ()
  {
    this.spinner.show();
    let railcarCheck=this.railCarTypeCollection.filter(rc=>rc.rail_type_id!=this.railCarTypeData.rail_type_id)
    this.railCarTypeData=this.railCarTypeModel.validateRailCarTypeForm(this.railCarTypeData);
    railcarCheck.forEach(sc=>{    
      if(this.railCarTypeData.rail_type_name==sc.rail_type_name){
        this.railCarTypeData.validateRailTypename=false;
        this.toast.warning("There is already a RailCar Type with this name.")          
      }
    })
    if (!(this.railCarTypeData.validateRailTypename)) {
      this.spinner.hide();
      return false;
    }
    else{
    this.railCarTypeModel.onSaveRailCarType(this.railCarTypeData,this.loggedinUserinfo.userId)
    .subscribe({
      next:(saveResponse)=>{
      this.toast.success(saveResponse, "Success");
      this.retrieveRailCarTypeCollection();
      this.spinner.hide();
      this.onCancel();
    },
    error:(error)=>
    {
      this.toast.error(error, "Error!");
      this.spinner.hide();
    }});
    return true;
  }

  }
  onCancel()
  {

    this.railCarTypeData=new RailCarTypeFormData();
  }

  
  onRowRailTypeEditInit(rowData:any){
    this.railCarTypeData.edit = true;
    this.railCarTypeData.editing = true;
    this.railCarTypeData.rail_type_id = rowData.rail_type_id
    this.railCarTypeData.rail_type_name=rowData.rail_type_name;
    this.railCarTypeData.is_active = rowData.is_active;
  }

  retrieveRailCarTypeCollection()
  {
    this.railCarTypeModel.retrieveRailCarTypeCollection(this.loggedinUserinfo.userId).subscribe({
    next: ( resultCollection:any)=>{
     this. railCarTypeCollection=resultCollection
    },
   error: (error:any)=>
    {
      console.error(error);
    },
  })
  }

  retrieveRailCarTypeStatus() {
    this.statusOpts = this.railCarTypeModel.retrieveRailCartTypeStatus();
  }
} 
