import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoggedInUserInfoModel } from 'src/app/site-permissions/loggedin-user-access.model';
import { RequestFormModel } from '../request-form.model';
import { CommonRequestComponent } from './request-form-common.component';
import { RequestMasterModel } from '../request-master.model';
import { PackageContainerBoxDrayageMasterModel } from '../package-container-box-drayage-master.model';
import { PackageContainerBoxDrayageFromData } from '../data/formdata/package-container-box-drayage-form.data';
import { PackagaContainerBoxRequestFormModel } from '../package-container-box-drayage-form.model';
import { PackageContainerBoxDrayageMasterData } from '../data/masterdata/package-container-box-drayage-master.data';
import { TranslateService } from '@ngx-translate/core';
import { AppServiceService } from 'src/app/services/app-service.service';
import { Subscription } from 'rxjs';


@Component({
    selector: 'app-package-container-box-drayage-request-form',
    templateUrl: '..\\shared-templates\\request-form-template.html',
    styleUrls: ['..\\shared-templates\\request-form-template.css'],
    encapsulation : ViewEncapsulation.None,
    providers: [LoggedInUserInfoModel,
        { provide: RequestFormModel, useClass: PackagaContainerBoxRequestFormModel },
        { provide: RequestMasterModel, useClass: PackageContainerBoxDrayageMasterModel }

    ]

})

export class PackageContainerBoxComponent extends CommonRequestComponent implements OnInit,OnDestroy {
    private subscriptionName: Subscription; //important to create a subscription
    messageReceived: any;

    // override requestMaster: PackageContainerBoxDrayageMasterData = new PackageContainerBoxDrayageMasterData;
    // override requestData: PackageContainerBoxDrayageFromData = new PackageContainerBoxDrayageFromData;
    override requestMaster:any
 override requestData:any
    _toast: ToastrService
    pageLanguage:string="";
    constructor(userAccessModel: LoggedInUserInfoModel, masterModel: RequestMasterModel,
        requestModel: RequestFormModel,
        toast: ToastrService, router: Router, spinner: NgxSpinnerService,
        private spinners: NgxSpinnerService, route: ActivatedRoute,translate:TranslateService,private _appServ: AppServiceService) {
        super(userAccessModel, masterModel, requestModel, router, toast, spinner, route,translate);
        this._toast = toast;
        this.subscriptionName= this._appServ.getUpdate().subscribe(message => { //message contains the data sent from service
          this.messageReceived = message;    
          this.ngOnInit();
        });
    }
    ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
        this.subscriptionName.unsubscribe();
    }

    ngOnInit(): void {
        this.pageLanguage=sessionStorage['selectedLanguage'];
        this.transportType = 6;
        this.requestMaster = new PackageContainerBoxDrayageMasterData();
        this.requestData = new PackageContainerBoxDrayageFromData();
        this.requestData.transportType = 6;
        this.bindfiledVisiblity();
        this.retrieveHeaderValues();
        this.validateloggedInUser();
        //Request Master
        this.retrieveMasterData(this.transportType);
       
        //retrieve Query Parameters
        this.retrieveQueryPramaters();
        this.retriveLang(this.pageLanguage);
    }

    bindfiledVisiblity() {

        this.fieldVisiblity.requestType = true;
        this.fieldVisiblity.startDate = true;
        this.fieldVisiblity.requestor = true;
        this.fieldVisiblity.bussinessunit = true;
        this.fieldVisiblity.articleNumberfreeText = true;
        this.fieldVisiblity.product = true;

        // Service Provider
        this.fieldVisiblity.monthlyVolume = true;
        this.fieldVisiblity.annualVolume = true;
        this.fieldVisiblity.dropContainerId = true;
        this.fieldVisiblity.tempRange = true;
        this.fieldVisiblity.tempControl = true;
        this.fieldVisiblity.hazardous = true;
        this.fieldVisiblity.loadingHrs = true;
        this.fieldVisiblity.unloadingHrs = true;
        this.fieldVisiblity.weekendDelivery = true;
        this.fieldVisiblity.unNumber=true;
        this.fieldVisiblity.isMaterialPackagedLiquidInTotes=true;

        //Shipping->Orgin
        this.fieldVisiblity.shippingName = true;
        this.fieldVisiblity.orginCity = true;
        this.fieldVisiblity.orginState = true;
        this.fieldVisiblity.orginCountry = true;
        this.fieldVisiblity.orginZipCode = true;
        this.fieldVisiblity.orginLocationType= true;
        //shipping-> destination
        this.fieldVisiblity.consigneeName = true;
        this.fieldVisiblity.destinationCity = true;
        this.fieldVisiblity.destinationState = true;
        this.fieldVisiblity.destinationCountry = true;
        this.fieldVisiblity.destinationZipCode = true;
        this.fieldVisiblity.destinationLocationType= true;

        //Additional Info
        this.fieldVisiblity.customerPreferedCarrier = true;
        this.fieldVisiblity.costVsService = true;
        this.fieldVisiblity.comments = true;

        this.fieldVisiblity.materialDoc = true;
        this.fieldVisiblity.materialLink = true;
        this.fieldVisiblity.sdsLink = true;
        this.fieldVisiblity.sdsAttachment = true;
        this.fieldVisiblity.additionalAttachment = true;
        this.fieldVisiblity.additionalAttachment2 = true;
        this.fieldVisiblity.hazardousSDSMandatory=true;
    }

    public clearForm() {
        this.OnCancel()
    }
    override OnCancel() {
        this.clearData(6);
        this.requestData.articleNumber = "";        

        //Service provider
        this.requestData.dropContainer = "";
    
        this.requestData.tempControl = "";
        this.requestData.tempRange = "";
        this.requestData.monthlyVolume=0;
        this.requestData.monthlyVolumeUnitId = 0;
        this.requestData.days = 0;
        this.requestData.hazardous = "";
        this.requestData.loadingHours = "";
        this.requestData.unloadingHour = "";
        this.requestData.weekendDelivery = "";
       
        this.requestData.hazmatclassId = 0;
        this.requestData.unNumber = "";
        this.requestData.isMaterialPackagedLiquidInTotes = "";
    
        //ShippingDetails
        this.requestData.destinationLocationTypeId = 0;
    
        this.requestData.orginLocationTypeId = 0;
    }
    GenerarateArticleNumberEvent() {
        console.log("")
       
    }
    onSpecialRequirement(event:any){

    }
    resetArticleData() {
        console.log("")
     
    }
    processArticleNumber(event: any) {
        console.log("")
      
    }

    retrieveHeaderValues() {
        this.title = "Package Drayage";
        this.description = "A \"Rush Request\" defined as Procurement providing rates to BU after 2 business days otherwise Procurement will provide rates within 7 business days for standard requests.";
    }

    requestTypeChangeEvent(event:any) {
        if (event.value === 13)
       {  
        this.startDateLable="DATE_AVAILABLE_WAREHOUSE"
       }
        else {
        this.startDateLable="START_DATE";
          
        }

    }

    handlehazardousChange(event:any){
        if(this.requestData.hazardous=="No")
        {
          this.requestData.hazmatclassId=0;
          this.requestData.unNumber="";
        }
    }

    onSubmitRequest() {
        this.handlingDefaultDropdownValues();
        this.requestData.monthlyVolumeUnitId=this.requestData.monthlyVolumeUnitId??0;
        this.requestData.annualVolumeUnitId=this.requestData.annualVolumeUnitId??0;
        this.requestData.orginLocationTypeId=this.requestData.orginLocationTypeId??0;
        this.requestData.destinationLocationTypeId=this.requestData.destinationLocationTypeId??0;
        this.createRequest();
    }

   

    onArticleComplete(event:any) {
        console.log("")

    }
    onArticleSelected(event:any) {
        console.log("")

    }
    onAnnualVolumeChange(event: any){
        console.log('');
      }
      
    bindConditionalFields(){
        console.log("")
    }
    onPlantSelectPopulate(event:any,index:number){
        console.log('')
    }

}