import { HttpClient, HttpUrlEncodingCodec } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn:"root"
})
export class RequestFormService
{
    codec = new HttpUrlEncodingCodec;
    constructor(private client:HttpClient)
    {

    }

    createRequest(data:any)
    {
        return this.client.post(environment.postBRRForm, data).pipe(map((response) => response));
    }

    retrieveNumberOfCars(transportType: number,annualVolume: number, annualVolumeUOM: number)
    {
        let  api:string=environment.getNumberofCars.replace("{0}",String(transportType)).replace("{1}",String(annualVolume)).replace("{2}",String(annualVolumeUOM))
        return this.client.get(api).pipe(map((response) => response));
     
    }
    retrieveRequestList(transportType:number,isExternalUser:boolean,userId:number)
    {
        let  api:string=environment.getRequests.replace("{mode}",String(transportType)).replace("{isExternal}",String(isExternalUser)).replace("{userId}",String(userId))
        return this.client.get(api).pipe(map((response) => response));
    }

    retrieveRequestDetails(transportType:number,requestId:number)
    {
       let  api:string=environment.getRequestDetails.replace("{0}",String(requestId)).replace("{1}",String(transportType))
        return this.client.get(api).pipe(map((response) => response));
     
    }
        
    exportRequestList(transportType:number){
        let isExternal:any=sessionStorage.getItem('isVendorUser');
        let userId:any=sessionStorage.getItem('userId');
        let api:string=environment.exportRequestList.replace("{0}",String(transportType)).replace("{1}",String(isExternal)).replace("{2}",String(userId))
        return this.client.get(api, { responseType: "blob" }).pipe(
                map((res: any) => {
                    return new Blob([res], {type: 'application/octet-stream'});
               
        }));
    }
}