import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { TempRequirementFormData } from "./data/temp-requirement-form.data";
import { TempRequirementService } from "./temp-requirement.service";

@Injectable()


export class TempRequirementModel
{
    constructor(private client: TempRequirementService) { }
    retrieveListHeader() {

        return [
             { field : "temp_requirement_id", header:"Id", width: "10%"},
            { field: "temp_requirement_name", header: "Temp Requirement", width: "20%" },
            { field: "status", header: "Status", width: "20%" },
            { field: "transport_type", header: "Transport Type", width: "45%" },
            { field: "edit", header: "", width: "5%" }
        ];
    }

    retrieveTempRequirementCollection(requestedBy: number): Observable<TempRequirementFormData[]> {
        let subject = new Subject<TempRequirementFormData[]>();
        let resultCollection: TempRequirementFormData[] = [];
        this.client.retrieveTempRequirementList(requestedBy).subscribe({
           next: (tempRequirementollectionResult:any) => {
            let apiResponse: any = tempRequirementollectionResult;
            let apiResult: any = apiResponse.result;
            if (apiResult.status_id == 200) {
                let apiResultData: any = apiResponse.data;
                apiResultData.forEach((element:any) => {
                    let resultdata = element as TempRequirementFormData;
                    resultdata.edit = true;
                    resultdata.status = resultdata.is_active ? "Active" : "Inactive";
                    resultCollection.push(resultdata);

                });
                subject.next(resultCollection);
            }
            else {
                subject.error(apiResult.message)
            }
        },
            error:(error:any) => {
                subject.error(error);
            }
    });
        return subject.asObservable();
    }

    retrieveTempRequirementStatus() {
        return[
            { label: "Active", value: true },
            { label: "InActive", value: false }
        ];
    }

    validateTempRequirementForm(data: TempRequirementFormData, selectedTransportType: any): TempRequirementFormData {
         data.temp_requirement_name = data.temp_requirement_name.trim();
         data.validateTempRequirement = this.validInput(data.temp_requirement_name) && this.validateTextInput(data.temp_requirement_name);
        data.validTransportType = selectedTransportType.length > 0;
         return data;
    }


    OnsaveVendor(data: TempRequirementFormData, selectedTransportType: any, userId: number): Observable<string> {

        let subject = new Subject<string>();

        let updateTransportType: any[] = [];
        this.processSelectedTransportType(selectedTransportType, updateTransportType);

        let temprequirementData = {
            modified_by: userId,
            temp_requirement_id: data.temp_requirement_id,
            temp_requirement_name: data.temp_requirement_name,
           
            is_active: data.is_active,
            transport_type_master: updateTransportType
        }


       
            this.client.submitTempRequirement(temprequirementData).subscribe({
                next:(createVendorResponse:any) => {
                this.processSaveResponse(createVendorResponse, subject);
            }
            ,
            error:(error) => {
                subject.error(error)

            }
    });
        
        return subject.asObservable();
    }


    private processSaveResponse(updatevendorResponse: any, subject: Subject<string>) {
        let apiResponse: any = updatevendorResponse;
        let apiResult: any = apiResponse.result;
        if (apiResult.status_id == 200) {
            subject.next(apiResult.message);
        }
        else {
            subject.error(apiResult.message);
        }
    }


    private processSelectedTransportType(selectedTransportType: any, updateTransportType: any[]) {
        selectedTransportType.forEach((element:any) => {
            let dropdownData = {
                masterId: element,
                masterValue: "",
                masterOrderBy: 0
            };

            updateTransportType.push(dropdownData);
        });
    }

    validInput(validStr:any) {
        return validStr.length > 1;
    }
    ValidateEmail(validStr:any) {
        let emailReg = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/;
        return emailReg.test(validStr);
    }
    validateUserName(validStr:any) {
        let unRegex = /^[a-zA-Z0-9]+$/;
        return unRegex.test(validStr);
    }

    validateTextInput(validStr:any) {
        let inputText = /^[a-zA-Z\s]+$/;
        return inputText.test(validStr);
    }

    validateCodes(validStr:any) {
        let codeRegex = /^[a-zA-Z0-9]+$/;
        return codeRegex.test(validStr);
    }
}
