import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppServiceService } from '../services/app-service.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login-qa',
  templateUrl: './login-qa.component.html',
  styleUrls: ['./login-qa.component.css']
})
export class LoginQaComponent implements OnInit {
  form!: FormGroup;
  submitted = false;
  islogin!: boolean;
  username!: string;
  apiResp: any;
  userDetails: any;
  noAccess = false;
  noAccessUser = false;
  href!: string;
  password!: string;
  userNotFound: boolean = false;
  constructor(private formBuilder: FormBuilder, private router: Router, private service: AppServiceService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.href = window.location.href;
    let dev = this.href.indexOf("dev").toString();
    let qa = this.href.indexOf("qa").toString();
    if (dev != "-1"|| qa != "-1") {
      this.form = this.formBuilder.group({
        username: ['', Validators.required],
        password: ['', Validators.required]
      });
    }    
  }

  onSubmit() {
    this.spinner.show();
    this.noAccess = false;
    this.noAccessUser = false;
    let reqData = {
      Username: this.form.value.username,
      Password: this.form.value.password
    }
    this.retrieveUserDetails(reqData);
  }
  private processUserDetails() {
    this.userDetails = this.apiResp.result;
    if (this.userDetails.length > 0) {
      let userInfo = this.userDetails[0];
      if (userInfo.is_active) {
        this.saveUserDetailsInSession(userInfo);
        let accessObj = {
          "appAccess": true,
          "homeAccess": true,
          "lpAccess": !userInfo.isExternalUser,
          "requestAccess": true,
          "adminAccess": userInfo.is_admin,
          "vendorAccess": true
        };
        this.service.setAccess(accessObj);
        this.service.sendUserAccessValues(accessObj);
        this.redirectToLandingPage();
      } else {
        this.noAccessUser = true;
      }
    }
    else {
      this.spinner.hide();
      this.noAccess = true;
      this.userNotFound = true;
    }
  }

  private redirectToLandingPage() {
    let requestPath = sessionStorage.getItem('requestPath');
    if (requestPath != null && requestPath != undefined && requestPath != "") {
      window.location.href = requestPath;
    }
    // else if(sessionStorage.getItem('isVendorUser')){
    //   this.router.navigate(['/request-list']);
    // }
    else{
      this.router.navigate(['/landingpage']);
    }
  }

  private retrieveUserDetails(reqData: { Username: any; Password: any; }) {
    this.service.getQAUserDetails(reqData).subscribe(response => {

      this.apiResp = response;
      if (this.apiResp.result.status_id != "501") {
        this.processUserDetails();
      }
      else {
        this.spinner.hide();
        this.noAccess = true;
        this.userNotFound = true;
      }
    });
  }


  private saveUserDetailsInSession(userInfo: any) {
    sessionStorage.setItem('firstName', userInfo.first_name);
    sessionStorage.setItem('lastName', userInfo.last_name);
    sessionStorage.setItem('email', userInfo.email);
    sessionStorage.setItem('BU', userInfo.department);
    sessionStorage.setItem('bulkTMSUser', userInfo.user_name);
    sessionStorage.setItem('roleId', userInfo.role_id);
    sessionStorage.setItem('appAccess', "true");
    sessionStorage.setItem('homeAccess', userInfo.isExternalUser?"false":"true");
    sessionStorage.setItem('lpAccess',userInfo.isExternalUser?"false":"true");
    sessionStorage.setItem('requestAccess', "true");
    sessionStorage.setItem('adminAccess', userInfo.is_admin);
    sessionStorage.setItem('vendorAccess', "true");
    sessionStorage.setItem('userId', userInfo.user_id);
    sessionStorage.setItem('isVendorUser', userInfo.isExternalUser);
  }
}
