import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, shareReplay } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn:"root"
})
export  class UserManagementServices
{
    constructor(private client: HttpClient) { }

    retrieveUsersCollection(requestedBy:number) {

        let link = environment.getUsers.replace("{requestedby}", requestedBy.toString())


        return this.client.get(link).pipe(shareReplay(1));
      }

      saveUsers(userInfo: any) {
        return this.client.post(environment.postUser, userInfo).pipe(map((response) => response));
      }
}