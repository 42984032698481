import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { RailRateRequestFormData } from "../data/formdata/rail-rate-form.data";
import { RailRequestFormModel } from "../rail-rate-request-form.model";
import { RequestFormModel } from "../request-form.model";
import { RequestDetailsComponent } from "./request-details-form.component";
import { Subscription } from "rxjs";
import { AppServiceService } from "src/app/services/app-service.service";

@Component({
    selector: 'app-liquid-request-details',
    templateUrl: '..\\shared-templates\\request-details-form-template.html',
    styleUrls: ['..\\shared-templates\\request-details-form-template.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [LoggedInUserInfoModel,
        { provide: RequestFormModel, useClass: RailRequestFormModel }
    ]
})
export class RailRateRequestDetailsComponent extends RequestDetailsComponent implements OnInit,OnDestroy {

    private subscriptionName: Subscription; //important to create a subscription
 messageReceived: any;
 pageLanguage:string="";
 override requestData:any
    constructor(userAccessModel: LoggedInUserInfoModel,  requestModel: RequestFormModel,
        toast: ToastrService, router: Router, spinner: NgxSpinnerService, route: ActivatedRoute,private _appServ: AppServiceService) {
        super(userAccessModel,requestModel, toast, router, spinner,route);
        this.subscriptionName= this._appServ.getUpdate().subscribe(message => { //message contains the data sent from service
            this.messageReceived = message;        
            let newLang=sessionStorage.getItem('selectedLanguage');
            if(this.pageLanguage!=newLang && this.pageLanguage!=""){
              this.ngOnInit();
            }
        });
    }

    ngOnInit(): void {
        this.pageLanguage=sessionStorage['selectedLanguage'];
       this.requestData = new RailRateRequestFormData();        
        this.startDateLable="EXPECTED_SHIPMENT_DATE";
        this.prefferedCarriear="Preferred Routing"; 
        this.transportType=4
        this.validateloggedInUser();
        this.readQueryString();
        this.retrieveHeaderValues();
        this.bindfiledVisiblity();
        
    }

    ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
        this.subscriptionName.unsubscribe();
    }
    downLoadSpecialReqAttachment(){console.log('')};
    downLoadFISPQFile(){console.log('')}
  bindfiledVisiblity()
  {

    this.fieldVisiblity.requestType=true;
    this.fieldVisiblity.startDate=true;
    this.fieldVisiblity.requestor=true;
    this.fieldVisiblity.bussinessunit=true;
    this.fieldVisiblity.articleNumber=true;
    this.fieldVisiblity.product=true;
    this.fieldVisiblity.mfgDescription=true;
    this.fieldVisiblity.railCarType=true;
    this.fieldVisiblity.payerOption=true;


    // Service Provider
    this.fieldVisiblity.annualVolume=true;
    this.fieldVisiblity.hazardous=true;
    this.fieldVisiblity.numberOfRailCarsRequired=true;

    //Shipping->Orgin
    this.fieldVisiblity.shippingName=true;
    this.fieldVisiblity.orginStreetAddress=true;
    this.fieldVisiblity.orginCity=true;
    this.fieldVisiblity.orginState=true;
    this.fieldVisiblity.orginCountry=true;
    this.fieldVisiblity.orginZipCode=true;
    this.fieldVisiblity.orginAlternateServingRailRoad=true;
    this.fieldVisiblity.orginRailStation=true;
    this.fieldVisiblity.orginServingRailRoad=true;
    this.fieldVisiblity.orginSiteCompetitivenessId=true;

    //shipping-> destination
    this.fieldVisiblity.consigneeName=true;
    this.fieldVisiblity.destinationStreetAddress=true;

    this.fieldVisiblity.destinationCity=true;
    this.fieldVisiblity.destinationState=true;
    this.fieldVisiblity.destinationCountry=true;
    this.fieldVisiblity.destinationZipCode=true;
    this.fieldVisiblity.destinationServingRailRoad=true;
    this.fieldVisiblity.destinationRailStation=true;
    this.fieldVisiblity.destinationAlternateServingRailRoad=true;
    this.fieldVisiblity.destinationSiteCompetitivenessId=true;


    //Payer Options
    this.fieldVisiblity.payer=true;
    this.fieldVisiblity.routing= true;
    this.fieldVisiblity.tariffNumber= true;
    this.fieldVisiblity.freightBillAddress= true;
    //Additional Info
    this.fieldVisiblity.customerPreferedCarrier=true;
    this.fieldVisiblity.costVsService=true;
    this.fieldVisiblity.comments=true;

    this.fieldVisiblity.materialDoc=true;
    this.fieldVisiblity.materialLink=true;
    this.fieldVisiblity.sdsLink=true;
    this.fieldVisiblity.sdsAttachment=true;
    this.fieldVisiblity.additionalAttachment=true;
    this.fieldVisiblity.additionalAttachment2=true;

    if(this.isVendorUser){
        this.fieldVisiblity.requestor=false;
        this.fieldVisiblity.mfgDescription=false;
        this.fieldVisiblity.orginStreetAddress=false;
        this.fieldVisiblity.destinationStreetAddress=false;
        this.fieldVisiblity.customerPreferedCarrier=false;
        this.fieldVisiblity.costVsService=false;
      }
    
  }
    retrieveHeaderValues() {
        this.title = "Rail Transportation";
      }
    bindConditionalFields(){
        console.log("")
 
    }
}