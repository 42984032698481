<div class="container-fluid home-bg p-0" id="landingPage">
  <div class="row welcomeText">
    <div class="col-md-12 d-flex flex-row-reverse" id="welcome" style="margin-top:15px">
      <label style="font-size: 20px; padding-left: 5px;font-weight: 600;color:black">Welcome to</label>
    </div>
  </div>
  <div class="row welcomeText">
    <div class="col-md-12 d-flex flex-row-reverse">
      <label style="font-size: 40px;font-weight: bold; color: #004A96;line-height: normal;">Logistics Procurement Rate
        Request</label>
    </div>
  </div>
  <div id="landing-region" class="row container-fluid" style="padding-right: 0px;margin-left:0px">
    <div class="col col-8 col-md-9" style="margin-top: 3%;">

    </div>
    <div class="col col-4 col-md-3 mrgtop" style="padding-right:0px">

      <div *ngFor="let mode of regionsDD" class="d-flex flex-column ">
        <button class="btnrounded" type="button" pButton icon="pi pi-angle-double-right" iconPos="left"
          label="{{mode.name}}" (click)="loadHomePage(mode)"
          [ngStyle]="mode.label=='NA'?{'background-color':'#21A1D2'}: mode.label=='SA'? {'background-color':'#F39501'}: mode.label=='EMEA'? {'background-color':'#C70124'}: {'background-color': '#65AC1E'}"></button>
      </div>
    </div>
  </div>
  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" color="#fff" type="line-scale-pulse-out"></ngx-spinner>
</div>
