import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root"
})

export class VendorResponseMasterService {
   
   
    constructor(private client: HttpClient) { }


    RetrieveVendorMaster(userId:number,transportType:number)
    {
        let  api:string=environment.getVendorMasterList.replace("{userId}",String(userId)).replace("{transportType}",String(transportType));
        return this.client.get(api).pipe(map((response) => response));


    }

    RetrieveVendorCurrencyMaster()
    {
        return this.client.get(environment.getVendorCurrencyMasterList).pipe(map((response) => response));
        
    }

    RetrieveVendorUserMaster(transportType:number){
        return this.client.get(environment.getAllVendorUsers.replace("{transportType}",String(transportType))).pipe(map((response)=>response));
    }
}