import { RequestFormData } from './request-common.data';

export class PackageContainerBoxDrayageFromData extends RequestFormData {

    articleNumber: string = "";

    //Service provider
    dropContainer:string="";

    tempControl: string = "";
    tempRange: string = "";
    monthlyVolume:number=0;
    monthlyVolumeUnitId: number = 0;
    days: number = 0;
    hazardous: string = "";
    loadingHours: string = "";
    unloadingHour: string = "";
    weekendDelivery: string = "";
   
    hazmatclassId: number = 0;
    unNumber: string = "";
    isMaterialPackagedLiquidInTotes: string = "";

    //ShippingDetails
    destinationLocationTypeId: number = 0;

    orginLocationTypeId: number = 0;
}