import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { AppServiceService } from 'src/app/services/app-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserManagementMasterModel } from 'src/app/admin/user-management/user-master-data.model';
import { DropDownLanguageMaster, DropDownRegionMaster } from 'src/app/shared/dropdown-master.data';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';


import { TranslateService } from '@ngx-translate/core';
import { AuthguardServiceService } from 'src/app/services/authguard-service.service';
import { isNullOrUndefined } from 'src/app/shared/utils/nullorundefined';
import { SelectItem } from 'primeng/api';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [UserManagementMasterModel],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, OnDestroy {
  

  accessObj = {
    "appAccess": false,
    "homeAccess": true,
    "lpAccess": false,
    "requestAccess": false,
    "adminAccess": false,
    "vendorAccess": false,
    'isvendorUser': false
  };
  href!: string;
  languagesDD: DropDownLanguageMaster[] = [];
  selectedLanguage: DropDownLanguageMaster[] = [];
  regionsDD: any = [];
  regions: any = [];
  selectedRegionVal: any = [];

  languagesDpD: any = [];
  languages!: SelectItem[];
  selectedLanguagesVal: any = [];


  landingpage: boolean = false;
  isLandingPage:boolean=false;
  messageReceived: any;

  selectedLang: string = isNullOrUndefined(sessionStorage.getItem('selectedLanguage'))?  'English' : sessionStorage['selectedLanguage'];
  selectedCar2: string = 'Spanish';
  cars!: SelectItem[] ;
  region: any = [];

  regionDD!: DropDownRegionMaster[];

  selectedRegions!: DropDownRegionMaster;
  selectregion: any = [];

  private headerSubscriptionName: Subscription; //important to create a subscription

  constructor(private router: Router, private spinner: NgxSpinnerService, private toast: ToastrService,
    private _appServ: AppServiceService, private userMasterModel: UserManagementMasterModel, private ele: ElementRef,
    private translate: TranslateService, private route: ActivatedRoute,private authService:AuthguardServiceService) {
    
    this.headerSubscriptionName = this._appServ.getUpdate().subscribe(message => { 
        this.messageReceived = message;
        this.languagesDD = this.messageReceived ? this.messageReceived.languagedd : []
        this.route.queryParams.subscribe(params => {
          this.checkrouteparamerters(params)
         
          if (params['isExternalUser'] != undefined || params['isExternalUser'] != null)
            sessionStorage.setItem('isVendorUser', params['isExternalUser'].toLowerCase() == 'true' ? "true" : "false");

        });
        if(sessionStorage.getItem('selectedRegion') !== null || sessionStorage.getItem('selectedRegion') !== undefined){
          this.getuserregions();
        }

       });
      this.authService.checkIsLandingPage().subscribe((response:any)=>{
        this.router.events.pipe(filter(event=>event instanceof NavigationEnd)).subscribe((event:any)=>{
          if (this.router.url == '/landingpage'){
            this.isLandingPage=true;
          }
          else{
            this.isLandingPage=false;
          }          
        })     
        this.selectedLang=sessionStorage['selectedLanguage'];
      })
  }
  checkrouteparamerters(params: Params) {
    const url = this.router.url;
          if (url != '/' || this.router.url != '/landingpage'){
            this.landingpage = false;
          }
          if(params != null){
            if (params['userId'] != undefined){
              sessionStorage.setItem('userId', params['userId']);
            }
            if (params['Region'] != undefined)
            {
              sessionStorage.setItem('selectedRegion',atob(params['Region']))
            }
          }          

  }

  @HostListener('document:click', ['$event'])
  onClick(evt: any) {
    if (!this.ele.nativeElement.contains(evt.target)) {
      const navbar = document.querySelector('#navbarSupportedContent');
      navbar?.classList.remove('show');
    }
  }

  @HostListener('window:unload', ['$event'])
  ngOnDestroy() {
    this.headerSubscriptionName.unsubscribe();
  }

  onLanguageChange(event: any) {
    this.selectedLang=event.value;
    sessionStorage.setItem('selectedLanguage', event.value)
    this.translate.use(event.value.toLowerCase());
    this._appServ.sendUpdate(false, this.languages, this.regionDD);
  }

  onRegionChange(event: any) {
  let langevent={value : "English"}
    this.onLanguageChange(langevent);   
    this.regionsDD = [];
    this.regionsDD = this.regions;
    sessionStorage.setItem('selectedRegion', event.value.label)
    sessionStorage.setItem('regionId', this.selectedRegions.value)
    this._appServ.sendUpdate(false, this.languages, this.regionDD);
    this.getuserLanguages();
  }

  ngOnInit() {
   

   
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      const url = this.router.url;
      if (url != '/' || this.router.url != '/landingpage')
      if (this.router.url == '/landingpage' || this.router.url == '/') {
        this.landingpage = true
      }      

      if (sessionStorage['userId'] !== undefined && sessionStorage['userId'] !== 'undefined' && sessionStorage['userId'] !== null && sessionStorage['userId'] !== 'null'
      && sessionStorage['userId'] !== '') {
        this.getuserregions();
      }
      
    })    

    if (sessionStorage.getItem("appAccess") != null && sessionStorage.getItem("appAccess") != "null" && sessionStorage.getItem("appAccess") != undefined
      && sessionStorage.getItem("appAccess") != "undefined" && sessionStorage.getItem("appAccess") != "") {
      this.accessObj.appAccess = (sessionStorage.getItem("appAccess") == "true");
      this.accessObj.requestAccess = (sessionStorage.getItem("requestAccess") == "true");
      this.accessObj.lpAccess = (sessionStorage.getItem("lpAccess") == "true");
      this.accessObj.vendorAccess = (sessionStorage.getItem("vendorAccess") == "true");
      this.accessObj.adminAccess = (sessionStorage.getItem("adminAccess") == "true");
      this.accessObj.homeAccess = (sessionStorage.getItem("homeAccess") == "true");
      this.accessObj.isvendorUser = (sessionStorage.getItem("isVendorUser") == "true");
    } else {
      this._appServ.getUserAccessValues().subscribe(response => {
        this.accessObj = response;
      });
    }
   
  }

  setSessionValuesOnQueryParams(params:any){
    if(params != null){
      if (params.userId != undefined){
        sessionStorage.setItem('userId', params['userId']);
      }
      if (params.Region != undefined)
      {
        sessionStorage.setItem('selectedRegion',atob(params['Region']))
      }
    }          

    if (params['isExternalUser'] != undefined || params['isExternalUser'] != null)
      sessionStorage.setItem('isVendorUser', params['isExternalUser'].toLowerCase() == 'true' ? "true" : "false");

  }

  navigateToRequests() {
    this.router.navigate(['/request-list'], { queryParams: { val: 'all' } })
  }
  homeclick() {
    this._appServ.sendUpdate(false, this.languagesDD, this.regionsDD);
    this.router.navigate(['/landingpage'])
  }

  getuserregions() {
console.log('call from header')

if(sessionStorage.getItem('userloggedin') != null){
    this.userMasterModel.RetrieveRegionMaster(Number(sessionStorage['userId']), sessionStorage['isVendorUser'] == 'true', 'Null').subscribe({
      next:(transportTypeResponse: any) => {
      if (transportTypeResponse.length > 0)

        if (!Number.isNaN(Number(sessionStorage['regionId']))) {
          this.transportTypeWithValidRegion(transportTypeResponse)
        }
        else {
          this.transportTypeWithoutRegion(transportTypeResponse)
          }
    },
      error:error => {
         console.error(error);
      }
      })
  }
}

  transportTypeWithValidRegion(transportTypeResponse:any){
    this.regionsDD = [];
    this.region = [];
    this.regions = [];
    transportTypeResponse.forEach((element:any) => {
      this.regionsDD.push({ id: element.value, value: element.label })

      this.region.push({ value: element.value, label: element.label, name: element.name })
      this.regions.push({ id: element.value, value: element.label })
    });
    this.regionDD = this.region;
    this.selectregion = this.regionDD.filter(i => i.value == Number(sessionStorage['regionId']))
    this.selectedRegions = this.selectregion[0];
    this.route.queryParams.subscribe(params => {

      if (params != null && params['Region'] != undefined)
      {
        sessionStorage.setItem('selectedRegion',atob(params['Region']))
      }

    });
    this.selectregion = this.regionDD.filter(i => i.label == sessionStorage['selectedRegion'])
    sessionStorage.setItem('selectedRegion', this.selectedRegions.label)
    sessionStorage.setItem('regionId', this.selectedRegions.value)
    this.selectedRegionVal = this.regionsDD.filter((i:any) => i.id == Number(sessionStorage['regionId']))

    this.getuserLanguages()

  }

  transportTypeWithoutRegion(transportTypeResponse:any){
    this.regionsDD = [];
    transportTypeResponse.forEach((element:any) => {
      this.regionsDD.push({ id: element.value, value: element.label })
      this.regions.push({ id: element.value, value: element.label })
    });
    this.selectedRegionVal = this.regionsDD;
    this.route.queryParams.subscribe(params => {

      if (params != null && params['Region'] != undefined)
      {
        sessionStorage.setItem('selectedRegion',atob(params['Region']))
        if(this.selectedRegionVal.find((i:any)=>i.value==atob(params['Region']))!= undefined)
        sessionStorage.setItem('regionId', this.selectedRegionVal.find((i:any)=>i.value==atob(params['Region'])).id)
        else{
          this.router.navigate(['/unauthorized']);
        }
      }
      else if(sessionStorage['selectedRegion'] == undefined){
        sessionStorage.setItem('selectedRegion', this.selectedRegionVal[0].value)
        sessionStorage.setItem('regionId', this.selectedRegionVal[0].id)
      }

    });
    this.getuserLanguages()

  }

  refresh() {
    this.router.navigate(['/home'])
      .then(() => {
        window.location.reload();
      });
  }


  getuserLanguages() {

    this._appServ.getLanguagesbasedonRegion(Number(sessionStorage['userId']), Number(sessionStorage['regionId'])).subscribe(response => {
      let apiResponse: any = response;
      let apiResponseResult: any = apiResponse.result;
      if (apiResponseResult.status_id === 200) {
        this.languagesDD = apiResponse.data;
        this.languagesDpD = [];
        apiResponse.data.forEach((element:any) => {
          this.languagesDpD.push({ label: element.LanguageName, value: element.LanguageName })
        });
        this.languages = [];
        this.languages = this.languagesDpD;
        this.selectedLanguagesVal = this.languagesDpD[0];
        if (this.languagesDD.length > 0) {
          this.selectedLanguage = this.languagesDD ? this.languagesDD[0]['LanguageId'] : {}
        }
      }      
    });

  }

  logout() {
    this.href = window.location.href;
    let dev = this.href.indexOf("dev").toString();
    let qa = this.href.indexOf("qa").toString();
    if (dev != "-1") {
      this.logoutQuality();
    }
    else if (qa != "-1") {
      this.logoutQuality();
    }
    else {
      window.sessionStorage.clear();
      window.location.href = 'https://login.microsoftonline.com/ecaa386b-c8df-4ce0-ad01-740cbdb5ba55/oauth2/v2.0/logout'
    }
  }


  private logoutQuality() {
    window.sessionStorage.clear();
    window.location.href = 'https://login.microsoftonline.com/ecaa386b-c8df-4ce0-ad01-740cbdb5ba55/oauth2/v2.0/logout'
  }
}
