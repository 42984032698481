<div id="deliveryType" class="container">
    <form autocomplete="off">
     <div class="row">
       <div class="form-group col-12 col-xl-4">
         <label for="DeliveryTypeName">Delivery Type Name</label>
         <input type="text"  autocomplete="off" class="form-control input-class" id="deliveryTypeNmae" name="deliveryTypeNmae" [(ngModel)]="deliveryTypeData.delivery_type_name">
         <span class="err" *ngIf="!deliveryTypeData.validateDeliveryType">*Invalid Delivery Type Name</span>
       </div>
       
      
       <div class="form-group col-12 col-xl-4" style="display: grid">
         <label for="status">Status</label>
         <p-dropdown name="vendorStatus" styleClass="cust-dropdown" [options]="statusOpts" [(ngModel)]="deliveryTypeData.is_active"
           placeholder="Select Status"></p-dropdown>
       </div>
       <div class="form-group col-12 col-xl-4" style="display: grid">
         <label for="transportType" style="margin-top: 0.4rem; margin-bottom: 0.3rem;">Transport Type</label>
         <p-multiSelect name="transportType" styleClass="cust-dropdown" [options]="transportTypeMasterData" [(ngModel)]="selectedTransportType" ></p-multiSelect>
         <span class="err" *ngIf="!deliveryTypeData.validTransportType">*Select a TransportType</span>
       </div>
     </div>
     <div class="row" style="margin-top: 18.5px;">
       <div class="form-group col-6 col-md-6" style="margin-bottom: 10px;">
         <p style="text-align: right">
           <input type="button" class="btn btn-secondary cust-btns" (click)="onCancel()" value="Clear">
         </p>
       </div>
       <div class="form-group col-6 col-md-6" style="margin-bottom: 10px;">
         <p style="text-align: left">
           <input *ngIf="!deliveryTypeData.editing" type="button" class="btn btn-primary cust-btns" style="background-color: #004A96 !important;" (click)="saveDeliveryType()" value="Create">
           <input *ngIf="deliveryTypeData.editing" type="button" class="btn btn-primary cust-btns" style="background-color: #004A96 !important;" (click)="saveDeliveryType()" value="Update">
         </p>
       </div>
     </div>
   </form>
 </div>
 <div class="container" id="userTable" style="margin-top: 20px; margin-bottom: 100px;padding-bottom:20px">
   <p-table #dt1 [columns]="deliveryTypeColumns" [value]="deliveryTypeCollection" [paginator]="true" [pageLinks]="2" [selectionPageOnly]="true" [rows]="10" [responsive]="false" [scrollable]="true" [resizableColumns]="true" columnResizeMode="expand"   [tableStyle]="{ 'min-width': '40rem' }">
     <ng-template pTemplate="colgroup" let-columns>
       <colgroup>
         <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
       </colgroup>
   </ng-template>
     <ng-template pTemplate="header" let-columns>
       <tr>
         <th id="col.header" *ngFor="let col of columns">
           {{col.header}}
         </th>
       </tr>
       <tr>
         <th id="thfilter" *ngFor="let col of columns" [ngSwitch]="col.field">
           <input *ngSwitchCase="'delivery_type_name'" pInputText type="text"
             (input)="dt1.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}">
          
        
             <input *ngSwitchCase="'status'" pInputText type="text"
             (input)="dt1.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}">

             <input *ngSwitchCase="'transport_type'" pInputText type="text"
             (input)="dt1.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}">                  </th>
       </tr>
     </ng-template>
     <ng-template pTemplate="body" let-rowData let-columns="columns">
       <tr>
         <td *ngFor="let col of columns">
          <span *ngIf="col.field == 'status'" class="status-text" [ngClass]="{'status-text-inactive':  rowData[col.field]=='Inactive'}">
            {{rowData[col.field]}}
          </span>
           <span *ngIf="col.field != 'IsActive' && col.field != 'edit' && col.field != 'status'">{{rowData[col.field]}}</span>
           <span *ngIf="col.field == 'IsActive'"><p *ngIf="rowData[col.field] == true">Active</p><p *ngIf="rowData[col.field] == false">InActive</p></span>
            <span *ngIf="col.field == 'edit'">
             <img *ngIf="!vendorEditing" class="accordion-img-icon pointer-cursor" alt="Edit" title='Edit Vendor'
               (click)="onRowDeliveryTypeEditInit(rowData)" src="./assets/images/icons/edit_icon.png">
             <img *ngIf="vendorEditing && rowData['VendorId'] === vId" alt="Cancel" class="accordion-img-icon pointer-cursor"
               (click)="onCancel()" title='Cancel Editing' src="./assets/images/icons/close_icon.png">
           </span> 
         </td>
       </tr>
     </ng-template>
   </p-table>
 </div>