import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  private router!: Router;
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
   
    if (!request.url.includes('Saml')) {
    sessionStorage.setItem("UserName", "MummacR");
     request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
     
    if (!request.url.includes('upload') && !request.url.includes('ExportExcel')) {
      request = this.cloneContentTypeHeaderRequest(request);
      
    }    
     request = this.setRequestAuthorization(request);
     request = this.setParamTypeHeaderRequest(request);
    return this.handleRequest(request,next);
  }
  else{
    request = request.clone({
      headers: request.headers.set('Access-Control-Allow-Origin', ['https://localhost:44369','http://localhost:44369','https://raterequest-dev.basf.com/','https://raterequest-qa.basf.com/','https://raterequest.basf.com/']) });
      
    return this.handleRequest(request,next);
   }
}

handleRequest(request: HttpRequest<any>, next: HttpHandler){
   return next.handle(request).pipe(
    tap(      
      error => {
        if (error instanceof HttpErrorResponse) {
          if (error.status == 401 || error.status == 0) {
            this.router.navigate(['login']);
          }
        }
      },
    )
  );
}
  private setRequestAuthorization(request: HttpRequest<any>) {
    if (request.url.includes('getcount')) {
      if (sessionStorage.getItem('UserName') != null && sessionStorage.getItem('UserName') != undefined && sessionStorage.getItem('UserName') != '') {
        request = request.clone({ headers: request.headers.set('Authorization', btoa(sessionStorage.getItem('UserName')!)) });
      }
      else {
        request = request.clone({ headers: request.headers.set('Authorization', btoa("NONE")) });
      }
    }
    else if(!request.url.includes('userrole')){
      if (sessionStorage.getItem('UserName') != null && sessionStorage.getItem('UserName') != undefined && sessionStorage.getItem('UserName') != '') {
        request = request.clone({ headers: request.headers.set('Authorization', btoa(sessionStorage.getItem('UserName')!)) });
      }      
    }
    return request;
  }

  private cloneContentTypeHeaderRequest(request: HttpRequest<any>) {
    if (!request.headers.has('Content-Type')) {
      request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
      request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
      request = request.clone({ headers: request.headers.set('Cache-Control', 'no-cache') });
      request = request.clone({ headers: request.headers.set('Pragma', 'no-cache') });
      request = request.clone({ headers: request.headers.set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT') });
      request = request.clone({ headers: request.headers.set('If-Modified-Since', '0') });
    }
    return request;
  }

  private setParamTypeHeaderRequest(request: HttpRequest<any>){
    request = request.clone({ headers: request.headers.set('userid', sessionStorage.getItem('userId') == null ? '0' : sessionStorage['userId'])});
    request = request.clone({ headers: request.headers.set('externallogin', sessionStorage.getItem('isVendorUser') == null ? 'true' : sessionStorage['isVendorUser'])});    
    request = request.clone({ headers: request.headers.set('region', sessionStorage.getItem('selectedRegion') == null ? 'NA' : sessionStorage['selectedRegion'])});
    request = request.clone({ headers: request.headers.set('lang', sessionStorage.getItem('selectedLanguage') == null ? 'English' : sessionStorage['selectedLanguage'])});
    return request;
  }
  
}
