<div id="admin-user-mngt" class="container">
    <form autocomplete="off">
      <div class="row">
        <div class="form-group col-12 col-xl-4">
          <label for="loginId">{{'ADMIN.USER_NAME' | translate}}</label>
          <input type="text"  autocomplete="off" class="form-control input-class" id="userId" name="userId" [(ngModel)]="userformData.user_name">
          <span *ngIf="!userformData.validUserName" class="err">*Invalid User Name</span>
        </div>
        <div class="form-group col-12 col-xl-4">
          <label for="firstName">{{'ADMIN.FIRST_NAME' | translate}}</label>
          <input type="text"  autocomplete="off" class="form-control input-class" id="firstName" name="firstName" [(ngModel)]="userformData.first_name">
          <span *ngIf="!userformData.validFirstName" class="err">*Invalid First Name</span>
        </div>
        <div class="form-group col-12 col-xl-4">
          <label for="lastName">{{'ADMIN.LAST_NAME' | translate}}</label>
          <input type="text"  autocomplete="off" class="form-control input-class" id="lastName" name="lastName" [(ngModel)]="userformData.last_name">
          <span *ngIf="!userformData.validLastName" class="err">*Invalid Last Name</span>
        </div>
        <div class="form-group col-12 col-xl-4">
          <label for="emailId">{{'ADMIN.EMAIL' | translate}}</label>
          <input type="text"  autocomplete="off" class="form-control input-class" id="emailId" name="emailId" [(ngModel)]="userformData.email">
          <span *ngIf="!userformData.validUserEmailId" class="err">*Invalid Email</span>
        </div>
        <div class="form-group col-12 col-xl-4">
          <label for="role">{{'ADMIN.ROLE' | translate}}</label>
          <p-dropdown name="role" styleClass="cust-dropdown" [options]="user_roles_master" [(ngModel)]="userformData.role_id"
            placeholder="Select Role"></p-dropdown>
            <span *ngIf="!userformData.validRole" class="err">*Please Select any Role</span>
        </div>
        <div class="form-group col-12 col-xl-4">
          <label for="region">{{'ADMIN.REGION' | translate}}</label>
          <p-multiSelect name="region" styleClass="cust-dropdown" [options]="regionsDD" [(ngModel)]="selectedRegion" defaultLabel="Select Region"></p-multiSelect>
          <span class="err" *ngIf="!userformData.validRegion">*Select region</span>
        </div>
        
        <div class="form-group col-12 col-xl-4">
          <label for="status">{{'ADMIN.STATUS' | translate}}</label>
          <p-dropdown name="status" styleClass="cust-dropdown" [options]="statusOpts" [(ngModel)]="userformData.is_active"
            placeholder="Select Status"></p-dropdown>
        </div>
        <div *ngIf="userformData.role_id==2" class="form-group col-12 col-xl-4">
          <label ></label>
          <div class="custom-control custom-checkbox mt-2">
            <p-checkbox [binary]="true" label="{{'ADMIN.MAKE_USER_ADMIN' | translate}}" labelStyleClass="ps-2" [(ngModel)]="userformData.is_admin"  [ngModelOptions]="{standalone: true}"></p-checkbox>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="form-group col-6 col-md-6" style="margin-bottom: 10px;">
          <p style="text-align: right">
            <input type="button" class="btn btn-secondary cust-btns" (click)="onCancel()" value="{{'ADMIN.CLEAR' | translate}}">
          </p>
        </div>
        <div class="form-group col-6 col-md-6" style="margin-bottom: 10px;">
          <p style="text-align: left">
            <input *ngIf="!userformData.editing" type="button" class="btn btn-primary cust-btns" style="background-color: #004A96 !important;" (click)="saveUser()" value="{{'ADMIN.CREATE' | translate}}">
            <input *ngIf="userformData.editing" type="button" class="btn btn-primary cust-btns" style="background-color: #004A96 !important;" (click)="saveUser()" value="{{'ADMIN.UPDATE' | translate}}">
          </p>
        </div>
      </div>
    </form>
  </div>
  <div class="container" id="userTable" style="margin-top: 20px; margin-bottom: 50px;padding-bottom:20px">
    <p-table #dt [columns]="userTable" [value]="usersData" [paginator]="true" [rows]="10" 
    [responsive]="false" [scrollable]="true"    [tableStyle]="{ 'min-width': '60rem' }"  [pageLinks]="2" [selectionPageOnly]="true">
      <ng-template pTemplate="colgroup" let-columns>
        
    </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th id="col.header" *ngFor="let col of columns" [ngStyle]="{'width': col.width}" >
            <span [ngStyle]="{'width': col.width}" >{{col.header | translate}} </span>
          </th>
        </tr>
        <tr>
          <th id="thfilter" *ngFor="let col of columns" [ngSwitch]="col.field" [ngStyle]="{'width': col.width}"  >
            <input *ngSwitchCase="'user_name'" pInputText type="text" 
              (input)="dt.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}" >
            <input *ngSwitchCase="'first_name'" pInputText type="text" 
              (input)="dt.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}" >
            <input *ngSwitchCase="'last_name'" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}" >
            <input *ngSwitchCase="'email'" pInputText type="text"  
              (input)="dt.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}" >
            <input *ngSwitchCase="'role_name'" pInputText type="text" 
              (input)="dt.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}" >
              <input *ngSwitchCase="'region'" pInputText type="text" 
              (input)="dt.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}" >
              <input *ngSwitchCase="'status'" pInputText type="text" 
              (input)="dt.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}" >
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
          <td *ngFor="let col of columns" [ngStyle]="{'width': col.width}" >
            <span class="status-text" [ngStyle]="{'width': col.width}"  [ngClass]="{'status-text-inactive':  rowData[col.field]=='Inactive'}" *ngIf="col.field == 'status'">
              {{rowData[col.field]}}
            </span>
            <span *ngIf="col.field != 'edit' && col.field != 'status'" [ngStyle]="{'width': col.width}" >{{rowData[col.field]}}</span>
            <span *ngIf="col.field == 'edit'" [ngStyle]="{'width': col.width}" >
              <img *ngIf="!editing" class="accordion-img-icon pointer-cursor" title='Edit User Role' alt="editIcon"
                (click)="onRowEditInit(rowData)" src="./assets/images/icons/edit_icon.png">
              <img *ngIf="editing && rowData['userId'] === id" class="accordion-img-icon pointer-cursor" alt="cancelIcon"
                (click)="onCancel()" title='Cancel Editing' src="./assets/images/icons/close_icon.png">
            </span> 
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>