import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { SiteCompetitivenessFormData } from "./data/site-competitiveness-form.data";
import { SiteCompetitivenessService } from "./site-competitiveness.service";

@Injectable()

export  class SiteCompetitivenessModel
{
    constructor(private client: SiteCompetitivenessService) { }

    retrieveSiteCompetitivenessStatus() {
        return [
            { label: "Active", value: true },
            { label: "InActive", value: false }
        ];
        
    }

    retrieveColumnHeader()
    {
        return [
            { field : "site_competitive_id", header:"Id", width: "10%"},
            { field : "site_competitive_name", header:"Site Competitiveness Name", width: "65%"},
            { field : "status", header:"Status", width: "20%"},
            { field : "edit", header: "", width: "5%" }
          ];
      
    }

    validateSiteCompForm( data :SiteCompetitivenessFormData):SiteCompetitivenessFormData{
        data.validateCompname = this.validInput(data.site_competitive_name);
        return data;
      }
      saveData(data :SiteCompetitivenessFormData,userId:number):Observable<string>
      {
let subject=new Subject<string>();
let request = {
    "site_competitive_id": data.site_competitive_id,
    "site_competitive_name":data.site_competitive_name,      
    "is_active":data.is_active,
    "modified_by":userId};
    this.client.submitSiteCompetitve(request).subscribe({
        next:(submitSiteCompetitveResponse:any) => {
        this.processSaveResponse(submitSiteCompetitveResponse,subject);
    }, error:(error) => {
        subject.error(error)
      }
      });
      return subject.asObservable();
    }

  retrieveSiteCompetitiveCollection(requestedBy:number):Observable<SiteCompetitivenessFormData[]>{
    let subject=new Subject<SiteCompetitivenessFormData[]>();
    let resultCollection:SiteCompetitivenessFormData[]=[];
    
    this.client.retrieveSiteCompetitveList(requestedBy).subscribe({
        next:(siteCompentitiveCollectionResponse:any) => {
     
        let apiResponse: any = siteCompentitiveCollectionResponse;
        let apiResult: any = apiResponse.result;
        if (apiResult.status_id == 200) {
            let apiResultData: any = apiResponse.data;
            apiResultData.forEach((element:any) => {
                let resultdata = element as SiteCompetitivenessFormData;
                resultdata.edit = true;
                resultdata.status = resultdata.is_active ? "Active" : "Inactive";
                resultCollection.push(resultdata);

            });
            subject.next(resultCollection);
        }
        else {
            subject.error(apiResult.message)
        }
    },
        error:(error) => {
            subject.error(error);
        }
  });
        return subject.asObservable();

  }
  
  private processSaveResponse(updatevendorResponse: any, subject: Subject<string>) {
    let apiResponse: any = updatevendorResponse;
    let apiResult: any = apiResponse.result;
    if (apiResult.status_id == 200) {
        subject.next(apiResult.message);
    }
    else {
        subject.error(apiResult.message);
    }
}
  validInput(validStr:any) {
    return validStr.length > 1;
  }
}