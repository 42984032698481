<div *ngIf="userAccessPermission.site_access.requestAccess" class="container-fluid" id="rateList" >
    <div class="container mx-1 px-0" style="margin-top: 40px" id="requestListContainer">
      
      <h5>{{'REQUESTS' | translate}}</h5>
      <div class="d-flex justify-content-between request-list" style="width: 97.2vw" >
          
          <div class="py-2" style="text-align: left !important">
            
            <p-dropdown name="transportType" class="cust-dropdown"
                [options]="transportTypeMaster"
                [(ngModel)]="selectedTransportType"
                (onChange)="onTransportTypeChange($event)"
            >
            </p-dropdown>
            </div>
            <div class="py-2">
              <button pButton type="button" label="{{'EXPORT_TO_EXCEL' | translate}}"
                                icon="pi pi-file-excel" class="p-button-outlined p-button-secondary ms-0"
                                (click)="exportExcel()"
                                style="text-align: right !important" *ngIf="!isExternalUser"></button>            
            </div>
        </div>
        <div  id="userTableRequestList" style="margin-top: 20px; margin-bottom: 100px;padding-bottom: 20px;">
          <p-table #dt1 [columns]="requestlistCols" [rows]="50"  [value]="requestListData" [paginator]="true" [rowsPerPageOptions]="[10,25,50,75,100]"  [pageLinks]="screenWidth<750? '1':'2'" [selectionPageOnly]="true"
          [responsive]="false" [scrollable]="true"  scrollHeight="43vh" scrollDirection="vertically"  [resizableColumns]="true" columnResizeMode="expand" [style]="{width: '97.2vw'}"   [tableStyle]="{ 'min-width': '60rem' }">
          <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
              <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
            </colgroup>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th id="col.header" *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width}">
               
               <span [ngStyle]="{'width': col.width}">{{col.header | translate}}
                <p-sortIcon [field]="col.field"></p-sortIcon></span> 
              </th>
            </tr>
            <tr>
              <th id="thfilter" *ngFor="let col of columns" [ngSwitch]="col.field" [ngStyle]="{'padding-top':  '7px' }">
                <input *ngSwitchCase="'equipmentType'" pInputText type="text" 
                (input)="dt1.filter($event.target.value, col.field, 'contains')"  [ngStyle]="{'width': '100%'}" >
                <input *ngSwitchCase="'referenceNumber'" pInputText type="text" 
                  (input)="dt1.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}"  >
                <input *ngSwitchCase="'transportType'" pInputText type="text" 
                  (input)="dt1.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}" >
                  <input *ngSwitchCase="'payer'" pInputText type="text" 
                  (input)="dt1.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}" >
               
                <input *ngSwitchCase="'requestType'" pInputText type="text" 
                  (input)="dt1.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}" >
                <input *ngSwitchCase="'requestor'" pInputText type="text" 
                  (input)="dt1.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}" >
                <input *ngSwitchCase="'businessUnit'" pInputText type="text" 
                  (input)="dt1.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}" >
                <input *ngSwitchCase="'articleNumber'" pInputText type="text" 
                  (input)="dt1.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}" >
                <input *ngSwitchCase="'product'" pInputText type="text" 
                  (input)="dt1.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}" >
                <input *ngSwitchCase="'orgin'" pInputText type="text" 
                  (input)="dt1.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}" >
                <input *ngSwitchCase="'destination'" pInputText type="text" 
                  (input)="dt1.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}" >
                <p-multiSelect *ngSwitchCase="'status'"   maxSelectedLabels="1" [ngStyle]="{'width': '100%'}"
                   appendTo="body" [options]="statusList" defaultLabel="{{'' | translate}}"  [panelStyle]="{minWidth:'20em'}"  
                   (onChange)="onStatusChange($event);dt1.filter($event.value, col.field, 'in')" (onPanelHide)="onStatusChange($event);dt1.filter($event.value, col.field, 'in')"></p-multiSelect>
                <p-calendar *ngSwitchCase="'requestedDate'" [baseZIndex]="100002" dateFormat="yy-mm-dd" dataType="string"  appendTo="body" [showButtonBar]="true"
                 (onSelect)="onCalendarChange($event);dt1.filter(filterDate, col.field, 'contains')"  (onClearClick)="dt1.reset()" [readonlyInput]="true"   [ngStyle]="{'width': '100%'}" >
                </p-calendar>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td *ngFor="let col of columns" [ngStyle]="{'width': col.width}"  >
                <span *ngIf="col.field == 'status'" [ngStyle]="{'display': 'inline-flex'}" class="status-text" [ngClass]="{'status-text-submitted':  rowData[col.field]=='BU Submitted', 'status-text-progress' : rowData[col.field]=='Bid In Progress'}">
                  {{rowData[col.field]}}
                </span>
                <span *ngIf="(col.field != 'referenceNumber') && (col.field != 'status')" [ngStyle]="{'width': col.width,'display': 'inline-flex'}" style="overflow-wrap: anywhere;">{{rowData[col.field]}}</span>
                <span *ngIf="col.field == 'referenceNumber'" (click)="displayRequestInfo(rowData)"
                  style="color: blue; cursor:pointer;"  [ngStyle]="{'width': col.width,'display': 'inline-flex'}">{{rowData[col.field]}}</span>
              </td>
            </tr>
          </ng-template>
        </p-table>
        </div>
    </div>
      <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" color="#fff" type="line-scale-pulse-out"></ngx-spinner>
</div>
