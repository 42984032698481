<div id="vendor-management" class="container">
     <form autocomplete="off">
      <div class="row">
        <div class="form-group col-12 col-xl-4">
          <label for="vendorName">Vendor Name</label>
          <input type="text"  autocomplete="off" class="form-control input-class" id="vendorName" name="vendorName" [(ngModel)]="vendorFormData.vendor_name">
          <span class="err" *ngIf="!vendorFormData.validVendorName">*Invalid Vendor Name</span>
        </div>
        
        <div class="form-group col-12 col-xl-4">
          <label for="vendorEmailId">Email</label>
          <input type="text"  autocomplete="off" class="form-control input-class" id="vendorEmailId" name="vendorEmailId" [(ngModel)]="vendorFormData.vendor_email">
          <span class="err" *ngIf="!vendorFormData.validVendorEmail">*Invalid Email</span>
        </div>

        <div class="form-group col-12 col-xl-4" style="display: grid">
          <label for="transportType">Transport Type</label>
          <p-multiSelect name="transportType" styleClass="cust-dropdown" [options]="transportTypeMasterData" [(ngModel)]="selectedTransportType" ></p-multiSelect>
          <span class="err" *ngIf="!vendorFormData.validVendorTransportType">*Select a TransportType</span>
        </div>
        <div class="form-group col-12 col-xl-4" style="display: grid">
          <label for="region" style="margin: 8px 0 2px 0;">{{'ADMIN.REGION' | translate}}</label>
          <p-multiSelect name="region" styleClass="cust-dropdown" [options]="regionsDD" [(ngModel)]="selectedRegion" defaultLabel="Select Region"></p-multiSelect>
          <span class="err" *ngIf="!vendorFormData.validRegion">*Select region</span>
        </div>
        
        <div class="form-group col-12 col-xl-4" style="display: grid">
          <label for="status">Status</label>
          <p-dropdown name="vendorStatus" styleClass="cust-dropdown" [options]="statusOpts" [(ngModel)]="vendorFormData.is_active"
            placeholder="Select Status"></p-dropdown>
        </div>
        
      </div>

      
      <div class="row" style="margin-top: 18px">
        <div class="form-group col-6 col-md-6" style="margin-bottom: 10px;">
          <p style="text-align: right">
            <input type="button" class="btn btn-secondary cust-btns" (click)="onCancel()" value="Clear">
          </p>
        </div>
        <div class="form-group col-6 col-md-6" style="margin-bottom: 10px;">
          <p style="text-align: left">
            <input *ngIf="!vendorFormData.editing" type="button" class="btn btn-primary cust-btns" style="background-color: #004A96 !important;" (click)="saveVendor()" value="Create">
            <input *ngIf="vendorFormData.editing" type="button" class="btn btn-primary cust-btns" style="background-color: #004A96 !important;" (click)="saveVendor()" value="Update">
          </p>
        </div>
      </div>
    </form>
  </div>
  <div class="container" id="userTable" style="margin-top: 20px; margin-bottom: 100px;padding-bottom:20px">
    <p-table #dt1 [columns]="vendorTableColumns" [value]="vendorCollection" [paginator]="true" [rows]="10" [responsive]="false" [scrollable]="true" 
    [resizableColumns]="true" columnResizeMode="expand"   [tableStyle]="{ 'min-width': '70rem' }" [pageLinks]="2" [selectionPageOnly]="true">
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
        </colgroup>
    </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th id="col.header" *ngFor="let col of columns">
            {{col.header}}
          </th>
        </tr>
        <tr>
          <th id="thfilter" *ngFor="let col of columns" [ngSwitch]="col.field">
            <input *ngSwitchCase="'vendor_name'" pInputText type="text" 
              (input)="dt1.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}">
            <input *ngSwitchCase="'vendor_company'" pInputText type="text" 
              (input)="dt1.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}">
            <input *ngSwitchCase="'vendor_email'" pInputText type="text"
              (input)="dt1.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}">
         
              <input *ngSwitchCase="'status'" pInputText type="text" 
              (input)="dt1.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}">

              <input *ngSwitchCase="'transport_type'" pInputText type="text" 
              (input)="dt1.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}">
              <input *ngSwitchCase="'vendorRegion'" pInputText type="text"
              (input)="dt1.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}">                  </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns">
            <span *ngIf="col.field == 'status'" class="status-text" [ngClass]="{'status-text-inactive':  rowData[col.field]=='Inactive'}">
              {{rowData[col.field]}}
            </span>
            <span *ngIf="col.field=='vendor_users'"><a class="viewhyperlink" style="cursor: pointer;" (click)="displayVendorUsers(rowData)">View Users</a></span>
            <span *ngIf="col.field != 'IsActive' && col.field != 'edit' && col.field !='vendor_users' && col.field != 'status'">{{rowData[col.field]}}</span>
            <span *ngIf="col.field == 'IsActive'"><p *ngIf="rowData[col.field] == true">Active</p><p *ngIf="rowData[col.field] == false">InActive</p></span>
            <span *ngIf="col.field == 'edit'">
              <img *ngIf="!vendorEditing" class="accordion-img-icon pointer-cursor" alt="Edit" title='Edit Vendor'
                (click)="onRowVendorEditInit(rowData)" src="./assets/images/icons/edit_icon.png">
              <img *ngIf="vendorEditing && rowData['VendorId'] === vId" alt="Cancel" class="accordion-img-icon pointer-cursor"
                (click)="onCancel()" title='Cancel Editing' src="./assets/images/icons/close_icon.png">
            </span> 
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>


  <p-dialog header="{{vendorHeader}}- Users" [(visible)]="displayUsers" [style]="{width: '80vw'}">
    <p-table [value]="vendorUsersFiltered" [scrollable]="true" [style]="{width:'100%'}"
         dataKey="vendor_id" [autoLayout]="true">
          
          <ng-template pTemplate="header">
            <tr>
              <th id="first_name" style="width: 150px;">First Name</th>
              <th id="last_name" style="width: 120px;">Last Name</th>
              <th id="email" style="width:180px">Email</th>
              <th id="region" style="width:120px">Region</th>
              <th id="status" style="width: 120px;">Status</th>              
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-venUser let-ri="rowIndex">
            <tr>
              <td style="width: 150px;">
                <div>
                  {{venUser.firstName}}
                </div>
              </td>
              <td style="width: 120px;">
                {{venUser.lastName}} 
              </td>
              <td style="width: 180px;">
                {{venUser.email}} 
              </td>
              <td style="width: 120px;">
                {{venUser.region}} 
              </td>
              <td style="width: 120px;">
                {{venUser.status}}
              </td>              
              
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td>
                No users exist for this vendor
              </td>
            </tr>
          </ng-template>
    </p-table>
  </p-dialog>
