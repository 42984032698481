export class RequestFormData {
    //Common
    status: string="";
    isActive: boolean=true;
    modifiedBy: number=0;
    requestedBy: number=0;
    requesterMailId !: string;
    requestId: number = 0;
    requestorUserid: string="";
    requestRefNumber: string="";
    requestStatusId: number=0;


    //General
    businessUnit: string="";
    product: string="";
    requestor: string="";
    requestTypeId: number=0;
    startDate: string="";
    transportType: number=0;
  

    //Service Provider
    volumeType:string="Annual";
    campaignSeasonalVolume:number=0;
    campaignSeasonalVolumeUid:number=0;
    csStartDate:string="";
    csEndDate:string="";
    annualVolume: number=0;
    annualVolumeUnitId: number=0;
    annualVolumeStartDate:string="";
   
    


    //ShippingDetails
    destinationPlantId:number=0;
    destinationCity: string="";
    destinationCountry: string="";
    destinationName: string="";
    destinationState: string="";
    destinationZipCode: string="";

    originPlantId:number=0;
    orginCity: string="";
    orginCountry: string="";
    orginName: string="";
    orginState: string="";
    orginZipCode: string="";


    //Additional info
    additionalAttchment: any;
    additionalAttchment2: any;
    additionalComments: string="";
    additionalFileExtn: string="";
    additionalFileExtn2: string="";
    additionalFileName: string="";
    additionalFileName2: string="";
    costVsService: string = "";
    isCost: any;
    isService: any;
    prefferedVendor: string="";
    priority: any;
    sdsAttachment: any;
    sdsAttchmentFileExtn: string="";
    sdsFileName: string="";
    region:number= Number(sessionStorage.getItem('regionId'));
    currencyId:number=0;
    mandadoryDocsInsideTruckDetails:any;
    mandadoryDocsInsideTruckIdSelected:any;
}