<div id="railTypeDiv" class="container">
          <form autocomplete="off">
            <div class="row">              
              <div class="form-group col-12 col-xl-6">
                <label for="railType">Rail CarType</label>
                <input type="text" class="form-control input-class" id="railtypeName" name="railtypeName" [(ngModel)]="railCarTypeData.rail_type_name" >
                <span *ngIf="!railCarTypeData.validateRailTypename" class="err">Invalid Product Name</span>
              </div>
              <div class="form-group col-12 col-xl-6" style="display: grid">
                <label for="status">Status</label>
                <p-dropdown name="articleStatus" styleClass="cust-dropdown" [options]="statusOpts" [(ngModel)]="railCarTypeData.is_active"
                  placeholder="Select Status"></p-dropdown>
              </div>
            </div>
            <div class="row mt-4">
              <div class="form-group col-6 col-md-6" style="margin-bottom: 10px;">
                <p style="text-align: right">
                  <button type="button" class="btn btn-secondary cust-btns" (click)="onCancel()">Clear</button>
                </p>
              </div>
              <div class="form-group col-6 col-md-6" style="margin-bottom: 10px;">
                <p style="text-align: left">
                  <input *ngIf="!railCarTypeData.editing" type="button" class="btn btn-primary cust-btns" style="background-color: #004A96 !important;" (click)="updateRailCarType()" value="Create">
                  <input *ngIf="railCarTypeData.editing" type="button" class="btn btn-primary cust-btns" style="background-color: #004A96 !important;" (click)="updateRailCarType()" value="Update">
                </p>
              </div>
            </div>
          </form>
        </div>
        <div class="container" id="userTable" style="margin-top: 20px; margin-bottom: 50px;padding-bottom:20px">
          <p-table #dt5 [columns]="railCarTypeTableColumns" [value]="railCarTypeCollection" [paginator]="true" 
          [pageLinks]="2" [selectionPageOnly]="true" [rows]="10" [responsive]="false" [scrollable]="true" [resizableColumns]="true" columnResizeMode="expand"   [tableStyle]="{ 'min-width': '40rem' }">
            <ng-template pTemplate="colgroup" let-columns>
              <colgroup>
                <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
              </colgroup>
          </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th id="col.header" *ngFor="let col of columns">
                  {{col.header}}
                </th>
              </tr>    
              <tr>
                <th id="thfilter" *ngFor="let col of columns" [ngSwitch]="col.field">
                  <input *ngSwitchCase="'rail_type_id'" pInputText type="text"
                    (input)="dt5.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}">
                  <input *ngSwitchCase="'rail_type_name'" pInputText type="text"
                    (input)="dt5.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}">                  
                    <input *ngSwitchCase="'status'" pInputText type="text"
                    (input)="dt5.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}">
                </th>
              </tr>          
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td *ngFor="let col of columns">
                  <span *ngIf="col.field == 'status'" class="status-text" [ngClass]="{'status-text-inactive':  rowData[col.field]=='Inactive'}">
                    {{rowData[col.field]}}
                  </span>
                  <span *ngIf="col.field != 'edit' && col.field != 'status'">{{rowData[col.field]}}</span>
                 <span *ngIf="col.field == 'edit'">
                    <img *ngIf="!editing" class="accordion-img-icon pointer-cursor" title='Edit Rail Type' alt="editIcon"
                      (click)="onRowRailTypeEditInit(rowData)" src="./assets/images/icons/edit_icon.png">
                    <img *ngIf="editing && rowData['userId'] === id" class="accordion-img-icon pointer-cursor" alt="cancelIcon"
                      (click)="onCancel()" title='Cancel Editing' src="./assets/images/icons/close_icon.png">
                  </span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>