import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn:"root"
})
export  class RailCarTypeServices
{
    constructor(private client: HttpClient) { }
    
retrieveRailtypeList(requestedBy: number){
    let link=environment.getRailCarTypeList.replace("{requestedby}", requestedBy.toString())
 
    return this.client.get(link).pipe(map((response)=>response));
  } 
  submitRailCarType(data: any) {
    return this.client.post(environment.submitRailCartype, data).pipe(map((response) => response));
  }
}