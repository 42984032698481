import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DryTruckRequestFormData } from "src/app/requests/data/formdata/dry-request-form.data";
import { RequestMasterData } from "src/app/requests/data/masterdata/request-master.data";
import { DryTruckRequestFormModel } from "src/app/requests/dry-request-form.model";
import { DryTruckRequestMasterModel } from "src/app/requests/dry-request-master.model";
import { RequestFormModel } from "src/app/requests/request-form.model";
import { RequestMasterModel } from "src/app/requests/request-master.model";
import { ProductModel } from "src/app/shared/product/products.model";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { CommonRequestComponent } from "../../request-form-common.component";
import { Subscription } from "rxjs/internal/Subscription";
import { AppServiceService } from "src/app/services/app-service.service";
import { TranslateService } from "@ngx-translate/core";
import { PlantCodeMasterData } from "../../../data/masterdata/request-master.data";
import { DropDownMaster } from "src/app/shared/dropdown-master.data";
import { FileUpload } from "primeng/fileupload";



@Component(
  {
    selector: 'app-dry-request-sa-form',
    templateUrl: '..\\..\\..\\shared-templates\\request-form-template.html',
    styleUrls: ['..\\..\\..\\shared-templates\\request-form-template.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [LoggedInUserInfoModel, ProductModel,
      { provide: RequestFormModel, useClass: DryTruckRequestFormModel },
      { provide: RequestMasterModel, useClass: DryTruckRequestMasterModel },
      FileUpload

    ]
  }
)

export class DryRequestFormSAComponent extends CommonRequestComponent implements OnInit,OnDestroy
 {
 
  private subscriptionName: Subscription; //important to create a subscription
  messageReceived: any;


    // override requestMaster: RequestMasterData= new RequestMasterData;
    // override requestData: DryTruckRequestFormData = new DryTruckRequestFormData;
    override requestData:any;
    override requestMaster:any;
    _toast: ToastrService
    _translate:TranslateService
    pageLanguage:string=""
    override is_FISPQ_fileUpload :boolean=false;
    selectedtruckIds!: any[];
  
    constructor(userAccessModel: LoggedInUserInfoModel, masterModel: RequestMasterModel,
      private productModel: ProductModel, requestModel: RequestFormModel,
      toast: ToastrService, router: Router, spinner: NgxSpinnerService,translate:TranslateService,
       private spinners: NgxSpinnerService, route: ActivatedRoute,private _appServ: AppServiceService,public fileUpload: FileUpload) {
        
      super(userAccessModel, masterModel, requestModel, router, toast, spinner,route,translate);
      this._toast=toast;
      this._translate=translate;
      this.subscriptionName= this._appServ.getUpdate().subscribe(message => { //message contains the data sent from service
      this.messageReceived = message;
      
      let newLang=sessionStorage.getItem('selectedLanguage');

      if(this.pageLanguage!=newLang && this.pageLanguage!=""){
        this.ngOnInit();
      }
      });
    }
    ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
      this.subscriptionName.unsubscribe();
  }
  
  
  
    ngOnInit(): void {
      this.pageLanguage=sessionStorage['selectedLanguage'];
      this.startDateLable="START_DATE";
      this.transportType = 2;
      this.requestMaster = new RequestMasterData();
      this.requestData = new DryTruckRequestFormData();
      this.plantCodeShippingDetails=new PlantCodeMasterData();
      this.requestData.transportType = 2;
      this.bindfiledVisiblity();
      this.retrieveHeaderValues();
      //login
      this.validateloggedInUser();
      //Request Master
      this.retrieveMasterData(this.transportType);
      //plantcode with shipping data
      this.retrievePlantCodeMasterData(this.transportType);
      //Article
      // this.retrieveArticleList(this.spinners)
      //retrieve Query Parameters
      this.retrieveQueryPramaters();
      this.retriveLang(this.pageLanguage);
    }
    public clearForm() {
      this.OnCancel();
      }
    bindfiledVisiblity()
    {
      this.fieldVisiblity.requestType=true;
      this.fieldVisiblity.startDate=true;
      this.fieldVisiblity.requestor=true;
      this.fieldVisiblity.bussinessunit=true;
      this.fieldVisiblity.articleNumber=true;
      this.fieldVisiblity.product=true;
      this.fieldVisiblity.mfg=true;
      this.fieldVisiblity.freightClass=true;
      this.fieldVisiblity.cleaninCost=true;
      this.fieldVisiblity.equipmentType=true;
      this.fieldVisiblity.equipmentType=true;
      
      // Service Provider
      this.fieldVisiblity.volumeType = true;
      this.fieldVisiblity.annualVolume=true;
      this.fieldVisiblity.nitrogenBlanket=true;
      this.fieldVisiblity.vacuumTrailer=true;
      this.fieldVisiblity.cleaningProcedures=true;
      this.fieldVisiblity.mandDocsInsideTruck=true;
      this.fieldVisiblity.mandDocsInsideTruckDoc=false;
      this.fieldVisiblity.mandDocsInsideTruckSa=true;
  
      this.fieldVisiblity.averageProduct=true;
      

      

      this.fieldVisiblity.monthlyVolume=false;
      this.fieldVisiblity.dropTrail=false;
      this.fieldVisiblity.days=false;
      this.fieldVisiblity.hazardous=false;
      this.fieldVisiblity.materialTopLoad=false;
      this.fieldVisiblity.loadingHrs=false;
      this.fieldVisiblity.unloadingHrs=false;

      this.fieldVisiblity.weekendDeliveryYN=false;
      this.fieldVisiblity.priorProductRestriction=false;
      //Shipping->Orgin
      this.fieldVisiblity.originPlant=true;
      this.fieldVisiblity.shippingName=true;
      this.fieldVisiblity.orginCity=true;
      this.fieldVisiblity.orginState=true;
      this.fieldVisiblity.orginCountry=true;
      this.fieldVisiblity.orginZipCode=true;
      //shipping-> destination
      this.fieldVisiblity.destinationPlant=true;
      this.fieldVisiblity.consigneeName=true;
      this.fieldVisiblity.destinationCity=true;
      this.fieldVisiblity.destinationState=true;
      this.fieldVisiblity.destinationCountry=true;
      this.fieldVisiblity.destinationZipCode=true;
  
      //Additional Info

      this.fieldVisiblity.comments=true;  
      this.fieldVisiblity.materialDoc=true;
      this.fieldVisiblity.materialLink=true;
      this.fieldVisiblity.sdsLink=true;
      this.fieldVisiblity.sdsAttachment=true;
      this.fieldVisiblity.additionalAttachment=true;
      this.fieldVisiblity.additionalAttachment2=true;
      this.fieldVisiblity.customerPreferedCarrier=false;
      this.fieldVisiblity.costVsService=false;   
      
    }
    override onmandadoryDocsInsideTruck(event: any){
      if(event.target.value=='Yes'){    this.OnRemoveFISPQAttachment()
        
      }
      this.mandatorydocsfunction();
    }
    override onmandDocsChangeEvent(event: any){
      if(this.requestData.mandadoryDocsInsideTruck=="Yes" && event.value.includes(1))
   {
    this.fieldVisiblity.mandDocsInsideTruckDoc=true;
   } else{
    this.fieldVisiblity.mandDocsInsideTruckDoc=false;
  } 
    }  
  
    override onArticleClear(event:any){
      this.resetArticleData();
    }
  
    onArticleComplete(event:any){
      super.retrieveProductDetails(event)
    }
    onArticleSelected(event:any){
      this.processArticleNumber(event);
  
    }
  
    retrieveHeaderValues() {
      this.title = "Dry Truck";
      this.description = "A \"Rush Request\" defined as Procurement providing rates to BU after 2 business days otherwise Procurement will provide rates within 7 business days for standard requests.";
    }
    resetArticleData() {
      this.requestData.mfg = "";
      this.selectedMFGCode = "";
      this.requestData.freighClass = "";
      this.requestData.cleaningCost = "";
      this.selectedArticle = [];
      this.requestData.product = "";
      this.requestData.articleNumber = "";
  
    }
    GenerarateArticleNumberEvent() {
      this.selectedArticle.label = this.requestData.articleNumber;
      return {
        label: this.requestData.articleNumber,
        value: this.requestData.product
      };
    }
  
    handlehazardousChange(event:any)
    {
      console.log("")
    }
    processArticleNumber(event:any){
      this.spinners.show();
      this.requestData.articleNumber = event.label;
      this.requestData.product=event.value;
      this.productModel.retrieveProductForArticle(event.label).subscribe({
        next:result => {
          this.requestData.mfg = result[0].mfg;
          this.requestData.freighClass = result[0].friegeClass;
          this.requestData.cleaningCost = result[0].newRate;
          this.spinners.hide();
        },
        error:error => {
          this._toast.error(this._translate.instant(error),this._translate.instant("ERROR"))
          this.spinners.hide();
        }
        
      });
    }
  
   override OnCancel() {
      
      this.clearData(2);
      this.requestData.articleNumber="";
      this.requestData.cleaningCost="";
      this.requestData.freighClass="";
      this.requestData.mfg="";
      this.requestData.campaignSeasonalVolume=0

    //Service provider
    this.requestData.days=0
    this.requestData.dropTrail="";
    this.requestData.monthlyVolume=0;
    this.requestData.monthlyVolumeUnitId=0;
    this.requestData.hazardous="";
    this.requestData.loadingHours='';
    this.requestData.materialTopLoad="";
    this.requestData.nitrogenBlanket="";
    this.requestData.unloadingHour='';
    this.requestData.vaccumeTrailer="";
    this.requestData.priorProductRestriction="";
    this.requestData.priorProductRestrictionDetails="";
    this.requestData.vaccumeTrailer="";
    this.requestData.mandadoryDocsInsideTruck="";
    this.requestData.mandadoryDocsInsideTruckId=0;
    this.requestData.averageProduct="";
    this.requestData.averageProductId=0;
    this.requestData.cleaningProcedures="";
    this.requestData.equipmentTypeId=0;
    this.requestData.specificEquipmentTypeId=0;

    this.requestData.mandadoryDocsInsideTruckIdSelected=0;
    this.fileUpload.clear();
      this.OnRemoveFISPQAttachment()


    this.resetArticleData();
    }
    fileuploadclear(fileUpload: FileUpload){
      fileUpload.clear();
      this.OnRemoveFISPQAttachment()
    }
    onSubmitRequest() {
      this.handlingDefaultDropdownValues();
      this.requestData.equipmentTypeId=this.requestData.equipmentTypeId??0;
      this.requestData.annualVolumeUnitId=this.requestData.annualVolumeUnitId??0;
      this.requestData.averageProductId=this.requestData.averageProductId??0;
   
  
  let updatemandadoryDocsInsideTruckId:any =[];
  if(this.requestData.mandadoryDocsInsideTruckIdSelected){
  this.requestData.mandadoryDocsInsideTruckIdSelected.forEach((element:any) => {
    let dropdownData = {
        masterId: element,
        masterValue: "",
        masterOrderBy: 0
    };

    updatemandadoryDocsInsideTruckId.push(dropdownData);
  
});
  }
    this.requestData.mandadoryDocsInsideTruckDetails= updatemandadoryDocsInsideTruckId;

      this.createRequest();
    }
  

    requestTypeChangeEvent(event:any){
      console.log('');
    }
    onAnnualVolumeChange(event: any){
      console.log('');
    }

    bindConditionalFields(){
      let DryReqData=this.requestData;
   
      if (DryReqData.FISPQFileName != undefined && DryReqData.FISPQFileName != "") {

        this.is_FISPQ_fileUpload = true;
      }
      if(this.requestData.mandadoryDocsInsideTruckDetails?.length>0)
      this.selectedtruckIds=[];
      this.requestData.mandadoryDocsInsideTruckDetails.forEach((element:any)=>
        {
          let resultdata=new DropDownMaster();
          resultdata.value=Number(element.masterId);
        
          resultdata.label=element.masterValue;
          this.selectedtruckIds.push(resultdata.value);
          
        })
        this.requestData.mandadoryDocsInsideTruckIdSelected= this.selectedtruckIds;
if(this.selectedtruckIds != undefined){
        if(this.requestData.mandadoryDocsInsideTruck=="Yes" && this.selectedtruckIds.includes(1))
        {
         this.fieldVisiblity.mandDocsInsideTruckDoc=true;
        } else{
         this.fieldVisiblity.mandDocsInsideTruckDoc=false;
       } 
      }
      else{
        this.fieldVisiblity.mandDocsInsideTruckDoc=false;
      } 
      this.priorProdDetailsRestrictions();
         this.mandatorydocsfunction();
    }
    mandatorydocsfunction(){

      if(this.requestData.mandadoryDocsInsideTruck=="Yes" && this.requestData.mandadoryDocsInsideTruckIdSelected.includes(1))
        {
         this.fieldVisiblity.mandDocsInsideTruckDoc=true;
        }
        else{
          this.fieldVisiblity.mandDocsInsideTruckDoc=false;
          this.OnRemoveFISPQAttachment()
        }
    }
    priorProdDetailsRestrictions() {
      if(this.requestData.priorProductRestriction=='Yes' && this.fieldVisiblity.priorProductRestriction)
      this.fieldVisiblity.priorProdDetailsRestrictions = true;
    }
    onPlantSelectPopulate(event:any,index:number){}
    
    override OnFISPQFileUpload(event:any,fileUpload: FileUpload) {
      this.spinners.show();
      let fileData = event.files[0];
      const element = event.files[0];
      if (element.size > 3145728) {
        this.spinners.hide();
        this._toast.warning(this._translate.instant("FILE_LIMIT_EXCEEDED"));
        fileUpload.clear();
        this.OnRemoveFISPQAttachment()
        return false;
      } else {
        this.requestData.FISPQFileName = fileData.name;
        this.requestData.FISPQFileExtension = fileData.type;
        this.requestData.FISPQAttachment = "";
        let fileReader = new FileReader();
        fileReader.onload = (e) => {
          this.requestData.FISPQAttachment = fileReader.result;
          this.requestData.FISPQAttachment = this.requestData.FISPQAttachment.replace(`data:${this.requestData.FISPQFileExtension};base64,`, '');
          this.is_FISPQ_fileUpload = true;
        }
        fileReader.readAsDataURL(fileData);
        this.spinners.hide();
        return true;
      }

    }
    onSpecialRequirement(event:any){  }
    override OnRemoveFISPQAttachment() {
      this.requestData.FISPQAttachment = "";
      this.requestData.FISPQFileName = "";
      this.requestData.FISPQFileExtension = "";
      this.is_FISPQ_fileUpload = false;
    }
  }