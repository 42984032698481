import { TruckLoadVendorResponseData } from "./truck-load-vendor-response.data";

export class TruckLoadVendorResponseFormData extends TruckLoadVendorResponseData {

    disable: boolean = false;
    validBidAmount: boolean = true;
    validComments: boolean = true;
    validCurrency: boolean = true;
    validRank: boolean = true;
    validRank2 :boolean =true;
    validVendor: boolean = true;
    validDueDate:boolean = true;
}