import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ProductModel } from "src/app/shared/product/products.model";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { TruckLoadRequestFormData } from "../data/formdata/truck-load-request-form.data";
import { TruckLoadRequestMasterData } from "../data/masterdata/truck-load-request-master.data";
import { RequestFormModel } from "../request-form.model";
import { RequestMasterModel } from "../request-master.model";
import { TruckLoadRequestFormModel } from "../truck-load-request-form.model";
import { TruckLoadRequestMasterModel } from "../truck-load-request-master.model";
import { CommonRequestComponent } from "./request-form-common.component";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { AppServiceService } from "src/app/services/app-service.service";

@Component(
    {
      selector: 'app-truck-load-request-form',
      templateUrl: '..\\shared-templates\\request-form-template.html',
      styleUrls: ['..\\shared-templates\\request-form-template.css'],
      encapsulation: ViewEncapsulation.None,
      providers: [LoggedInUserInfoModel, ProductModel,
        { provide: RequestFormModel, useClass: TruckLoadRequestFormModel },
        { provide: RequestMasterModel, useClass: TruckLoadRequestMasterModel },
       
      ]
    }
  )
  
  export class TruckLoadFormComponent extends CommonRequestComponent implements OnInit,OnDestroy{
   
    private subscriptionName: Subscription; //important to create a subscription
    messageReceived: any;
    // override requestMaster: TruckLoadRequestMasterData= new TruckLoadRequestMasterData;
     // override requestData:  RequestFormData
       override requestData:any
       override requestMaster:any
    // override requestData: RequestData = new RequestData;
    pageLanguage:string="";
    constructor(userAccessModel: LoggedInUserInfoModel, masterModel: RequestMasterModel,
        private productModel: ProductModel, requestModel: RequestFormModel,
        toast: ToastrService, router: Router, spinner: NgxSpinnerService,
         private spinners: NgxSpinnerService, route: ActivatedRoute,translate:TranslateService,private _appServ: AppServiceService) {          
        super(userAccessModel, masterModel, requestModel, router, toast, spinner,route,translate);
        this.subscriptionName= this._appServ.getUpdate().subscribe(message => { //message contains the data sent from service
          this.messageReceived = message;    
          this.ngOnInit();
        });
      }

    ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
        this.subscriptionName.unsubscribe();
    }
    
    ngOnInit(): void {
      this.transportType = 3;
      this.startDateLable="START_DATE";
      this.pageLanguage=sessionStorage['selectedLanguage'];
      this.requestMaster = new TruckLoadRequestMasterData();
       this.requestData = new TruckLoadRequestFormData();
      this.requestData.transportType = 3;
      this.bindfiledVisiblity();
      this.retrieveHeaderValues();
      //login
      this.validateloggedInUser();
      //Request Master
      this.retrieveMasterData(this.transportType);
      //Article
   
      //retrieve Query Parameters
      this.retrieveQueryPramaters();
      this.retriveLang(this.pageLanguage);
    }
  
    bindfiledVisiblity()
  {
    this.fieldVisiblity.requestType=true;
    this.fieldVisiblity.startDate=true;
    this.fieldVisiblity.requestor=true;
    this.fieldVisiblity.bussinessunit=true;
    this.fieldVisiblity.product=true;
    this.fieldVisiblity.equipmentType=true;

    // Service Provider
    this.fieldVisiblity.monthlyVolume=true;
    this.fieldVisiblity.annualVolume=true;
    this.fieldVisiblity.tempControl=true;
    this.fieldVisiblity.dropTrail=true;
    this.fieldVisiblity.days=true;
    this.fieldVisiblity.hazardousMandatory=true;
    this.fieldVisiblity.seasonal=true;
    this.fieldVisiblity.seasonalComments=true;
    this.fieldVisiblity.unNumber=true;
    this.fieldVisiblity.tankerEndorsement=true;

    //Shipping->Orgin
    this.fieldVisiblity.shippingName=true;
    this.fieldVisiblity.orginCity=true;
    this.fieldVisiblity.orginState=true;
    this.fieldVisiblity.orginCountry=true;
    this.fieldVisiblity.orginZipCode=true;
    //shipping-> destination
    this.fieldVisiblity.consigneeName=true;
    this.fieldVisiblity.destinationCity=true;
    this.fieldVisiblity.destinationState=true;
    this.fieldVisiblity.destinationCountry=true;
    this.fieldVisiblity.destinationZipCode=true;

    //Additional Info
    this.fieldVisiblity.customerPreferedCarrier=true;
    this.fieldVisiblity.costVsService=true;
    this.fieldVisiblity.comments=true;

    this.fieldVisiblity.materialDoc=true;
    this.fieldVisiblity.materialLink=true;
    this.fieldVisiblity.sdsLink=true;
    this.fieldVisiblity.sdsAttachment=true;
    this.fieldVisiblity.additionalAttachment=true;
    this.fieldVisiblity.additionalAttachment2=true;
    this.fieldVisiblity.hazardousSDSMandatory=true;
  }

    handlehazardousChange(event:any)
    {
      if(this.requestData.hazardous=="No")
      {
      
        this.requestData.unNumber="";
      }
    }
    
    override handleSeasonalChange(event:any)
    {
      if(this.requestData.seasonal=="No")
      {
        this.requestData.seasonalComments="";
       
      }
    }
 
  onAnnualVolumeChange(event: any){
    console.log('');
  }
  onArticleComplete(event:any){
    console.log("")
    
   }
  onArticleSelected(event:any){
  
    console.log("")

  }

  retrieveHeaderValues() {
    this.title = "Truckload";
    this.description = "A \"Rush Request\" defined as Procurement providing rates to BU after 2 business days otherwise Procurement will provide rates within 7 business days for standard requests.";
  }
  resetArticleData() {
    console.log("")
    
  }
   GenerarateArticleNumberEvent() {
    console.log("")
    
  }
  processArticleNumber(event: any) {
  
    console.log("")

  }
  public clearForm() {
    this.OnCancel();
    }
    
  override OnCancel() {
    this.clearData(3);
    this.requestData.equipmentTypeId=0;


    //Service provider
    this.requestData.days=0;
    this.requestData.dropTrail="";
    this.requestData.monthlyVolume=0;
    this.requestData.monthlyVolumeUnitId=0;
    this.requestData.hazardous="";
    this.requestData.tempControl="";
    this.requestData.unNumber="";
    this.requestData.seasonal="";
    this.requestData.seasonalComments="";
    this.requestData.tankerEndorsement="";
    this.clearData(3)
  }
  onSubmitRequest() {
    this.handlingDefaultDropdownValues();
    this.requestData.equipmentTypeId=this.requestData.equipmentTypeId??0;
    this.requestData.monthlyVolumeUnitId=this.requestData.monthlyVolumeUnitId??0;
    this.requestData.annualVolumeUnitId=this.requestData.annualVolumeUnitId??0;
    this.createRequest();
  }
  // override onCountryCheck(event:any){

  // }
  onSpecialRequirement(event:any){  }
  requestTypeChangeEvent(event:any){
    console.log('');
}
bindConditionalFields(){
  console.log("");

}
onPlantSelectPopulate(event:any,index:number){
  console.log('');

}

}
   
    
  