<div *ngIf="accessObj.appAccess" class="container mobile-responsive" style="padding: 0px">

<br>
<div class="col" >
  <div class="row">
    <div class="col-md-10">
      <p>{{'SELECT_TRANSPORTATION_MODE' | translate}}</p>
    </div>
    <div *ngIf="selectedLanguage=='Spanish'" class="col-md-2">
      <button class="infoIcon" (click)="op.toggle($event)">
        <img alt="" src="./assets/images/icons/info_icon.png"/>
      </button>
    </div>
  </div>    
</div>

<div>
  <p-overlayPanel #op>      
    <div style="width:18rem">
      <h5>Mexico Mode Equivalent</h5>
      <span class="row p-2">Camion <span>&#8594;</span> Trailer Complete (18-20 tons)</span>
      <span class="row p-2">Carga Parcial <span>&#8594;</span> Camionetas De 1 Ton a 14 Tons</span>
      <span class="row p-2">Paquete Intermodal <span>&#8594;</span> Transporte Intermodal</span>
      <span class="row p-2">Acarreo De Paquetes <span>&#8594;</span> Samples</span>
      <span class="row p-2">Camión De Graneles Líquidos <span>&#8594;</span> Pipas</span>
      <span class="row p-2">Camión De Graneles Secos <span>&#8594;</span> Tolvas</span>        
    </div>     
  </p-overlayPanel>
</div>
 
<div class="row rollout-container mb-5">
  
  <div class="col" *ngFor="let modes of arrmodes">
    <div className="row" style="height:85px ">
      <div className="col-md-5-cols">
        <div *ngIf="modes[0].ModeType =='Truck'"  class="col" style="display: grid;text-align: center;">
          <img src="./assets/images/van.png" class="img-responsive " style="max-width: 180px;max-height: 120px;margin: 0 auto;" alt="busimage"/>
        </div>
      </div>
      <div className="col-md-5-cols"><div *ngIf="modes[0].ModeType =='Bulk'"  class="col" style="display: grid;text-align: center;">
        <img src="./assets/images/truck1.png" class="img-responsive " style="max-width: 180px;max-height: 120px;margin: 0 auto;" alt="busimage"/>
      </div></div>
      <div className="col-md-5-cols"> <div *ngIf="modes[0].ModeType =='Rail'"  class="col" style="display: grid;text-align: center;">
        <img src="./assets/images/Rail.png" class="img-responsive " style="max-width: 180px;max-height: 120px;margin: 0 auto;" alt="carriage"/>          
      </div></div>
      <div className="col-md-5-cols"><div *ngIf="modes[0].ModeType =='Shipping'"  class="col" style="display: grid;text-align: center;">
        <img src="./assets/images/cargoship.png" class="img-responsive " style="max-width: 180px;max-height: 120px;margin: 0 auto;" alt="cargoship"/>                   
      </div>
      <div className="col-md-5-cols">
        <div *ngIf="modes[0].ModeType =='Airfreight'"  class="col" style="display: grid;text-align: center;">
          <img src="./assets/images/airfreight.png" class="img-responsive " style="max-width: 180px;max-height: 114px;margin: 0 auto;" alt="airimage"/>      
        </div>
      </div>
    </div>
  </div>
    <div class="row"  *ngFor="let mode of modes" style="display: contents !important;" >
      <div className="col-md-5-cols"style="display: grid;text-align: center;padding:15px 0px 15px 0px">
        <div>
        <a *ngIf="mode.IsVisible" style="margin-bottom: 5px;cursor: pointer; font-weight: 500; color:black" (click)="navigateRaterequest(mode.ModeId)">{{mode.ModeName | translate}}</a>          
     
      <a *ngIf="!mode.IsVisible"  style="margin-bottom: 5px" class="grey-text">{{mode.ModeName | translate}}</a>  
      <span  *ngIf="!mode.IsVisible"   class="err">*</span>
 
     </div> 
        </div>
      </div>

  </div>
  <div class="row" style="position:fixed;bottom:35px;">
    <p class="grey-text"><span class="err">*</span> {{'COMING_SOON' | translate}}</p>
  </div>
</div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" color="#fff" type="line-scale-pulse-out"></ngx-spinner>