import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { ArticelManagementServices } from "./article-management.service";
import { ArticleMappingFormData, ArticleRequest } from "./data/article-management-form.data";

@Injectable()

export  class ArticleManagementModel
{
    constructor(private client: ArticelManagementServices) { }

    retrieveArticleStatus() {
         return [
            { label: "Active", value: true },
            { label: "InActive", value: false }
        ];
    }

    validateArticleForm(data:ArticleMappingFormData,selectedMFG:any ):ArticleMappingFormData
    {
        data.validArticleNumber = this.validNumber(data.article_number) && this.validInput(data.article_number);
        data.validProductName = this.validInput(data.product_name)
        data.validMFG = data.mfg_codes.length> 0 && !data.mfg_codes.some((val, i) => data.mfg_codes.indexOf(val) !== i);
        return data;
    }

    
  validInput(validStr:any) {
    return validStr.length > 1;
  }
  
  validNumber(validStr:any) {
    let numRegex = /^[0-9,]*$/;
    return numRegex.test(validStr);
  }
    retrieveArticleGridColumn()
    {
        return [
            { field: "article_number", header: "Article#", width: "10%" },
            { field: "product_name", header: "Product Name", width: "30%" },
            { field: "mfg_code", header: "MFG Code", width: "7%" },
            { field: "mfg_description", header: "MFG Description", width: "38%" },
            { field: "status", header: "Status", width: "10%" },
            { field: "edit", header: "", width: "5%" }
          ];
    }
    

    lazyLoadArticlesData(articleRequest:ArticleRequest):Observable<ArticleMappingFormData[]>{
        let subject=new Subject<ArticleMappingFormData[]>();
        let resultCollection:ArticleMappingFormData[]=[];
        let payLoad={
            pageNo:(articleRequest.first/articleRequest.rowsPerPage)+1,
            rowsPerPage:articleRequest.rowsPerPage,
            f_article_number:articleRequest.filter?.article_number,
            f_product_name:articleRequest.filter?.product_name,
            f_mfg_code:articleRequest.filter?.mfg_code,
            f_mfg_description:articleRequest.filter?.mfg_description,
            f_status:articleRequest.filter?.status,
            requestedBy:articleRequest.requestedBy
        }
        console.log(payLoad,"payload in model")
        this.client.lazyLoadArticleData(payLoad).subscribe({
            next: (articleCollectionResponse: any) => {
                let apiResponse: any = articleCollectionResponse;
                let apiResult: any = apiResponse.result;
                if (apiResult.status_id == 200) {
                    let apiResultData: any = apiResponse.data;
                    apiResultData.forEach((element:any) => {
                        let resultdata = element as ArticleMappingFormData;
                        resultdata.edit = true;
                        resultdata.status = resultdata.is_active ? "Active" : "Inactive";
                        resultCollection.push(resultdata);
    
                    });
                    subject.next(resultCollection);
                }
                else {
                    subject.error(apiResult.message)
                }
              },
              error: (error: any) => {
                subject.error(error);
              },

        })
        
        return subject.asObservable();
    }

    onSave(data :ArticleMappingFormData , userId:number,clenaingCategoryData:any):Observable<string>
    {
        let subject=new Subject<string>();
        data.mfg_codes.forEach((mfgcode:any) => {
            let mfgObj = clenaingCategoryData.filter((a:any) => a.mfg_code == mfgcode)[0];
            data.mapped_mfg.push({mfg_code:mfgcode,mfg_description:mfgObj?mfgObj.mfg_description:'NA'});
        });
        let request = {
          "article_id": data.article_id,
          "article_number": data.article_number ,
          "product_name":data.product_name,
          "mfg_code":data.mfg_code,
          "mfg_description": data.mfg_description,
          "mapped_mfg": data.mapped_mfg,
          "is_active":data.is_active,
          "modified_by":userId
        }
        this.client.submitArticleMapping(request).subscribe(
            {
                next: (submitArticleMappingResponse: any) => {
                    this.processSaveResponse(submitArticleMappingResponse,subject);
                  },
                  error: (error: any) => {
                    subject.error(error);
                  },
            })

        return subject.asObservable();
    }

    
private processSaveResponse(updatevendorResponse: any, subject: Subject<string>) {
    let apiResponse: any = updatevendorResponse;
    let apiResult: any = apiResponse.result;
    if (apiResult.status_id == 200) {
        subject.next(apiResult.message);
    }
    else {
        subject.error(apiResult.message);
    }
}
}
