import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { RequestMasterData } from "./data/masterdata/request-master.data";

import { RequestMasterModel } from "./request-master.model";
import { RequestMasterService } from "./request-master.service";
import { LiquidDryRequestMasterData } from "./data/masterdata/liquid-dry-request-master.data";

@Injectable()
/** Handles the funcanality for Liquid and Dry truck Request master **/
export class LiquidTruckRequestMasterModel extends RequestMasterModel
    {

    constructor(service: RequestMasterService) {
        super(service);
        console.log("constructor")
    }


    responseOnSuccess(apiResponse: any, subject: Subject<RequestMasterData>) {
        let datacollection: LiquidDryRequestMasterData = new LiquidDryRequestMasterData();
        datacollection.equipementTypeMaster=this.convertResponsetoArray(apiResponse.data.equipementTypeMaster,false);
        datacollection.mandatoryDocumentsInsideTruck=this.convertResponsetoArray(apiResponse.data.mandatoryDocumentsInsideTruck,false);
        datacollection.averageProduct=this.convertResponsetoArray(apiResponse.data.averageProduct,false);
        datacollection.chassisMaster=this.convertResponsetoArray(apiResponse.data.chassisMaster,false);
        datacollection.loadingPointMaster=this.convertResponsetoArray(apiResponse.data.loadingPointMaster,false);
        datacollection.isoTankRentalRequirementMaster=this.convertResponsetoArray(apiResponse.data.isoTankRentalRequirementMaster,false);
        datacollection.tankTypeMaster=this.convertResponsetoArray(apiResponse.data.tankTypeMaster,false);
        datacollection.dangerousGoodsClassMaster=this.convertResponsetoArray(apiResponse.data.dangerousGoodsClassMaster,false);
        datacollection.totalWeightShipmentVolumeUnitMaster=this.convertResponsetoArray(apiResponse.data.totalWeightShipmentVolumeUnitMaster,false);
        datacollection.preferedTransportModeMaster=this.convertResponsetoArray(apiResponse.data.preferedTransportModeMaster,false);
        datacollection.specificEquipementTypeMaster=this.convertResponsetoArray(apiResponse.data.specificEquipmentTypeMaster,false);
        datacollection.specialCertificationMaster=this.convertResponsetoArray(apiResponse.data.specialCertificationMaster,false);
        datacollection.hoseLengthMaster=this.convertResponsetoArray(apiResponse.data.hoseLengthMaster,false);
        datacollection.driverLanguageMaster=this.convertResponsetoArray(apiResponse.data.driverLanguageMaster,false);
        datacollection.requiredCleaningProcedureMaster=this.convertResponsetoArray(apiResponse.data.requiredCleaningProcedureMaster,false);
        datacollection = this.processCommonResult(apiResponse, datacollection) as LiquidDryRequestMasterData;
        subject.next(datacollection);
    }

}