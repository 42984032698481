import { inject, Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthguardServiceService } from '../services/authguard-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard  {
  loginvalue: any;
  expireDate!: Date;
  authService: any;
  childactive = false;
  routparams: any;
  expectedUrl:any;
  constructor(private actroute: ActivatedRoute, private Authguardservice: AuthguardServiceService, public router: Router) {

  }
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    this.expectedUrl = route.data['expectedurl'];
    this.routparams = route.queryParams;

    if (!this.Authguardservice.gettoken()) {
      return this.onUserAuthenticated(state);      
    }
    else{  
   
      return this.onUserNotAutenticated();
    }
  }

  onUserAuthenticated(state: RouterStateSnapshot){

      if (this.routparams != null) {
        if(this.routparams.region != null)
        {
          sessionStorage.setItem('selectedRegion',this.routparams.region)
        }
        sessionStorage.setItem('returnUrlparams', JSON.stringify(this.routparams));
        if (JSON.stringify(this.routparams).includes('RequestId')) {
          sessionStorage.setItem('paramsExist', 'true');
        }
        sessionStorage.setItem('returnUrlpath', JSON.stringify(state.url));
        sessionStorage.setItem('requestPath', this.expectedUrl);
      }
      else {
        sessionStorage.setItem('paramsExist', 'false');
      }
      sessionStorage.setItem('returnUrl', this.expectedUrl);
      sessionStorage.setItem('requestPath', this.expectedUrl);
      
      this.router.navigate(['/login'], { queryParams: { returnUrl: this.expectedUrl } });
      return false;
  }

  onUserNotAutenticated(){
    this.Authguardservice.setIsLandingPage(true);
    this.loginvalue = JSON.parse(JSON.stringify(sessionStorage.getItem('loginJWT')));
    if (this.expectedUrl == 'admin') {
      if (JSON.parse(JSON.stringify(sessionStorage.getItem('adminAccess'))))
        return true;
      else {
        this.router.navigate(['/unauthorized']);
        return false;
      }
    }
    else {      
      return true;
    }
  }

 
}
export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(AuthenticationGuard).canActivate(next, state);
}
