<div *ngIf="userAccessPermission.site_access.adminAccess" class="container-fluid" id="adminContainer">
    <div class="">
        <div class="container mx-1 px-0" style="margin-top: 40px; margin-bottom:100px">
            <p-tabView [activeIndex]="activeTab" (onChange)="onTabChange($event)">
                <p-tabPanel style="font-weight: 600;" header="{{'ADMIN.USER_MANAGEMENT' | translate}}" [selected]="true">
                    <div *ngIf="userAccessPermission" id="adminUserMngt">
                        <app-admin-user-management *ngIf="activeTab==0" [loggedinUserinfo]="userAccessPermission">
                        </app-admin-user-management>
                    </div>
                </p-tabPanel>

                <p-tabPanel style="font-weight: 600;" header="Vendor Management">
                    <div *ngIf="userAccessPermission">
                        <app-admin-vendor-management *ngIf="activeTab==1" [loggedinUserinfo]="userAccessPermission">
                        </app-admin-vendor-management>
                    </div>
                </p-tabPanel>

                <p-tabPanel style="font-weight: 600;" header="Cleaning Category" *ngIf="region=='NA' ||  region=='EMEA'" >
                    <div *ngIf="userAccessPermission && (region=='NA' ||  region=='EMEA')">
                        <app-admin-cleaning-category-management *ngIf="activeTab==2" [loggedinUserinfo]="userAccessPermission">
                        </app-admin-cleaning-category-management>
                       
                    </div>
                </p-tabPanel>

                <p-tabPanel style="font-weight: 600;" header="Article Mapping"  *ngIf="userAccessPermission">
                    <div *ngIf="userAccessPermission && (region!='SA' && region!='AP')">
                        <app-admin-article-management *ngIf="activeTab==3" [loggedinUserinfo]="userAccessPermission">
                        </app-admin-article-management>
                    </div>
                    <div *ngIf="userAccessPermission && (region=='SA' || region=='AP')">
                        <app-admin-article-management *ngIf="activeTab==2" [loggedinUserinfo]="userAccessPermission">
                        </app-admin-article-management>
                    </div>
                </p-tabPanel> 

                <p-tabPanel style="font-weight: 600;" header="Site Competitiveness"  *ngIf="userAccessPermission && region=='NA'">
                    <div *ngIf="userAccessPermission && region=='NA' ">
                        <app-admin-site-competitiveness-management *ngIf="activeTab==4" [loggedinUserinfo]="userAccessPermission">
                        </app-admin-site-competitiveness-management>
                    </div>
                </p-tabPanel>
                 <p-tabPanel style="font-weight: 600;" header="RailCar Type"  *ngIf="userAccessPermission && region=='NA'">
                    <div *ngIf="userAccessPermission && region=='NA'">
                        <app-admin-railcar-type-management *ngIf="activeTab==5" [loggedinUserinfo]="userAccessPermission">
                        </app-admin-railcar-type-management>
                    </div>
                </p-tabPanel>
               <p-tabPanel style="font-weight: 600;" header="Temp Requirement" *ngIf="userAccessPermission && region=='NA'">
                    <div *ngIf="userAccessPermission && region=='NA'">
                        <app-admin-temp-requirement-management *ngIf="activeTab==6" [loggedinUserinfo]="userAccessPermission">
                        </app-admin-temp-requirement-management>
                    </div>
                </p-tabPanel> 
                <p-tabPanel style="font-weight: 600;" header="Delivery Type" *ngIf="userAccessPermission && region=='NA'">
                    <div *ngIf="userAccessPermission && region=='NA'">
                        <app-admin-delivery-type-management *ngIf="activeTab==7" [loggedinUserinfo]="userAccessPermission">
                        </app-admin-delivery-type-management>
                    </div>
                </p-tabPanel>

            </p-tabView>
        </div>
    </div>
</div>