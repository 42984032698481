import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { LoggecInUserInfo } from "src/app/site-permissions/data/loggedin-user-info";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { SiteCompetitivenessFormData } from "../data/site-competitiveness-form.data";
import { SiteCompetitivenessModel } from "../site-competitiveness.model";


@Component({
    selector: 'app-admin-site-competitiveness-management',
    templateUrl: './admin-site-competitiveness-component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [LoggedInUserInfoModel,SiteCompetitivenessModel]
  })

export class AdminSiteCompetitivenessManagementComponent implements OnInit
{

    @Input() loggedinUserinfo!: LoggecInUserInfo;

    siteCompetitivenessCollection:SiteCompetitivenessFormData[]=[];
    siteCompetitivenessData:SiteCompetitivenessFormData=new SiteCompetitivenessFormData();

    statusOpts: any;

    siteCompetitivenessColumn: any;
  
    constructor(private siteCompetitivenessModel: SiteCompetitivenessModel,
      private spinner: NgxSpinnerService,
      private toast: ToastrService) {
  
    }
    ngOnInit(): void {
    
        this.retrieveCleaningCategoryTableColumn();
        this.retrieveCleaningCategoryStatus();
        this.retrieveCleaningCategoryCollection();
    }
    

  retrieveCleaningCategoryTableColumn() {
    this.siteCompetitivenessColumn = this.siteCompetitivenessModel.retrieveColumnHeader();
    const screenWidth = window.innerWidth;
    if(screenWidth < 750) {
      this.siteCompetitivenessColumn.forEach((col:any) => {
          col.width = (parseInt(col.width.replace('%','')) * 10).toString() + 'px';
      });
    }
  }

  retrieveCleaningCategoryStatus()
  {
    this.statusOpts= this.siteCompetitivenessModel.retrieveSiteCompetitivenessStatus();
  }
  retrieveCleaningCategoryCollection()
  {
    this.siteCompetitivenessModel.retrieveSiteCompetitiveCollection(this.loggedinUserinfo.userId)
    .subscribe({
      next:(siteCompetitiveCollectionResponse)=>
      {this.siteCompetitivenessCollection=siteCompetitiveCollectionResponse;},
      error:(error)=>      
      {
        console.error(error);
      }});
  }

  updateSiteCompetitivenes() {
    this.spinner.show();
    let sitedata:any=this.siteCompetitivenessCollection.filter(sc=>sc.site_competitive_id!=this.siteCompetitivenessData.site_competitive_id);
    this.siteCompetitivenessData=this.siteCompetitivenessModel.validateSiteCompForm(this.siteCompetitivenessData);
    sitedata.forEach((sc:any)=>{    
      if(this.siteCompetitivenessData.site_competitive_name==sc.site_competitive_name){
        this.siteCompetitivenessData.validateCompname=false;
        this.toast.warning("There is already a Site Competetive with this name.")          
      }
    })
    if (!this.siteCompetitivenessData.validateCompname) {
      this.spinner.hide();
      return false;
    } 
    else{
       this.siteCompetitivenessModel.saveData(this.siteCompetitivenessData,this.loggedinUserinfo.userId).subscribe({
        next:(saveResponse:any)=>{
        this.toast.success("Success");
        this.retrieveCleaningCategoryCollection();
        this.onCancel();
        this.spinner.hide();
       },
        error:(error:any)=>{
          this.toast.error(error,"Error!");
        }
    });
        return true
      }
  }
  onRowSiteCompEditInit(rowData:any){
    this.siteCompetitivenessData.editing = true;
    this.siteCompetitivenessData.edit = true;
    this.siteCompetitivenessData.site_competitive_id = rowData.site_competitive_id;
    this.siteCompetitivenessData.site_competitive_name=rowData.site_competitive_name;
    this.siteCompetitivenessData.is_active = rowData.is_active;
  }
  onCancel()
  {

    this.siteCompetitivenessData=new SiteCompetitivenessFormData();
  }
} 
