import { ISODryageTruckFormData } from "./iso-dryage-truck-form.data";

export class ISoDryageTruckRequestDetails extends ISODryageTruckFormData {

    annualVolumeUnit: string="";
    monthlyVolumeUnit: string="";
    reasonForSelection: string="";
    rateReqCalData:any="";
    rateReqCalFileName:string="";
    rateReqCalFileExtn:string="";
    
    requestType: string="";
    tempRequirement: string="";
    transportTypeName: string="";
    deliveryTypeName:string="";

}