import { PackageDrayageVendorResponseData } from "./package-drayage-vendor-response.data";

export class PackageDrayageVendorResponseFormData extends PackageDrayageVendorResponseData
{
  
    disable:boolean=false;
    validVendor:boolean=true;
    validBidAmount:boolean=true;
    validComments:boolean=true;
    validRank:boolean=true;
    validCurrency:boolean=true;
    validDueDate:boolean=true;
}