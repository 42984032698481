<div id="siteCompeDiv" class="container">
  <form autocomplete="off"> 
    <div class="row">              
      <div class="form-group col-12 col-xl-6">
        <label for="siteComp">Site Competitiveness</label>
        <input type="text" class="form-control input-class" id="sitecomp" name="sitecomp" [(ngModel)]="siteCompetitivenessData.site_competitive_name" >
        <span *ngIf="!siteCompetitivenessData.validateCompname" class="err">Invalid  Site Competitiveness</span>
      </div>
      <div class="form-group col-12 col-xl-6" style="display: grid">
        <label for="status">Status</label>
        <p-dropdown name="articleStatus" styleClass="cust-dropdown" [options]="statusOpts" [(ngModel)]="siteCompetitivenessData.is_active"
          placeholder="Select Status"></p-dropdown>
      </div>
    </div>
    <div class="row mt-4">
      <div class="form-group col-6 col-md-6" style="margin-bottom: 10px;">
        <p style="text-align: right">
          <button type="button" class="btn btn-secondary cust-btns" (click)="onCancel()">Clear</button>
        </p>
      </div>
      <div class="form-group col-6 col-md-6" style="margin-bottom: 10px;">
        <p style="text-align: left">
          <input *ngIf="!siteCompetitivenessData.editing" type="button" class="btn btn-primary cust-btns" style="background-color: #004A96 !important;" (click)="updateSiteCompetitivenes()" value="Create">
          <input *ngIf="siteCompetitivenessData.editing" type="button" class="btn btn-primary cust-btns" style="background-color: #004A96 !important;" (click)="updateSiteCompetitivenes()" value="Update">
        </p>
      </div>
    </div>
  </form>
</div>
<div class="container" id="userTable" style="margin-top: 20px; margin-bottom: 50px;padding-bottom:20px">
  <p-table #dt5 [columns]="siteCompetitivenessColumn" [value]="siteCompetitivenessCollection" [paginator]="true" [rows]="10" 
  [pageLinks]="2" [selectionPageOnly]="true" [responsive]="false" [scrollable]="true" [resizableColumns]="true" columnResizeMode="expand"   [tableStyle]="{ 'min-width': '40rem' }">
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
      </colgroup>
  </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th id="col.header" *ngFor="let col of columns">
          {{col.header}}
        </th>
      </tr>    
      <tr>
        <th id="thfilter" *ngFor="let col of columns" [ngSwitch]="col.field">
          <input *ngSwitchCase="'site_competitive_id'" pInputText type="text"
            (input)="dt5.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}">
          <input *ngSwitchCase="'site_competitive_name'" pInputText type="text"
            (input)="dt5.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}">                  
            <input *ngSwitchCase="'status'" pInputText type="text"
            (input)="dt5.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}">
        </th>
      </tr>          
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td *ngFor="let col of columns">
          <span *ngIf="col.field == 'status'" class="status-text" [ngClass]="{'status-text-inactive':  rowData[col.field]=='Inactive'}">
            {{rowData[col.field]}}
          </span>
          <span *ngIf="col.field != 'edit' && col.field != 'status'">{{rowData[col.field]}}</span>
           <span *ngIf="col.field == 'edit'">
            <img *ngIf="!editing" class="accordion-img-icon pointer-cursor" title='Edit Site Competitiveness' alt="editIcon"
              (click)="onRowSiteCompEditInit(rowData)" src="./assets/images/icons/edit_icon.png">
            <img *ngIf="editing && rowData['userId'] === id" class="accordion-img-icon pointer-cursor" alt="cancelIcon"
              (click)="onCancel()" title='Cancel Editing' src="./assets/images/icons/close_icon.png">
          </span> 
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
