import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn:"root"
})
export  class CleaningCategoryService
{
    constructor(private client: HttpClient) { }

    
  retrieveCleaningCategoryCollection(requestedBy:number) {
      let link=environment.getCleaningCategories.replace("{requestedby}", requestedBy.toString())
    return this.client.get(link).pipe(map((response) => response));
  }

  createCleaningCategory(data: any) {
    return this.client.post(environment.addCleaningCategory, data).pipe(map((response) => response));
  }

  updateCleaningCategory(data: any) {
    return this.client.post(environment.updateCleaningCategory, data).pipe(map((response) => response));
  }

}