import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { LoginQaComponent } from './login-qa/login-qa.component';
import { HomeComponent } from './home/home.component';
import { RequestListComponent } from './requests/request-list/request-list.component';
import { TruckLoadFormComponent } from './requests/request-forms/truck-load-request-form.component';
import { TruckLoadRequestDetailsComponent } from './requests/request-details/truck-load-details-form.component';
import { RailRateRequestFormComponent } from './requests/request-forms/rail-rate-request-form.component';
import { RailRateRequestDetailsComponent } from './requests/request-details/rail-rate-details-form.components';
import { AdminComponent } from './admin/component/admin.component';
import { ISODryageTruckComponent } from './requests/request-forms/iso-dryage-truck-form.component';
import { ISODryageTruckRequestDetailsComponent } from './requests/request-details/iso_dryage_truck-details-form.component';
import { PackageContainerBoxComponent } from './requests/request-forms/package-container-box-drayage-component';
import { PackagaContainerBoxDetailsRequestComponent } from './requests/request-details/package-container-box-drayage-request-detail-form.component';
import { LiquidRequestFormCommonComponent } from './requests/request-forms/liquid-request-form-common/liquid-request-form-common.component';
import { LiquidRequestDetailFormComponent } from './requests/request-details/liquid-request-detail/liquid-request-detail-form-common/liquid-request-detail-form.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { DryRequestFormCommonComponent } from './requests/request-forms/dry-request-form-common/dry-request-form-common.component';
import { DryRequestDetailFormCommonComponent } from './requests/request-details/dry-request-details/dry-request-detail-form-common/dry-request-detail-form-common.component';
import { AuthenticationGuard } from './_helpers/authentication.guard';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';

const routes: Routes = [
  { path: 'unauthorized', component: UnauthorizedComponent },
  {
    path:'', component: LoginComponent, pathMatch: 'full'
  },
  {
    path:'login', component: LoginComponent
  },
  {
    path:'login-qa', component: LoginQaComponent
  },
  {
    path:'landingpage', component: LandingpageComponent,data: { expectedurl: 'landingpage' }, canActivate: [AuthenticationGuard]
  },  
  
  {
    path: 'home', component: HomeComponent,data: { expectedurl: 'home' }, canActivate: [AuthenticationGuard]
  },
  {
    path: 'home/liquid-request-form', component: LiquidRequestFormCommonComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'liquid-request-form' } 
  },
  {
    path: 'request-list/liquid-request-form/edit', component: LiquidRequestFormCommonComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'liquid-request-form' } 
  },
  {
    path: 'liquid-request-form', component: LiquidRequestFormCommonComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'liquid-request-form' } 
  },
  {
    path: 'dry-request-form', component: DryRequestFormCommonComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'dry-request-form' } 
  },
  {
    path: 'home/dry-request-form', component: DryRequestFormCommonComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'dry-request-form' } 
  },
  {
    path: 'request-list/dry-request-form/edit', component: DryRequestFormCommonComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'dry-request-form' } 
  },
  {
    path: 'truck-load-request-form', component: TruckLoadFormComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'truck-load-request-form' } 
  },
  {
    path: 'home/truck-load-request-form', component: TruckLoadFormComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'truck-load-request-form' } 
  },
  {
    path: 'request-list/truck-load-request-form/edit', component: TruckLoadFormComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'truck-load-request-form' } 
  },
  {
    path: 'rail-rate-request-form', component: RailRateRequestFormComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'rail-rate-request-form' } 
  },
  {
    path: 'home/rail-rate-request-form', component: RailRateRequestFormComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'rail-rate-request-form' } 
  },
  {
    path: 'request-list/rail-rate-request-form/edit', component: RailRateRequestFormComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'rail-rate-request-form' } 
  },
  {
    path: 'iso-dryage-truck-request-form', component: ISODryageTruckComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'iso-dryage-truck-request-form' } 
  },
  {
    path: 'home/iso-dryage-truck-request-form', component: ISODryageTruckComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'iso-dryage-truck-request-form' } 
  },
  {
    path: 'request-list/iso-dryage-truck-request-form/edit', component: ISODryageTruckComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'iso-dryage-truck-request-form' } 
  },
  {
    path: 'iso-dryage-truck-request-details', component: ISODryageTruckRequestDetailsComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'iso-dryage-truck-request-details' } 
  },
  {
    path: 'request-list/iso-dryage-truck-request-details', component: ISODryageTruckRequestDetailsComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'iso-dryage-truck-request-details' } 
  },
  {
    path: 'app-package-container-box-drayage-request-form', component: PackageContainerBoxComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'app-package-container-box-drayage-request-form' } 
  },
  {
    path: 'home/app-package-container-box-drayage-request-form', component: PackageContainerBoxComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'app-package-container-box-drayage-request-form' } 
  },
  {
    path: 'request-list/app-package-container-box-drayage-request-form/edit', component: PackageContainerBoxComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'app-package-container-box-drayage-request-form' } 
  },
  {
    path: 'package-container-box-drayage-request-details', component: PackagaContainerBoxDetailsRequestComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'package-container-box-drayage-request-details' } 
  },
  {
    path: 'request-list/package-container-box-drayage-request-details', component: PackagaContainerBoxDetailsRequestComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'package-container-box-drayage-request-details' } 
  },
  {
    path: 'request-list', component: RequestListComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'request-list' } 
  },
  {
    path: 'liquid-request-details', component: LiquidRequestDetailFormComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'liquid-request-details' } 
  },
  {
    path: 'request-list/liquid-request-details', component: LiquidRequestDetailFormComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'liquid-request-details' } 
  },
  {
    path: 'dry-request-details', component: DryRequestDetailFormCommonComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'dry-request-details' } 
  },
  {
    path: 'request-list/dry-request-details', component: DryRequestDetailFormCommonComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'dry-request-details' } 
  },
  {
    path: 'truck-load-request-details', component: TruckLoadRequestDetailsComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'truck-load-request-details' } 
  },
  {
    path: 'request-list/truck-load-request-details', component: TruckLoadRequestDetailsComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'truck-load-request-details' } 
  },
  {
    path: 'rail-rate-request-details', component: RailRateRequestDetailsComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'rail-rate-request-details' } 
  },
  {
    path: 'request-list/rail-rate-request-details', component: RailRateRequestDetailsComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'rail-rate-request-details' } 
  },
  {
    path: 'admin', component: AdminComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'admin' } 
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
