import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { LiquidRequestDetails } from "../../../data/formdata/liquid-request-details.data";
import { LiquidTruckRequestFormModel } from "../../../liquid-request-form.model";
import { RequestFormModel } from "../../../request-form.model";
import { RequestDetailsComponent } from "../.././request-details-form.component";
import { Subscription } from "rxjs";
import { AppServiceService } from "src/app/services/app-service.service";

@Component({
    selector: 'app-liquid-request-ap-details',
    templateUrl: '..\\..\\..\\shared-templates\\request-details-form-template.html',
    styleUrls: ['..\\..\\..\\shared-templates\\request-details-form-template.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [LoggedInUserInfoModel,
        { provide: RequestFormModel, useClass: LiquidTruckRequestFormModel }
    ]
})
export class LiquidRequestDetailsAPComponent extends RequestDetailsComponent implements OnInit,OnDestroy {

  private subscriptionName: Subscription; //important to create a subscription
  messageReceived: any;
  pageLanguage:string="";
  override requestData:any; 
    constructor(userAccessModel: LoggedInUserInfoModel,  requestModel: RequestFormModel,
        toast: ToastrService, router: Router, spinner: NgxSpinnerService, route: ActivatedRoute,private _appServ: AppServiceService) {
        super(userAccessModel,requestModel, toast, router, spinner,route);
        this.subscriptionName= this._appServ.getUpdate().subscribe(message => { //message contains the data sent from service
        this.messageReceived = message;        
        let newLang=sessionStorage.getItem('selectedLanguage');
        if(this.pageLanguage!=newLang && this.pageLanguage!=""){
          this.ngOnInit();
        }
        });
    }

    ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
      this.subscriptionName.unsubscribe();
  }

    ngOnInit(): void {
      this.pageLanguage=sessionStorage['selectedLanguage'];
        this.requestData = new LiquidRequestDetails();
        this.transportType=1
        this.validateloggedInUser();
        this.readQueryString();
        this.retrieveHeaderValues();
        this.bindfiledVisiblity();
        
    }
    downLoadSpecialReqAttachment(){console.log('')};
    downLoadFISPQFile(){console.log('')}
    bindfiledVisiblity()
    {
      this.fieldVisiblity.requestType=true;
      this.fieldVisiblity.startDate=true;
      this.fieldVisiblity.requestor=true;
      this.fieldVisiblity.bussinessunit=true;
      this.fieldVisiblity.articleNumber=true;
      this.fieldVisiblity.articleNumberMandatory = true;
      this.fieldVisiblity.product=true;
      this.fieldVisiblity.mfg=false;
      this.fieldVisiblity.freightClass=false;
      this.fieldVisiblity.cleaninCost=false;
      this.fieldVisiblity.interModel=true;
      this.fieldVisiblity.equipmentType= true;
  
      // Service Provider
      this.fieldVisiblity.volumeType =true;
      this.fieldVisiblity.campaignSeasonalVolume =true;
      this.fieldVisiblity.csVolumeTiming = true;
      this.fieldVisiblity.monthlyVolume=false;
      this.fieldVisiblity.centerRearUnload=false;
      this.fieldVisiblity.dropTrail=false;
      this.fieldVisiblity.days=false;
      this.fieldVisiblity.tempRange=true;
      this.fieldVisiblity.tempRequirement=true;
      this.fieldVisiblity.priorProductRestriction=true;
      this.fieldVisiblity.hazardous=true;
      this.fieldVisiblity.kosher=false;
      this.fieldVisiblity.materialTopLoad=false;
      this.fieldVisiblity.foodGradeWash=false;
      this.fieldVisiblity.loadingHrs=true;
      this.fieldVisiblity.unloadingHrs=true;
      this.fieldVisiblity.nitrogenBlanket=true;
      this.fieldVisiblity.weekendDelivery=false;
      this.fieldVisiblity.groundVapourRecovery=false;
      this.fieldVisiblity.centerRear=false;
      this.fieldVisiblity.isoTankRentalRequirement = true;
      this.fieldVisiblity.isoTankRentalPeriod = true;
      this.fieldVisiblity.tankType = true;
      this.fieldVisiblity.specificRemarkForTankType =true;
      this.fieldVisiblity.topBottomLoad = true;
      this.fieldVisiblity.topBottomUnload = true;
      this.fieldVisiblity.cleaningService = true;
      this.fieldVisiblity.cleaningFrequency =true;
      this.fieldVisiblity.heatingService =true;
      this.fieldVisiblity.heatingTemperature = true;
      this.fieldVisiblity.priorProductRestrictionDetails = true;
      this.fieldVisiblity.tariffZone = true;
      this.fieldVisiblity.dangerousGoodsClass = true;
      this.fieldVisiblity.otherAdditionalServiceRequirement = true;
  
      //Shipping->Orgin
      this.fieldVisiblity.originPlant = true;
      this.fieldVisiblity.shippingName=true;
      this.fieldVisiblity.orginCity=true;
      this.fieldVisiblity.orginState=true;
      this.fieldVisiblity.orginCountry=true;
      this.fieldVisiblity.orginZipCode=true;
      //shipping-> destination
      this.fieldVisiblity.destinationPlant = true;
      this.fieldVisiblity.consigneeName=true;
      this.fieldVisiblity.destinationCity=true;
      this.fieldVisiblity.destinationState=true;
      this.fieldVisiblity.destinationCountry=true;
      this.fieldVisiblity.destinationZipCode=true;
  
      //Additional Info
      this.fieldVisiblity.customerPreferedCarrier=false;
      this.fieldVisiblity.costVsService=false;
      this.fieldVisiblity.comments=true;
  
      this.fieldVisiblity.materialDoc=true;
      this.fieldVisiblity.materialLink=true;
      this.fieldVisiblity.sdsLink=true;
      this.fieldVisiblity.sdsAttachment=true;
      this.fieldVisiblity.additionalAttachment=true;
      this.fieldVisiblity.additionalAttachment2=true;
      if(this.isVendorUser){
        this.fieldVisiblity.requestor=false;
        this.fieldVisiblity.customerPreferedCarrier=false;
        this.fieldVisiblity.costVsService=false;
      }
      
    }
    
    retrieveHeaderValues() {
        this.title = "Liquid Truck";
      }

    bindConditionalFields(){
        console.log("")
    }
      
}