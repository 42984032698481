import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DryRequestDetails } from "src/app/requests/data/formdata/dry-request-details.data";
import { DryTruckRequestFormModel } from "src/app/requests/dry-request-form.model";
import { RequestFormModel } from "src/app/requests/request-form.model";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { RequestDetailsComponent } from "../../request-details-form.component";
import { Subscription } from "rxjs";
import { AppServiceService } from "src/app/services/app-service.service";
import { isNullOrUndefined } from "src/app/shared/utils/nullorundefined";



@Component({
    selector: 'app-dry-request-na-details',
    templateUrl: '..\\..\\..\\shared-templates\\request-details-form-template.html',
    styleUrls: ['..\\..\\..\\shared-templates\\request-details-form-template.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [LoggedInUserInfoModel,
        { provide: RequestFormModel, useClass: DryTruckRequestFormModel }
    ]
})

export class DryRequestDetailsNAComponent extends RequestDetailsComponent implements OnInit ,OnDestroy
{

 private subscriptionName: Subscription; //important to create a subscription
 messageReceived: any;
 pageLanguage:string="";
 override requestData:any;
    constructor(userAccessModel: LoggedInUserInfoModel,  requestModel: RequestFormModel,
        toast: ToastrService, router: Router, spinner: NgxSpinnerService, route: ActivatedRoute,private _appServ: AppServiceService) {
        super(userAccessModel,requestModel, toast, router, spinner,route);
        this.subscriptionName= this._appServ.getUpdate().subscribe(message => { //message contains the data sent from service
        this.messageReceived = message;
        
        let newLang=sessionStorage.getItem('selectedLanguage');
        if(this.pageLanguage!=newLang && this.pageLanguage!=""){
          this.ngOnInit();
        }
        });
      }
      ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
        this.subscriptionName.unsubscribe();
    }
    
    downLoadFISPQFile(){console.log('')}

    ngOnInit(): void {
      this.pageLanguage=isNullOrUndefined(sessionStorage.getItem('selectedLanguage'))?'English':sessionStorage['selectedLanguage'];
        this.requestData = new DryRequestDetails();
        this.transportType=2;
        this.validateloggedInUser();
        this.readQueryString();
        this.retrieveHeaderValues();
        this.bindfiledVisiblity();
        
    }
    
    bindfiledVisiblity()
    {
      this.fieldVisiblity.requestType=true;
      this.fieldVisiblity.naCountry=true;
      this.fieldVisiblity.startDate=true;
      this.fieldVisiblity.requestor=true;
      this.fieldVisiblity.bussinessunit=true;
      this.fieldVisiblity.articleNumber=true;
      this.fieldVisiblity.product=true;
      this.fieldVisiblity.mfg=true;
      this.fieldVisiblity.freightClass=true;
      this.fieldVisiblity.interModel=false;
  
      // Service Provider
      this.fieldVisiblity.volumeType=true;
      this.fieldVisiblity.nitrogenBlanket=true;
      this.fieldVisiblity.priorProductRestriction=true;
      this.fieldVisiblity.hazardous=true;
      this.fieldVisiblity.vacuumTrailer=true;
      
      this.fieldVisiblity.cleaningProcedures=false;
      this.fieldVisiblity.mandDocsInsideTruck=false;
      this.fieldVisiblity.averageProduct=false;    

      this.fieldVisiblity.monthlyVolume=false;
      this.fieldVisiblity.materialTopLoad=false;
      this.fieldVisiblity.loadingHrs=false;
      this.fieldVisiblity.unloadingHrs=false;

      this.fieldVisiblity.weekendDeliveryYN=false;    
      
  
      //Shipping->Orgin
      this.fieldVisiblity.originPlant=true;
      this.fieldVisiblity.shippingName=true;
      this.fieldVisiblity.orginCity=true;
      this.fieldVisiblity.orginState=true;
      this.fieldVisiblity.orginCountry=true;
      this.fieldVisiblity.orginZipCode=true;
      //shipping-> destination
      this.fieldVisiblity.destinationPlant=true;
      this.fieldVisiblity.consigneeName=true;
      this.fieldVisiblity.destinationCity=true;
      this.fieldVisiblity.destinationState=true;
      this.fieldVisiblity.destinationCountry=true;
      this.fieldVisiblity.destinationZipCode=true;
  
      //Additional Info
      this.fieldVisiblity.customerPreferedCarrier=false;
      this.fieldVisiblity.costVsService=false;
      this.fieldVisiblity.comments=true;
  
      this.fieldVisiblity.materialDoc=true;
      this.fieldVisiblity.materialLink=true;
      this.fieldVisiblity.sdsLink=true;
      this.fieldVisiblity.sdsAttachment=true;
      this.fieldVisiblity.additionalAttachment=true;
      this.fieldVisiblity.additionalAttachment2=true;
      

      if(this.isVendorUser){
        this.fieldVisiblity.requestor=false;
        this.fieldVisiblity.customerPreferedCarrier=false;
        this.fieldVisiblity.costVsService=false;
      }
      
    }
    retrieveHeaderValues() {
        this.title = "Dry Truck";
      }

    bindConditionalFields(){
        let DryReqData=this.requestData as DryRequestDetails;
        if(DryReqData.naCountry=="Mexico"){
          this.fieldVisiblity.cleaninCost=false;
          this.fieldVisiblity.dropTrail=false;
          this.fieldVisiblity.equipmentType=true;
          this.fieldVisiblity.typeOfShipment=true;
          this.fieldVisiblity.monthlyKms=DryReqData.typeOfShipment=="Dedicated";
          this.fieldVisiblity.totalWeightShipment=true;
          this.fieldVisiblity.isSpecialRequirement=true;
          this.fieldVisiblity.specailRequirementAttachment=DryReqData.isSpecialRequirement=="Yes";
          this.fieldVisiblity.multipleDestination=true;
        }
        else{
          this.fieldVisiblity.cleaninCost=true;
          this.fieldVisiblity.dropTrail=true;
        }
    }

    downLoadSpecialReqAttachment(){
        this.fileDownloaded = false;
        this.openpdfEdge((this.requestData as DryRequestDetails).specialRequirementAttachment, (this.requestData as DryRequestDetails).specialRequirementFileName, (this.requestData as DryRequestDetails).specialRequirementFileExtension);
        this.fileDownloaded = true;
    }
}


