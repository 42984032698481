import { RequestFormData } from "./request-common.data";

export class RailRateRequestFormData extends RequestFormData
{
    articleNumber: string="";
    railCartTypeId:number=0;
    mfg: string="";
    payerOption:string="";

    //Service Provider
    numberOfRailCarsRequired:number=0;
    centerRear: string="";
    hazardous: string="";

    //Payer Options
    freightBillAddress: string="";
    payer: string="";
    routing:string="";
    tariffNumber: string="";

    //Shipping ->Orgin
    orginStreetAddress:string="";
    orginServingRailRoad:string="";
    orginAlternateServingRailRoad:string="";
    orginSiteCompetitivenessId :number=0;
    orginRailStation:string="";

    
    //Shipping->Destination
    destinationStreetAddress:string="";
    destinationServingRailRoad:string="";
    destinationAlternateServingRailRoad:string="";
    destinationSiteCompetitivenessId :number=0;
    destinationRailStation:string="";

    
}