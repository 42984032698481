import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { RequestFormData } from "./data/formdata/request-common.data";
import { RequestCommonListData } from "./data/request-list/common-request-list.data";
import { RequestFormService } from "./request-form.services";

@Injectable()
export abstract class RequestFormModel
{
 constructor (private services:RequestFormService)
 {
    console.log("")
 }
 abstract createRequest(data:RequestFormData):Observable<string>;
abstract responseOnSuccessRequestList(apiResponse: any, subject: Subject<RequestCommonListData[]>):any;
abstract OnRequestDetailSuccess(apiResponse: any, resolve: (value: RequestFormData | PromiseLike<RequestFormData>) => void):any;


retrieveNumberOfCars(transportType:number, annualVolume: number, annualVolumeUOM: number): Observable<number> 
    {
        let subject = new Subject<number>();
        this.services.retrieveNumberOfCars(transportType,annualVolume,annualVolumeUOM).subscribe(
            {next:result => {
            
            let apiResponse: any = result;
            let responseResult: any = apiResponse.results;
            if (responseResult.status_id === 200) {
                subject.next(Number(apiResponse.data)) 
            }
            else
            {
                subject.next(Number(0)) ;
            }
            
           
        } ,
        error:err=>{
            subject.error(err) ;
        }
        
    }
        );
        return subject.asObservable();
    }
retrieveRequetcollection(transportType:number,isExternalUser:boolean,userId:number):Observable<RequestCommonListData[]>{
    let subject = new Subject<RequestCommonListData[]>();
    this.services.retrieveRequestList(transportType,isExternalUser,userId).subscribe(response => {
        let apiResponse: any = response;
        let responseResult: any = apiResponse.results;
        if (responseResult.status_id === 200) {
            this.responseOnSuccessRequestList(apiResponse, subject);
        }
        else {
            subject.error(responseResult.message);
        }
        
    });
    return subject.asObservable();
}

    exportRequestList(transportType:number):Observable<string>
    {
        
        let subject = new Subject<string>();
        this.services.exportRequestList(transportType).subscribe({
            next:result => {
            const a = document.createElement('a');
            document.body.appendChild(a);
            const blob: any = new Blob([result], { type: 'octet/stream' });
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = "Request.xlsx";
            a.click();
            window.URL.revokeObjectURL(url);
        } ,
        error:err=>{
            subject.error(err) ;
        }
    }
        
        );
       return subject.asObservable();
    }

retrieveRequestDetails(transportType:number,requestId:number):Promise<RequestFormData>{
   
    return new Promise<RequestFormData>((resolve, reject)=> {
    this.services.retrieveRequestDetails(transportType,requestId).subscribe(response=>
        {
            let apiResponse: any = response;
            let responseResult: any = apiResponse.results;
            if (responseResult.status_id === 200) {
                this.OnRequestDetailSuccess(apiResponse, resolve);
            }
            else {
                reject(responseResult.message);
            }
            
        });
    
});

}
    
 protected insertRequest(data:any):Observable<string>
 {
    let subject=new Subject<string>();
     this.services.createRequest(data).subscribe(response=>
        {
            let apiResponse:any= response;
            let responseResult:any=apiResponse.results;
            if(responseResult.status_id===200)
            {
            
                subject.next("");
            }
            else{
            subject.error(responseResult.message);
            }
        });
        return subject.asObservable();
 }
}