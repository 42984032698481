import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

declare global{
  interface Navigator {
    msSaveOrOpenBlob?: (blob: any, defaultName?: string) => boolean
  }
}
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
