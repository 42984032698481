import { VendorResponseData } from "./vendor-response.data";

export class VendorResponseFormData extends VendorResponseData
{
  
    disable:boolean=false;
    validVendor:boolean=true;
    validBidAmount:boolean=true;
    validComments:boolean=true;
    validRank:boolean=true;
    validCurrency:boolean=true;
    validDueDate:boolean=true;
}