import { Injectable } from "@angular/core";
import {  Observable,  Subject } from "rxjs";
import { ProductData } from "./Data/product.data";
import { ProductService } from "./product.service";

@Injectable()

export class ProductModel
{

    constructor(private productService:ProductService){}

    retrieveProductForArticle(articalNumber:string):Observable<ProductData[]>
    {
        let subject=new Subject<ProductData[]>();
        this.productService.retrieveProductInfoBasedonArticleNumber(articalNumber).subscribe(response=>
            {
                let apiResponse:any;
                apiResponse=response;
                let apiResponseStatus=apiResponse.results;
                if(apiResponseStatus.status_id===200)
                {
                    let data=this.mapProductData(apiResponse.ProductInfo);
                    subject.next(data);
                }
                else
                {
                    subject.error("INVALID_ARTICLE_NUMBER");
                }

            })
        
    
        
        return subject.asObservable();
        
    }


    private mapProductData(response:any):ProductData[]
    {
        let productData:ProductData[]=[];
        response.forEach((element:any) => { 
        let data=new ProductData();

        data.articleNumber=element.article_number;
        data.friegeClass=element.freightClass;
        data.mfg=element.mfg_description;
        data.mfgCode=element.mfg_code;
        data.product=element.product_name;
        data.cleaningCategoryCode=element.cleaningCategoryCode;
        data.newRate=element.newRate;
        data.isactive=element.is_active;
        productData.push(data);
        });
        return productData;
    }
}
