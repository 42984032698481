import { DropDownMaster } from "src/app/shared/dropdown-master.data";
import { RequestMasterData } from "./request-master.data";

export class LiquidDryRequestMasterData extends RequestMasterData
{
    equipementTypeMaster:DropDownMaster[]=[];
    averageProduct:DropDownMaster[]=[];
    mandatoryDocumentsInsideTruck:DropDownMaster[]=[];
    chassisMaster:DropDownMaster[]=[];
    loadingPointMaster:DropDownMaster[]=[];
    specificEquipementTypeMaster:DropDownMaster[]=[];
    isoTankRentalRequirementMaster: DropDownMaster[]=[];
    tankTypeMaster: DropDownMaster[]=[];
    dangerousGoodsClassMaster: DropDownMaster[]=[];
    totalWeightShipmentVolumeUnitMaster : DropDownMaster[]=[];
    preferedTransportModeMaster: DropDownMaster[]=[];
    specialCertificationMaster: DropDownMaster[]=[];
    hoseLengthMaster: DropDownMaster[]=[];
    driverLanguageMaster: DropDownMaster[]=[];
    requiredCleaningProcedureMaster: DropDownMaster[]=[];
   
}