import { DropDownMaster } from "src/app/shared/dropdown-master.data";


export class RequestMasterData {
    articleOpts: DropDownMaster[] = [];
    mfgOpts: DropDownMaster[] = [];
    countryMaster: DropDownMaster[] = [];
    requestTypeMaster: DropDownMaster[] = [];
    tempRequirementMaster: DropDownMaster[] = [];
    unitMaster: DropDownMaster[] = [];

}
export class PlantCodeMasterData{
    originPlantCodeMaster:DropDownMaster[]=[];
    destinationPlantCodeMaster:DropDownMaster[]=[];
    plantShippingData:PlantCodeShippingData[]=[];
}
export class PlantCodeShippingData{
    masterId:number=0
    masterValue:string='';
    masterName:string='';
    masterOrderBy:number=0;
    city:string='';
    state:string='';
    zip:string='';
    country:string='';
    isOriginPlantCode:boolean=false;
}
