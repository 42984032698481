import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppServiceService } from 'src/app/services/app-service.service';

@Component({
  selector: 'app-vendor-form-common',
  templateUrl: './vendor-form-common.component.html',
  styleUrls: ['./vendor-form-common.component.css']
})
export class VendorFormCommonComponent implements OnInit,OnDestroy {
  @Input() requestId!: number;
  @Input() country!: string;
  @Input() transportType!: number;
  @Input() statusId!: number;
  @Input() requestDestination!:string;
  @Output() refresh=new EventEmitter();
  
  private vendorFormSubscriptionName: Subscription; //important to create a subscription
  messageReceived: any;
  region!: string;
  constructor(private _appServ: AppServiceService) { 
    this.vendorFormSubscriptionName= this._appServ.getUpdate().subscribe(message => { //message contains the data sent from service
      this.messageReceived = message;
      this.region=sessionStorage['selectedRegion'];    
      });
  }

  ngOnInit() {
    console.log("")
  }

  ngOnDestroy(): void {
    this.vendorFormSubscriptionName.unsubscribe();
  }

  reload(event:any){
    this.refresh.emit('update');
  }

}
