<div id="articleDiv" class="container">
    <form autocomplete="off">
      <div class="row">
        <div class="form-group col-12 col-xl-6">
          <label for="articleNum">Article Number</label>
          <input [disabled]="articleMappingData.editing" type="text" class="form-control input-class" id="articleNum" name="articleNum" [(ngModel)]="articleMappingData.article_number">
          <span *ngIf="!articleMappingData.validArticleNumber" class="err">Invalid Article Number</span>
        </div>
        <div class="form-group col-12 col-xl-6">
          <label for="productName">Product Name</label>
          <input [disabled]="amEditing" type="text" class="form-control input-class" id="productName" name="productName" [(ngModel)]="articleMappingData.product_name" >
          <span *ngIf="!articleMappingData.validProductName" class="err">Invalid Product Name</span>
        </div>
        <div class="form-group col-12 col-xl-6">
          <div class="row m-0">
            <div class="col-10 col-xl-11 pl-0">
              <label for="MFG">Add MFG</label>
              <p-dropdown name="mfgOpts" styleClass="cust-dropdown" [options]="mfgOpts"
               [filter]="true"  [(ngModel)]="selectedMFG" appendTo="body"
                placeholder="Add MFG"></p-dropdown>
            </div>
            <div class="col-2 col-xl-1">
              <button type="button" pTooltip="Click to Add MFG" tooltipPosition="top" [disabled]="selectedMFG==''" style="margin-top: 2.5rem;" class="btn btn-success" (click)="onAddMfg()">+</button>
            </div>
          </div>         
        </div>
        <div class="form-group col-12 col-xl-6">
          <label for="MFG">Mapped MFG</label>
          <p-multiSelect name="selectedmfgList" styleClass="cust-dropdown" [options]="mfgList" [(ngModel)]="articleMappingData.mfg_codes" defaultLabel="Select MFG"></p-multiSelect>

          <span *ngIf="!articleMappingData.validMFG" class="err">Please select atleast one MFG</span>
        </div>        
      </div>
      <div class="row mt-4">
        <div class="form-group col-6 col-md-6" style="margin-bottom: 10px;">
          <p style="text-align: right">
            <button type="button" class="btn btn-secondary cust-btns" (click)="onCancel()">Clear</button>
          </p>
        </div>
        <div class="form-group col-6 col-md-6" style="margin-bottom: 10px;">
          <p style="text-align: left">
            <input *ngIf="!articleMappingData.editing" type="button" class="btn btn-primary cust-btns" style="background-color: #004A96 !important;" (click)="updateArticleMapping()" value="Create">
            <input *ngIf="articleMappingData.editing" type="button" class="btn btn-primary cust-btns" style="background-color: #004A96 !important;" (click)="updateArticleMapping()" value="Update">
          </p>
        </div>
      </div>
    </form>
  </div>
  <div class="container" id="userTable" style="margin-top: 20px; margin-bottom: 50px">
    <p-table #dt4 [columns]="articleTableColumns" rowGroupMode="rowspan"
     groupRowsBy="article_number" [value]="articleMappingCollection" [lazy]="true" [totalRecords]="articleRequest.totalRecords"
     (onLazyLoad)="loadArticleMappingData($event)" [loading]="loading" 
      [paginator]="true" [rows]="articleRequest.rowsPerPage" [responsive]="false" [scrollable]="true" [resizableColumns]="true"
       columnResizeMode="expand"   [tableStyle]="{ 'min-width': '70rem','min-height':'40.53px' }">
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
        </colgroup>
    </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th id="col.header" *ngFor="let col of columns">
            {{col.header}}
          </th>
        </tr>
        <tr>
          <th id="thfilter" *ngFor="let col of columns" [ngSwitch]="col.field">
            <input *ngSwitchCase="'article_number'" pInputText type="text" [(ngModel)]="articleRequest.filter.article_number"
               [ngStyle]="{'width': '100%'}"
              (keydown.enter)='filterArticleData()'>
            <input *ngSwitchCase="'product_name'" pInputText type="text" [(ngModel)]="articleRequest.filter.product_name"
              (keydown.enter)="filterArticleData()" [ngStyle]="{'width': '100%'}">
            <input *ngSwitchCase="'mfg_code'" pInputText type="text" [(ngModel)]="articleRequest.filter.mfg_code"
              (keydown.enter)="filterArticleData()" [ngStyle]="{'width': '100%'}">
            <input *ngSwitchCase="'mfg_description'" pInputText type="text" [(ngModel)]="articleRequest.filter.mfg_description"
              (keydown.enter)="filterArticleData()" [ngStyle]="{'width': '100%'}">
              <input *ngSwitchCase="'status'" pInputText type="text" [(ngModel)]="articleRequest.filter.status"
              (keydown.enter)="filterArticleData()" [ngStyle]="{'width': '100%'}">
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowgroup="rowgroup" let-rowspan="rowspan">
        <tr>
          <td *ngFor="let col of columns">
            <span *ngIf="col.field == 'status'" class="status-text" [ngClass]="{'status-text-inactive':  rowData[col.field]=='Inactive'}">
              {{rowData[col.field]}}
            </span>
            <span *ngIf="col.field == 'article_number' && rowgroup" [attr.rowspan]="rowspan">
              {{rowData[col.field]}}
            </span>
            <span *ngIf="col.field != 'edit' && col.field != 'status' && col.field != 'article_number'">{{rowData[col.field]}}</span>
            <span *ngIf="col.field == 'edit'">
              <img *ngIf="!editing" class="accordion-img-icon pointer-cursor" title='Edit User Role' alt="editIcon"
                (click)="onRowAMEditInit(rowData)" src="./assets/images/icons/edit_icon.png">
              <img *ngIf="editing && rowData['userId'] === id" class="accordion-img-icon pointer-cursor" alt="cancelIcon"
                (click)="onCancel()" title='Cancel Editing' src="./assets/images/icons/close_icon.png">
            </span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td>
           <ngx-spinner [fullScreen]="false" bdColor="rgba(51, 51, 51, 0.8)" size="default" color="#fff" type="line-scale-pulse-out"></ngx-spinner>

          </td>
        </tr>
      </ng-template>
    </p-table>
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" color="#fff" type="line-scale-pulse-out"></ngx-spinner>

  </div>
