import { DropDownMaster } from "src/app/shared/dropdown-master.data";

export class UserManagementData {
    user_id!: number;
    user_name!: string;
    email!: string;
    first_name!: string;
    last_name!: string;
    created_date!: Date;
    modified_date!: Date;
    is_admin!: boolean;
    is_active!: boolean;
    department_id!: string;
    role_id!: number;
    role_name!: string;
    modified_by!:number;
    
    region_master: DropDownMaster[] = [];

    region:string="";
}