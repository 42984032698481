import { LiquidBulkVendorResponseData } from "./liquid-bulk-vendor-response.data";

export class LiquidVendorResponseFormData extends LiquidBulkVendorResponseData
{
  
    disable:boolean=false;
    validVendor:boolean=true;
    validBidAmount:boolean=true;
    validComments:boolean=true;
    validRank:boolean=true;
    validRank2:boolean=true;
    validCurrency:boolean=true;
    validDueDate:boolean=true;
}