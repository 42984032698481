import { VendorUserDropdownMaster } from "src/app/shared/vendor-dropdown-master.data";

export class VendorResponseData
{
    responseId:number=0;
    vendorId:number=0;
    vendorName:string="";
    vendorUser:VendorUserDropdownMaster[]=[];
    selectedVendorUser:VendorUserDropdownMaster[]=[]
    bidAmount: number=0;
    comments:string="";
    isSelected:boolean=false;
    respondeDate!:Date;
    attachment:any=null;
    rankAssigned:any=0;
    currencyType:number=0;
    dueDate:string='';
    isNotified:boolean=false;
    notifiedDate!:string;

    vendorPdfAttachment:any="";
    vendorPdfFileName:string="";
    vendorPdfFileExtn:string="";

    vendorBids:any[]=[];   
    bidStatus:string="";
     
}
