import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn:"root"
})
export  class SiteCompetitivenessService
{
    constructor(private client: HttpClient) { }

 retrieveSiteCompetitveList(requestedBy: number){
     let link=environment.getSiteComptitiveList.replace("{requestedby}", requestedBy.toString())
        return this.client.get(link).pipe(map((response)=>response));
    } 

    submitSiteCompetitve(data: any) {
        return this.client.post(environment.submitSiteComptivelist, data).pipe(map((response) => response));
      }
}