import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { DropDownMaster, DropDownRegionMaster } from "src/app/shared/dropdown-master.data";
import { UserMasterDataServices } from "./user-masterdata.service";
import { AppServiceService } from "src/app/services/app-service.service";

@Injectable()

export  class UserManagementMasterModel
{

    constructor(private client: UserMasterDataServices,private applicaionservice:AppServiceService) { }

    retrieveUserRoles(requestedBy :number):Observable<DropDownMaster[]>
    {
        let subject =new Subject<DropDownMaster[]>();
        let result:DropDownMaster[] =[];
        this.client.retrieveRoles(requestedBy).subscribe({
            next:userRoleResult=>
            {
                let apiResponse:any=userRoleResult;
                let apiResult:any=apiResponse.result;
                if(apiResult.status_id==200)
                {
                    let apiResultData:any=apiResponse.data;
                    apiResultData.forEach((element:any) => {
                        let resultdata=new DropDownMaster();
                         resultdata.value=Number(element.role_id);
                       
                         resultdata.label=element.role_name;
                         result.push(resultdata);
                 
                      });
                      subject.next(result);
                }
                else
                {
                    subject.error(apiResult.message)
                }
            },
            error:error=>
            {
                subject.error(error)
            }
    });
                return subject.asObservable();

    }
    retrieveUserStatus()
    {
        return [
            { label: "Active", value: true },
            { label: "InActive", value: false }
          ];
    }


    public RetrieveRegionMaster(requestedBy :number,isadmin:boolean,type:string):Observable<DropDownMaster[]>
    {
        let subject=new Subject<DropDownMaster[]>();
        this.applicaionservice.getRegionsData(requestedBy,isadmin,type).subscribe(response=>
            {
                let apiResponse:any =response;
                let apiResponseResult:any=apiResponse.result;
                if(apiResponseResult.status_id===200)
                {
                    let  resultdata:any=apiResponse.data;
                    let datacollection=this.convertResponsetoArray(resultdata);
                    subject.next(datacollection);
                }
                
            });

            return subject.asObservable();
    }

    //Convert  Response to DropDownMaster Array
 private  convertResponsetoArray(response:any):DropDownRegionMaster[]
 {
    let result:DropDownRegionMaster[]=[];
    response.forEach((element:any) => {
       let resultdata=new DropDownRegionMaster();
        resultdata.value=Number(element.masterId);
      
        resultdata.label=element.masterValue;
        resultdata.name=element.masterName;
        result.push(resultdata);

     });
     return result;
 }
}
