import { VendorResponseFormData } from "./vendor-response-form.data"

export class VendorApiSubmit{
    vendorsData!: VendorResponseFormData[];
    requestId!: number;
    referenceNumber!:string;
    selectedReason!: string;
    isconfirmed! :boolean;
    requestStatus!:number;
    requestorMail!:string;  
    transportType!:number;
    modifiedBy!:number
}