<div id="footer" class="container-fluid footerStyle pe-0">
  <div class="container me-0">
      <div class="row">
        <a class="navbar-brand imglogo" (click)="homeclick()" >
            <div id="copyright"  class="img-logo logotext d-flex align-items-center text-wrap pe-3">
                Copyrights 2024 BASF Corporation
            </div> 
        
            <img src="./assets/images/BASFo_wh100tr.png" alt="brand" class="img-responsive img-logo ps-2" />
        </a>
          <div id="copyright" class="col-md-12 copyright" style="margin-top:15px; float: right">
              Copyrights 2024 BASF Corporation
          </div>
      </div>
  </div>
</div>