import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DropDownMaster } from "src/app/shared/dropdown-master.data";
import { TransportTypeMasterModel } from "src/app/shared/transport-type-master/transport-type-master.model";
import { LoggecInUserInfo } from "src/app/site-permissions/data/loggedin-user-info";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { TempRequirementFormData } from "../data/temp-requirement-form.data";
import { TempRequirementModel } from "../temp-requirement.model";



@Component({
    selector: 'app-admin-temp-requirement-management',
    templateUrl: './temp-requirement.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [LoggedInUserInfoModel,TempRequirementModel,
      TransportTypeMasterModel
    ]
  })

export class AdminTempRequirementComponent implements OnInit
{

    @Input() loggedinUserinfo!: LoggecInUserInfo;

    tempRequirementFormData: TempRequirementFormData= new TempRequirementFormData();
    tempRequirementFormDatacollection:TempRequirementFormData[]=[];
    transportTypeMasterData:DropDownMaster[]=[];
    selectedTransportType:any;
    
  statusOpts:any;

    TempRequirementTableColumns:any;
    constructor(private tempReqModel:TempRequirementModel,
      private transportTypeMasterModel:TransportTypeMasterModel,
      private spinner: NgxSpinnerService,
    private toast: ToastrService )
    {

    }
    ngOnInit(): void {
    
      this.retrieveTransportType();
      this.retrieveListHeader();
      this.retrieveVendorCollection();
this.retrieveVendorStatus();

    }
retrieveVendorStatus()
  {
   this. statusOpts=this.tempReqModel.retrieveTempRequirementStatus();
  }
 retrieveListHeader()
 {
  this.TempRequirementTableColumns=this.tempReqModel.retrieveListHeader();
  const screenWidth = window.innerWidth;
  if(screenWidth < 750) {
    this.TempRequirementTableColumns.forEach((col:any) => {
        col.width = (parseInt(col.width.replace('%','')) * 10).toString() + 'px';
    });
  }
 }

 retrieveVendorCollection ()
 {
   this.tempReqModel.retrieveTempRequirementCollection(this.loggedinUserinfo.userId).subscribe(
   {
    next: (tempRequirementFormDataCollection:any)=>{
    this.tempRequirementFormDatacollection=tempRequirementFormDataCollection;
   },
   error:(error)=>
   {
     console.error(error);
   }
 })
 }
    retrieveTransportType()
    {
      this.transportTypeMasterModel.RetrieveTransportTypeMaster().subscribe({
        next:(transportTypeResponse:any)=>
        {
         this. transportTypeMasterData=transportTypeResponse;
        },
        error:(error)=>
        {
          console.error(error);
        }
    })

    }


    onRowVendorEditInit(rowData:any) {
      this.tempRequirementFormData.editing = true;
      this.tempRequirementFormData.temp_requirement_id = rowData.temp_requirement_id;
      this.tempRequirementFormData.temp_requirement_name = rowData.temp_requirement_name;
      this.tempRequirementFormData.is_active = rowData.is_active;
      let data:any = rowData.transport_type_master;
      this.selectedTransportType=[];
      data.forEach((element:any)=>
        {
          let resultdata=new DropDownMaster();
          resultdata.value=Number(element.masterId);
        
          resultdata.label=element.masterValue;
          this.selectedTransportType.push(resultdata.value);
        })       
    }

    onCancel() {

      this.tempRequirementFormData= new TempRequirementFormData();
      this.selectedTransportType=null;
      this.selectedTransportType=[];
    }

    saveTempRequirement() {
      this.spinner.show();      
      this.tempRequirementFormData=    this.tempReqModel.validateTempRequirementForm(this.tempRequirementFormData,this.selectedTransportType)
    if(!(this.tempRequirementFormData.validateTempRequirement && this.tempRequirementFormData.validTransportType))
    {
        this.spinner.hide();
        return false;
      }
  else{
      this.tempReqModel.OnsaveVendor(this.tempRequirementFormData,this.selectedTransportType,this.loggedinUserinfo.userId).subscribe({
        next:(saveResponse:any)=>
        {
          this.toast.success(saveResponse, "Success");
          this.retrieveVendorCollection();
          this.onCancel();
          this.spinner.hide();
        },
       error: error=>
        {
          this.toast.error(error, "Error!");
          this.spinner.hide();
        }
  })
  return true
    }
  }
} 
