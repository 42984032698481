import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DryRequestDetails } from "src/app/requests/data/formdata/dry-request-details.data";
import { DryTruckRequestFormModel } from "src/app/requests/dry-request-form.model";
import { RequestFormModel } from "src/app/requests/request-form.model";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { RequestDetailsComponent } from "../../request-details-form.component";
import { Subscription } from "rxjs";
import { AppServiceService } from "src/app/services/app-service.service";


@Component({
    selector: 'app-dry-request-emea-details',
    templateUrl: '..\\..\\..\\shared-templates\\request-details-form-template.html',
    styleUrls: ['..\\..\\..\\shared-templates\\request-details-form-template.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [LoggedInUserInfoModel,
        { provide: RequestFormModel, useClass: DryTruckRequestFormModel }
    ]
})

export class DryRequestDetailsEMEAComponent extends RequestDetailsComponent implements OnInit,OnDestroy
{

 private subscriptionName: Subscription; //important to create a subscription
 messageReceived: any;
 override requestData:any;
    constructor(userAccessModel: LoggedInUserInfoModel,  requestModel: RequestFormModel,
        toast: ToastrService, router: Router, spinner: NgxSpinnerService, route: ActivatedRoute,private _appServ: AppServiceService) {
        super(userAccessModel,requestModel, toast, router, spinner,route);
        this.subscriptionName= this._appServ.getUpdate().subscribe(message => { //message contains the data sent from service
        this.messageReceived = message;
        
          this.ngOnInit();
        });
    }

    ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
       this.subscriptionName.unsubscribe();
    }
    
    ngOnInit(): void {
        this.requestData = new DryRequestDetails();
        this.transportType=2;
        this.validateloggedInUser();
        this.readQueryString();
        this.retrieveHeaderValues();
        this.bindfiledVisiblity();
        
    }
    downLoadSpecialReqAttachment(){console.log('')};
    downLoadFISPQFile(){console.log('')};
    bindfiledVisiblity()
    {
      this.fieldVisiblity.requestType = true;
      this.fieldVisiblity.startDate = false;
      this.fieldVisiblity.requestor = true;
      this.fieldVisiblity.bussinessunit = true;
      this.fieldVisiblity.articleNumber = true;
      this.fieldVisiblity.articleNumberMandatory = true;
      this.fieldVisiblity.product = true;
      this.fieldVisiblity.mfg = true;
      this.fieldVisiblity.mfgMandatory = true;
      this.fieldVisiblity.freightClass = false;
      this.fieldVisiblity.cleaninCost = false;
      this.fieldVisiblity.interModel = false;
      this.fieldVisiblity.equipmentType = false;
     
      this.fieldVisiblity.preferedTransportMode=true;
      this.fieldVisiblity.heatingDuringTransport=true;
      this.fieldVisiblity.groundOperatingEquipment=true;
      this.fieldVisiblity.specialCertification=true;
      this.fieldVisiblity.hoseLength=true;
      this.fieldVisiblity.driverLanguage=true;
      this.fieldVisiblity.requiredCleaningProcedure=true;
      this.fieldVisiblity.vacuumTestConfirmation=true;
      this.fieldVisiblity.reactorLoading=true;
      this.fieldVisiblity.twoStepBusiness=true;
      this.fieldVisiblity.onlyOnecompartments=true;
      // Service Provider
      
      this.fieldVisiblity.volumeType=true;
      this.fieldVisiblity.campaignSeasonalVolume=true;
      this.fieldVisiblity.csVolumeTiming = true;
      this.fieldVisiblity.nitrogenBlanket=false;
      this.fieldVisiblity.centerRearUnload = false;
      this.fieldVisiblity.centerRearUnloadMandatory = false;
      
      this.fieldVisiblity.priorProductRestriction = false;
      this.fieldVisiblity.kosher = false;
      this.fieldVisiblity.foodGradeWash = false;
      this.fieldVisiblity.unloadingHrs = false;
      this.fieldVisiblity.weekendDeliveryYN=false
      this.fieldVisiblity.dropTrail = false;
      this.fieldVisiblity.hazardous=false;
      this.fieldVisiblity.hazardousMandatory = false;
      this.fieldVisiblity.materialTopLoad = false;
      this.fieldVisiblity.loadingHrs = true;
      
      this.fieldVisiblity.specificEquipmentType=true;
      this.fieldVisiblity.pump = true;
      this.fieldVisiblity.chassis = true;
      this.fieldVisiblity.couplings = true;
      this.fieldVisiblity.loadingPoint =false;
      this.fieldVisiblity.gmp = false;
      this.fieldVisiblity.fami = false;
      this.fieldVisiblity.isopa = false;
      this.fieldVisiblity.halalWash = false;
      this.fieldVisiblity.unloadingHose = false;
      this.fieldVisiblity.onboardCompressor = false;
      this.fieldVisiblity.others = true;
    
    
      //Shipping->Orgin
      this.fieldVisiblity.originPlant=true;
      this.fieldVisiblity.shippingName=true;
      this.fieldVisiblity.orginCity=false;
      this.fieldVisiblity.orginCityEMEA=true
      this.fieldVisiblity.orginState=false;
      this.fieldVisiblity.orginCountry=false;
      this.fieldVisiblity.orginCountryEMEA=true;
      this.fieldVisiblity.orginZipCode=false;
      this.fieldVisiblity.originZipCodeMandatory=true;

      //shipping-> destination
      this.fieldVisiblity.multipleDestination=true;
      this.fieldVisiblity.destinationPlant=false;
      this.fieldVisiblity.consigneeName=true;
      this.fieldVisiblity.destinationCity=false;
      this.fieldVisiblity.destinationCityEMEA=true
      this.fieldVisiblity.destinationState=false;
      this.fieldVisiblity.destinationCountry=false;
      this.fieldVisiblity.destinationCountryEMEA=true;
      this.fieldVisiblity.destinationZipCode=false;
      this.fieldVisiblity.distinationZipCodeMandatory=true;
     
      //Additional Info

      this.fieldVisiblity.comments=true;
  
      this.fieldVisiblity.materialDoc=true;
      this.fieldVisiblity.materialLink=true;
      this.fieldVisiblity.sdsLink=true;
      this.fieldVisiblity.sdsAttachment=true;
      this.fieldVisiblity.additionalAttachment=true;
      this.fieldVisiblity.additionalAttachment2=true;
      this.fieldVisiblity.customerPreferedCarrier=false;
      this.fieldVisiblity.costVsService=false;

      if(this.isVendorUser){
        this.fieldVisiblity.requestor=false;
        this.fieldVisiblity.customerPreferedCarrier=false;
        this.fieldVisiblity.costVsService=false;
      }
      
    }
    retrieveHeaderValues() {
        this.title = "Dry Truck";
      }
    
    bindConditionalFields(){
       console.log("") 
    }
}
