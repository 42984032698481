import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { RailRateRequestFormData } from "./data/formdata/rail-rate-form.data";
import { RailRateRequestDetails } from "./data/formdata/rail-rate-request-details.data";
import { RequestFormData } from "./data/formdata/request-common.data";
import { RailRateRequestListData } from "./data/request-list/rail-rate-request-list.data";
import { RequestFormModel } from "./request-form.model";
import { RequestFormService } from "./request-form.services";
import { RequestListModel } from "./request-list/request-list.model";
import { TranslateService } from "@ngx-translate/core";
import { RequestCommonListData } from "./data/request-list/common-request-list.data";

@Injectable()

export class RailRequestFormModel extends RequestFormModel implements RequestListModel {
    translate:TranslateService;
    constructor(service: RequestFormService,_translate:TranslateService) {
        super(service);
       this. _service=service;
       this.translate=_translate;

    }
    _service:RequestFormService;
    retrieveListHeader():any {
        let  requestDetailCols:any = [
            { field: "referenceNumber", header: "ID", width: "5vw" },
            { field: "payer", header: "PAYER", width: "6vw" },
            { field: "requestType", header: "REQUEST_TYPE", width: "8vw" },
            { field: "requestor", header: "REQUESTOR", width: "7vw" },
            { field: "businessUnit", header: "BU", width: "4vw" },
            { field: "articleNumber", header: "ARTICLE_NUMBER", width: "5vw" },
            { field: "product", header: "PRODUCT", width: "8vw" },
            { field: "orgin", header: "ORIGIN", width: "8vw" },
            { field: "destination", header: "DESTINATION", width: "8vw" },
            { field: "status", header: "STATUS", width: "8vw" },
            { field: "requestedDate", header: "DATE", width: "6vw"},
            { field: "numberOfCars", header: "NUMBER_OF_CARS", width: "8vw"}
          ];
          return requestDetailCols;
    }

   

    createRequest(data: RailRateRequestFormData): Observable<string> {
        let subject = new Subject<string>();
        this.insertRequest(data).subscribe({next:result => { subject.next(result) },
            error:error => { subject.error(error) }
    }
        );
        return subject.asObservable();
    }

     OnRequestDetailSuccess(apiResponse: any, resolve: (value: RequestFormData | PromiseLike<RequestFormData>) => void) {
        let data :RailRateRequestDetails= apiResponse.data as RailRateRequestDetails;
        // data.updatedPayerOptiob=data.payerOption.toLowerCase()=="yes"?"Basf":"3rd Party"
        data.updatedPayerOptiob=data.payerOption.toLowerCase()=="yes"?this.translate.instant("BASF"):this.translate.instant("THIRD_PARTY")
        
        resolve(data);
    }

    responseOnSuccessRequestList(apiResponse: any, subject: Subject<RequestCommonListData[]>) {
        let requestCollection: RailRateRequestListData[] = [];
      
        apiResponse.data.forEach((element:any) => {
          
            requestCollection.push(element as RailRateRequestListData);
        });

        subject.next(requestCollection);
    }

    


}