import { TempRequirementData } from "./temp-requirement.data";

export class TempRequirementFormData extends TempRequirementData
{
    edit:boolean=true;
    editing:boolean=false;
    status:string="";

    validateTempRequirement:boolean=true;
    validTransportType:boolean=true;

    
}