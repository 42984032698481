import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserManagementMasterModel } from '../admin/user-management/user-master-data.model';
import { AppServiceService } from '../services/app-service.service';
import { DropDownMaster } from '../shared/dropdown-master.data';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.css'],
  providers: [ UserManagementMasterModel]
})
export class LandingpageComponent implements OnInit {
  regionsDD:DropDownMaster[]=[];
  href! : string;
  selectedRegion:any;
  modesbasedonRegion: any[]=[];
  modes!: any[];
  arrmodes: any =[];
  languagesDD:DropDownMaster[]=[];
  selectedLanguage:any;
  
  
  constructor(private _appServ: AppServiceService,private translate:TranslateService, private router: Router,private userMasterModel: UserManagementMasterModel,private spinners: NgxSpinnerService) { }

  ngOnInit() {

this.spinners.show();
setTimeout(() => {
  /** spinner ends after 5 seconds */
  this.spinners.hide();
}, 1000);
    this.getuserregions();
   
  }
  sendMessage(message:any): void {
    this._appServ.sendUpdate(message,this.languagesDD,this.regionsDD);
}

  loadHomePage(mode:any){
    sessionStorage.setItem('selectedRegion',mode.label);
    sessionStorage.setItem('regionId',mode.value);
    this.sendMessage(true);
     if(sessionStorage['isVendorUser']=='true'){
        this.router.navigate(['/request-list'], { queryParams: { val: 'all' }})
      }
      else{
        this.router.navigate(['/home']);
      }
    this.translate.use('english');
    sessionStorage.setItem('selectedLanguage','English');             
  }
  getuserregions() {
    this.spinners.show();
    console.log('call from landing')
    this.userMasterModel.RetrieveRegionMaster(Number(sessionStorage['userId']),sessionStorage['isVendorUser']=='true','E').subscribe({
      next:(transportTypeResponse)=>
      {
       this.regionsDD=transportTypeResponse;
    if(!Number.isNaN(Number(sessionStorage['regionId']))){
  this.selectedRegion= this.regionsDD.filter(i=>i.value == Number(sessionStorage['regionId']) )
  this.getuserLanguages()
}
this.spinners.hide();
      },
      error:error=>
      {
        this.spinners.hide();
        console.error(error);
      }
      })
  }
  getuserLanguages() {

    this._appServ.getLanguagesbasedonRegion(Number(sessionStorage['userId']),Number(sessionStorage['regionId'])).subscribe(response=>
      {
          let apiResponse:any =response;
          let apiResponseResult:any=apiResponse.result;
          if(apiResponseResult.status_id===200)
          {
              this.languagesDD=apiResponse.data;
              if(this.languagesDD.length>0)
                  this.selectedLanguage =this.languagesDD[0]           
          }
          
      });
    
  }
  logout(){
    this.href = window.location.href;
    let dev = this.href.indexOf("dev").toString();
    let qa = this.href.indexOf("qa").toString();
    if(dev != "-1"){
      this.logoutQuality(); 
   }    
    else if(qa != "-1"){
      this.logoutQuality(); 
  }
  else{  
      window.sessionStorage.clear();  
      window.location.href = "https://federation.basf.com/nidp/saml2/v2/logout";
    }
  }
  homeclick(){
    this.router.navigate(['/landingpage'])
  }
  
  
  private logoutQuality() {
    window.sessionStorage.clear();
    window.location.href = "https://federation-qa.basf.com/nidp/saml2/v2/logout";
  }
}
