import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { RequestVendorResponseForm } from "./data/request-vendor-response-form.data";
import { VendorResponseFormData } from "./data/vendor-response-form.data";
import { VendorResponseData } from "./data/vendor-response.data";
import { VendorResponseServices } from "./vendor-response.services";
import { VendorApiSubmit } from "./data/vendor-api-submit.data";

@Injectable()


export abstract class VendorResponseModel {

    constructor(private service: VendorResponseServices) {
    }
    abstract submitBidByVendor(vendorApiSubmitData:VendorApiSubmit): Observable<string>;
    abstract notifyVendorResponse(vendorApiSubmitData:VendorApiSubmit): Observable<string>;
    abstract submitvendorResponse(vendorApiSubmitData:VendorApiSubmit,rateReqCalData:string,rateReqCalFileName:string,rateReqCalFileExtn:string): Observable<string>;
    abstract onretrieveVendorResponseSuccess(apiResponse: any, subject: Subject<VendorResponseData[]>):any;
    abstract validateRank(ranks:any,vendorsData:any):any;

    retrieveVendorResponse(requestId: number, transportType: number,isExternalUser:boolean,userId:number): Observable<VendorResponseData[]> {
        let subject = new Subject<VendorResponseData[]>();
        this.service.retrieveVendorResponse(requestId, transportType,isExternalUser,userId).subscribe(response => {


            let apiResponse: any = response;
            let responseResult: any = apiResponse.results;
            if (responseResult.status_id === 200) {
                this.onretrieveVendorResponseSuccess(apiResponse, subject);
            }

        });
        return subject.asObservable();
    }


    
    GenerateBitlink(requestId: number): string {
        let url = "";
        if ((window.location.href.indexOf('?requestId')).toString() != "-1") {
            url = window.location.href;
        } else {
            url = window.location.href + `?requestId=${requestId}`
        }

        return url;

    }



    validateVendor(vendorsData: any) {
        vendorsData.forEach((v:any) => {
            v.validVendor = (v.vendorId != null && v.vendorId != undefined && v.vendorId != 0);

        });
        return vendorsData;
    }

    deleteEmptyVendorResponse(vendorsData: VendorResponseFormData[]) {
        let finalVendors = vendorsData.filter((v:any) => v.vendorId != 0);
        finalVendors.forEach((f:any) => {
            if (f.responseId == 0) {
               
                delete f.responseId;
            }
            delete f.disable;
        });
        return finalVendors;
    }
    generateIsfinalStatus(requestStatus: number): boolean {
        if (requestStatus == 7) {
            return true;
        }
        else
            return false;

    }
    

    protected onBindRequestVendorResponse(apiRequest: RequestVendorResponseForm,vendorApiSubmitData:VendorApiSubmit,
        rateReqCalData:string,rateReqCalFileName:string,rateReqCalFileExtn:string) {
        let finalVendors = this.deleteEmptyVendorResponse(vendorApiSubmitData.vendorsData);


        apiRequest.requestId = vendorApiSubmitData.requestId;
        apiRequest.reasonForSelection = vendorApiSubmitData.selectedReason;
        apiRequest.rateReqCalData=rateReqCalData;
        apiRequest.rateReqCalFileName=rateReqCalFileName;
        apiRequest.rateReqCalFileExtn=rateReqCalFileExtn;
        apiRequest.referenceNumber = vendorApiSubmitData.referenceNumber;
        apiRequest.isFinal = vendorApiSubmitData.isconfirmed;
        apiRequest.isFinalstatus = vendorApiSubmitData.isconfirmed ? this.generateIsfinalStatus(vendorApiSubmitData.requestStatus) : false;
        apiRequest.viewBidUrl = vendorApiSubmitData.isconfirmed ? this.GenerateBitlink(vendorApiSubmitData.requestId) : " ";
        apiRequest.vendorResponse = finalVendors;
        apiRequest.requesterMailId = vendorApiSubmitData.requestorMail;
        apiRequest.transportType = vendorApiSubmitData.transportType;
        apiRequest.modifiedBy = vendorApiSubmitData.modifiedBy;
        apiRequest.region = Number(sessionStorage.getItem('regionId'));
        apiRequest.isExternalUser= sessionStorage.getItem('isVendorUser')=='true'
        return apiRequest;
    }

    protected onSubmitVendorResponse(apiRequest: RequestVendorResponseForm) {
        let subject = new Subject<string>();
        this.service.submitVerdorResponse(apiRequest).subscribe(response => {
            let apiResp: any = response;
            if (apiResp.results.status_id == "200") {
                subject.next(apiResp.results.message);
            }
            else {
                subject.error(apiResp.results.message);

            }
        });
        return subject.asObservable();
    }

    protected onNotifyVendorResponse(apiRequest: RequestVendorResponseForm) {
        let subject = new Subject<string>();
        this.service.notifyVendorResponse(apiRequest).subscribe(response => {
            let apiResp: any = response;
            if (apiResp.results.status_id == "200") {
                subject.next(apiResp.results.message);
            }
            else {
                subject.error(apiResp.results.message);

            }
        });
        return subject.asObservable();
    }

    protected onSubmitBidByVendor(apiRequest: RequestVendorResponseForm) {
        let subject = new Subject<string>();
        this.service.submitBidByVendor(apiRequest).subscribe(response => {
            let apiResp: any = response;
            if (apiResp.results.status_id == "200") {
                subject.next(apiResp.results.message);
            }
            else {
                subject.error(apiResp.results.message);

            }
        });
        return subject.asObservable();
    }
}
