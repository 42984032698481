import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { RequestFormModel } from 'src/app/requests/request-form.model';
import { LoggedInUserInfoModel } from 'src/app/site-permissions/loggedin-user-access.model';

import { VendorResponseMasterModel } from '../../vendor-response-master.model';
import { VendorResponseModel } from '../../vendor-response.model';
import { VendorUpdateCommonComponent } from '../../request-vendor-form/vendor-update-common.component';
import { TruckLoadRequestFormModel } from 'src/app/requests/truck-load-request-form.model';
import { TruckVendorResponseModel } from '../../truck-load-vendor-response.model';
import { TruckLoadRequestDetails } from 'src/app/requests/data/formdata/truck-load-request-details.data';
import { TruckLoadVendorResponseFormData } from '../../data/truck-load-vendor-response-form.data';
import { formatDate } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { VendorApiSubmit } from '../../data/vendor-api-submit.data';
import * as moment from 'moment';



@Component({
  selector: 'app-truck-load-vendor-emea',
  templateUrl:'..\\truck-load-details-vendor-template.html',
  styleUrls: ['..\\truck-load-details-vendor-template.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [LoggedInUserInfoModel,
    VendorResponseMasterModel,
    ConfirmationService,
    { provide: RequestFormModel, useClass: TruckLoadRequestFormModel },
    { provide: VendorResponseModel, useClass: TruckVendorResponseModel }

  ]
})
export class TruckLoadVendorUpdateComponentEMEA  extends VendorUpdateCommonComponent  implements OnInit {
  
  @Input() requestDestination!: string;
  mexicoCurrencySymbol: string = "";
  _translate:TranslateService;
  spinner:NgxSpinnerService;
  constructor(private userAccessModel: LoggedInUserInfoModel, private requestModel: RequestFormModel,
    private vendorMaster: VendorResponseMasterModel, private vendorModel: VendorResponseModel,translate:TranslateService,
    private cService: ConfirmationService, private toast: ToastrService, private router: Router,private _spinner: NgxSpinnerService, private route: ActivatedRoute) {
      super(translate,_spinner);
      this._translate=translate;
      this.spinner=_spinner;
     }

  ngOnInit() {

   this. requestData = new TruckLoadRequestDetails();
   this.vendorObj = new   TruckLoadVendorResponseFormData();
   if(this.requestDestination=="Mexico")
   {
     this.isMexicoAmountDisplay=true;
   }
   
    this.validateloggedInUser(this.userAccessModel,this.router);
    this.retrievevendorMaster(this.vendorMaster);
    this.retrieveVendorUserMaster(this.vendorMaster);
    this.retrievevendorCurrency(this.vendorMaster);
     this.retrieveRequestDetails(this.transportType, this.requestId, this.requestModel);
  }

  changeMexicoCurrency(event:any){
    let curr = this.currencyOpts.filter(c => c.value == event)[0];
    if (curr.label == "USD") {
        this.mexicoCurrencySymbol = "";
    }
   
}
  onfilterVendorName(rowData: any) {
    this.vendorsData.forEach((v:any) => {
        (v as TruckLoadVendorResponseFormData).disable = true;
        if (v.vendorId == rowData.vendorId) {
          if(this.vendorsList.filter(v1 => v1.value == rowData.vendorId).length > 0)
            v.vendorName = this.vendorsList.filter(v1 => v1.value == rowData.vendorId)[0].label;
          else
          v.vendorName=rowData.vendorName
        }
        else if(v.vendorName==''){
          v.vendorName=this.vendorsList.find(x=>x.value==v.vendorId)!.label;
        }
    });
}
 CreateNewVendorResponseRow() {
  let vendorObj=new TruckLoadVendorResponseFormData();
  vendorObj.currencyType=this.requestData.currencyId;
  vendorObj.currencyType2=this.requestData.currencyId;
  vendorObj.mexicoCurrencyType=this.requestData.currencyId;
  vendorObj.mexicoCurrencyType2=this.requestData.currencyId; 
  this.onCreateNewVendorResponseRow(vendorObj)
}
  onRetrieveRequestCompleted()
  {
    if(this.requestData.destinationCountry!=undefined && this.requestData.destinationCountry.toLowerCase()=="mexico")
    {
      this.isMexicoAmountDisplay=true
    }
    this.retrieveVendorResponse(this.vendorModel);
  }
 
 onRetrieveVendorResponseCompleted()
 {
  this.reasonForSelection=((this.requestData)as TruckLoadRequestDetails).reasonForSelection;
  this.rateReqCalData=((this.requestData)as TruckLoadRequestDetails).rateReqCalData;
  this.rateReqCalFileName=((this.requestData)as TruckLoadRequestDetails).rateReqCalFileName;
  this.rateReqCalFileExtn=((this.requestData)as TruckLoadRequestDetails).rateReqCalFileExtn;

 }

  
  vendorSelected(rowData:any) {
    rowData.validVendor=true;
    rowData.vendorUser=this.VendorUsersList.filter(ven=>ven.VendorId==rowData.vendorId)

  }

  


  addVendor(rowData:any) {
    let vendorObj=new TruckLoadVendorResponseFormData();
    vendorObj.currencyType=this.requestData.currencyId;
    vendorObj.currencyType2=this.requestData.currencyId;
    vendorObj.mexicoCurrencyType=this.requestData.currencyId;
    vendorObj.mexicoCurrencyType2=this.requestData.currencyId;
    this.onAddVendor(rowData,this.toast,vendorObj)
  }
  removeVendor(rowData:any) {
    this.onRemoveVendor(rowData);
  }

  openDeleteVendorPopup(rowData:any) {
    this.onOpenDeleteVendorPopup(rowData,this.cService)
  }

  bindVendorResponseResult(element: any) { 
    
    return element as TruckLoadVendorResponseFormData ;
  }

  onSelectionChange(rData:any,field:any) {
    this.vendorsData.forEach((v:any)=>{
        if (v.vendorId == rData.vendorId) {
          if(field=="isSelected"){
            v.isSelected = !v.isSelected;
            rData.rankAssigned=v.isSelected?rData.rankAssigned:0;
          }
          if(field=="isSelected2"){
            (v as TruckLoadVendorResponseFormData).isSelected2 = !(v as TruckLoadVendorResponseFormData).isSelected2;
            rData.rankAssigned2=(v as TruckLoadVendorResponseFormData).isSelected2?rData.rankAssigned2:0;
          }
           
        }
    })
  }

  isVendorsDueDatePassed(){
    this.enableAwardingSubmit=true;
    this.enableVendorInputMode=false;
    let isdueDategreater:boolean=false;
    this.vendorsData.forEach(ven=>{
      if(ven.dueDate!==undefined && ven.dueDate!=null && ven.dueDate!=""){       
        if(moment(moment(this.minDateValue).format('YYYY-MM-DD')).diff(moment(moment(ven.dueDate).format('YYYY-MM-DD')))<=0){
          isdueDategreater=true;
          this.enableVendorInputMode=true;
        }
      }
      else if(ven.bidAmount==0 && (ven as TruckLoadVendorResponseFormData).bidAmount2==0){
        isdueDategreater=true;
        this.enableVendorInputMode=true;
      }
      else{      
        this.enableVendorInputMode=true;
      }      
    })
    this.selectMinBidAmount(isdueDategreater)
   }

   selectMinBidAmount(isdueDategreater:boolean){
    if(isdueDategreater){
      this.enableAwardingSubmit=false;
    }
    if(this.enableAwardingSubmit){      
      let minBidAmount:any=0;
      minBidAmount=this.calculateMinimumBidAmount(minBidAmount);
      
      if(this.requestData.requestStatusId!==7 && minBidAmount){
        this.vendorsData.forEach(ven=>{
          if(ven.bidAmount==minBidAmount){
            ven.isSelected=true
          }
          if((ven as TruckLoadVendorResponseFormData).bidAmount2==minBidAmount){
            (ven as TruckLoadVendorResponseFormData).isSelected2=true;
          }
        })
      }      
    }
   }

   calculateMinimumBidAmount(minBidAmount:any){
    this.vendorsData.forEach(ven=>{        
      if(ven.bidAmount>0){
        minBidAmount=minBidAmount==0?ven.bidAmount:minBidAmount;
        minBidAmount = minBidAmount>ven.bidAmount?ven.bidAmount:minBidAmount;
      }
      if((ven as TruckLoadVendorResponseFormData).bidAmount2>0){
        minBidAmount=minBidAmount==0?(ven as TruckLoadVendorResponseFormData).bidAmount2:minBidAmount;
        minBidAmount = minBidAmount>(ven as TruckLoadVendorResponseFormData).bidAmount2?(ven as TruckLoadVendorResponseFormData).bidAmount2:minBidAmount;
      }
    }) 
    return minBidAmount;
   }

   rearrangeRanksonRemove(rowData:any){
    this.ranks = [];     

    for(let vd of this.vendorsData){
       //managing vendor first rank
      if((vd.rankAssigned> rowData.rankAssigned) && (vd.rankAssigned> rowData.rankAssigned2)){
        vd.rankAssigned = vd.rankAssigned - 2;
      }
      else if((vd.rankAssigned> rowData.rankAssigned) || (vd.rankAssigned> rowData.rankAssigned2)){
        vd.rankAssigned = vd.rankAssigned - 1;
      }
        
      //managing vendor second rank
      if(((vd as TruckLoadVendorResponseFormData).rankAssigned2> rowData.rankAssigned)
         && ((vd as TruckLoadVendorResponseFormData).rankAssigned2> rowData.rankAssigned2)){
          (vd as TruckLoadVendorResponseFormData).rankAssigned2 = (vd as TruckLoadVendorResponseFormData).rankAssigned2 - 2;
      }
      else if(((vd as TruckLoadVendorResponseFormData).rankAssigned2> rowData.rankAssigned)
         || ((vd as TruckLoadVendorResponseFormData).rankAssigned2> rowData.rankAssigned2)){
          (vd as TruckLoadVendorResponseFormData).rankAssigned2 = (vd as TruckLoadVendorResponseFormData).rankAssigned2 - 1;
      }

      let rObj = {
        value: this.ranks.length + 1,
        label: (this.ranks.length + 1).toString()
      };
      this.ranks.push(rObj);
      let rObj2 ={
        value:this.ranks.length+1,
        label:(this.ranks.length+1).toString()
      }
      this.ranks.push(rObj2);
    }
  } 




  notifyVendors(){
    this.spinner.show();
    let unsavedVendors:boolean=false;
    this.selectedVendors.forEach(vendor=>{
      if(!vendor.responseId){
        unsavedVendors=true;
      }
    })
    if(unsavedVendors){
      this.spinner.hide();
      this.toast.warning(this._translate.instant("UPDATE_VENDORS_BEFORE_NOTIFY"),this._translate.instant("WARNING"))
      return false;
    }
    this.selectedVendors.forEach(vendor=>{
      if(vendor.dueDate==undefined){
        vendor.dueDate=formatDate(this.calculateDueDate(vendor.dueDate),'yyyy-MM-dd','en-Us')
      }
      
    })
    this.selectedVendors = this.vendorModel.validateVendor(this.selectedVendors) 
    this.selectedVendors = this.vendorModel.validateRank(this.ranks, this.selectedVendors);
    let  _vendorData=this.selectedVendors as TruckLoadVendorResponseFormData[];
    let invalidData = _vendorData .filter((v:any) => !v.validVendor || !v.validBidAmount || !v.validComments  || !v.validCurrency || !v.validDueDate);
      if (invalidData.length > 0) {
        this.spinner.show();
        return false;
      }
      else{

      let apisubmitdata:VendorApiSubmit=new VendorApiSubmit();
      apisubmitdata.vendorsData=this.selectedVendors as TruckLoadVendorResponseFormData[];
      apisubmitdata.requestId=this.requestId;
      apisubmitdata.referenceNumber=this.requestData.requestRefNumber;
      apisubmitdata.selectedReason=this.reasonForSelection;
      apisubmitdata.isconfirmed=false;
      apisubmitdata.requestStatus=this.requestData.requestStatusId;
      apisubmitdata.requestorMail=this.requestData.requesterMailId;
      apisubmitdata.transportType=this.transportType;
      apisubmitdata.modifiedBy=this.userAccessPermission.userId;

     this.vendorModel.notifyVendorResponse(apisubmitdata)
      .subscribe(
        {next:response => {        
        this.selectedVendors = [];
        this.vendorsData=[];
        this.retrieveVendorResponse(this.vendorModel)
        this.spinner.hide();
        this.toast.success(this._translate.instant("VENDOR_USERS_NOTIFIED_SUCCESSFULLY"),this._translate.instant("SUCCESS"));
      },
      error:error => {
        this.spinner.hide();
        this.toast.error(this._translate.instant(error),this._translate.instant("ERROR"));
      }
      });
      return true;
    }
 }
 submitBidByVendor(){
  this.spinner.show()
  let invalidData:boolean=false;
  this.vendorsData.forEach(ven=>{
    if(!ven.bidAmount && !(ven as TruckLoadVendorResponseFormData).bidAmount2){       
      invalidData=true;
    }
     
  })
  if(invalidData){
    this.spinner.hide()
    this.toast.warning(this._translate.instant("VALID_BID_AMOUNT_FOR_RATE_RATE_ALTERNATIVE"),this._translate.instant("WARNING"));
     
    return false;      
  } 
  else{

  let apisubmitdata:VendorApiSubmit=new VendorApiSubmit();
      apisubmitdata.vendorsData=this.vendorsData as TruckLoadVendorResponseFormData[];
      apisubmitdata.requestId=this.requestId;
      apisubmitdata.referenceNumber=this.requestData.requestRefNumber;
      apisubmitdata.selectedReason=this.reasonForSelection;
      apisubmitdata.isconfirmed=false;
      apisubmitdata.requestStatus=this.requestData.requestStatusId;
      apisubmitdata.requestorMail=this.requestData.requesterMailId;
      apisubmitdata.transportType=this.transportType;
      apisubmitdata.modifiedBy=this.userAccessPermission.userId;
  
  this.vendorModel.submitBidByVendor(apisubmitdata)
  .subscribe(
    {next:response=>{
    if(response=='SUCCESS'){
      this.vendorsData=[]
      this.retrieveVendorResponse(this.vendorModel)
      this.spinner.hide()
      this.toast.success(this._translate.instant("BID_SUBMIT_SUCCESFUL"),this._translate.instant("SUCCESS"));
    }      
    else{
      this.spinner.hide()
      this.toast.warning(this._translate.instant(response),this._translate.instant("WARNING"))
    }
  },
  error:error=>{
    this.spinner.hide()
    this.toast.error(this._translate.instant(error),this._translate.instant("ERROR"));
  }
}
  )

  return true;
}
}

  updateVendors() {
    this.spinner.show();
    if (!this.clearVendors) {
      this.vendorsData = this.vendorModel.validateVendor(this.vendorsData) 
      let  _vendorData=this.vendorsData as TruckLoadVendorResponseFormData[];
      let invalidData = _vendorData .filter((v:any) => !v.validVendor || !v.validBidAmount || !v.validComments || !v.validCurrency || !v.validDueDate);

      if (invalidData.length > 0) {
        this.spinner.show();
        return ;
      }
           
    }
    (this.vendorsData as TruckLoadVendorResponseFormData[]).forEach(vendor=>{
      if(vendor.dueDate==undefined || vendor.dueDate == null || vendor.dueDate ==''){
        vendor.dueDate=formatDate(this.calculateDueDate(vendor.dueDate),'yyyy-MM-dd','en-Us')
      }
      vendor.rankAssigned=vendor.rankAssigned??0;
      vendor.rankAssigned2=vendor.rankAssigned2??0;
    })

    let apisubmitdata:VendorApiSubmit=new VendorApiSubmit();
    apisubmitdata.vendorsData=this.vendorsData as TruckLoadVendorResponseFormData[];
    apisubmitdata.requestId=this.requestId;
    apisubmitdata.referenceNumber=this.requestData.requestRefNumber;
    apisubmitdata.selectedReason=this.reasonForSelection;
    apisubmitdata.isconfirmed=false;
    apisubmitdata.requestStatus=this.requestData.requestStatusId;
    apisubmitdata.requestorMail=this.requestData.requesterMailId;
    apisubmitdata.transportType=this.transportType;
    apisubmitdata.modifiedBy=this.userAccessPermission.userId;

    this.vendorModel.submitvendorResponse(apisubmitdata,"","","")
      .subscribe(
        {next:response => {
        this.vendorsData = [];
        this.selectedVendors=[];
        this.ranks=[]
        this.spinner.hide()
        this.retrieveRequestDetails(this.transportType,this.requestId,this.requestModel);
        this.refresh.emit('update');
        if(response=='SUCCESS')
        this.toast.success(this._translate.instant("VENDORS_UPDATE_SUCCESSFUL"),this._translate.instant("SUCCESS"));
        else{
          this.toast.warning(this._translate.instant(response),"warning")
        }
      },
        error:error => {
          this.toast.error(this._translate.instant(error),this._translate.instant("ERROR"));
        }
        });
  }

  canelSubmit() {
    this.spinner.show()
    this.vendorsData = [];
    this.selectedVendors=[];
    this.ranks=[];
    this.retrieveVendorResponse(this.vendorModel);
    this.spinner.hide(); 


  }
 override cancelvendorSubmit(){   
    this.router.navigateByUrl('/request-list')
 }

  openCofirmPopup() {
    this.cService.confirm({
      message: this._translate.instant('SURE_TO_PROCEED'),
      header: this._translate.instant('CONFIRMATION'),
      accept: () => {
        this.ConFirmVendor();
      },
      reject: () => {

      }
    });
  }


  ConFirmVendor() {
    this.spinner.show()
    this.vendorsData = this.vendorModel.validateVendor(this.vendorsData);
    this.vendorsData = this.vendorModel.validateRank(this.ranks, this.vendorsData);
   
    let  _vendorData=this.vendorsData as TruckLoadVendorResponseFormData[];
    let invalidData = _vendorData .filter((v:any) => !v.validVendor  || !v.validBidAmount  || !v.validComments  || !v.validRank || !v.validRank2  || !v.validCurrency || !v.validDueDate);
    
    if(invalidData.length > 0) {
      this.spinner.hide()
      this.toast.warning(this._translate.instant("CHECK_VENDOR_DETAILS"), "Warning!");
      return false;
    }
	
    this.validReason = this.reasonForSelection != null && this.reasonForSelection != undefined && this.reasonForSelection != "";
    if(!this.validReason) {
      this.spinner.hide()
      this.toast.warning(this._translate.instant("VAL_REASON_FOR_SELCTION"),this._translate.instant("WARNING"))
     return false;
    }
else{
    let apisubmitdata:VendorApiSubmit=new VendorApiSubmit();
    apisubmitdata.vendorsData=this.vendorsData as TruckLoadVendorResponseFormData[];
    apisubmitdata.requestId=this.requestId;
    apisubmitdata.referenceNumber=this.requestData.requestRefNumber;
    apisubmitdata.selectedReason=this.reasonForSelection;
    apisubmitdata.isconfirmed=true;
    apisubmitdata.requestStatus=this.requestData.requestStatusId;
    apisubmitdata.requestorMail=this.requestData.requesterMailId;
    apisubmitdata.transportType=this.transportType;
    apisubmitdata.modifiedBy=this.userAccessPermission.userId;

    this.vendorModel.submitvendorResponse(apisubmitdata,this.rateReqCalData,this.rateReqCalFileName,this.rateReqCalFileExtn)
      .subscribe(
        {next:submitvendorResponse => {        

        this.vendorsData = [];
        this.ranks=[]
        this.retrieveRequestDetails(this.transportType,this.requestId,this.requestModel);
        this.refresh.emit('submit');
        this.spinner.hide()
        if(submitvendorResponse=='SUCCESS')
        this.toast.success(this._translate.instant("VENDORS_AWARD_DONE"),this._translate.instant("SUCCESS"));
        else{
          this.toast.warning(this._translate.instant(submitvendorResponse),this._translate.instant("WARNING"))
        }
      },
        error:error => {
          this.spinner.hide()
          this.toast.error(this._translate.instant(error),this._translate.instant("ERROR"));
        }
        });
return true;
      }
  
}




 
}
