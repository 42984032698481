import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { UserManagementForm } from "./data/user-management-form.data";
import { UserManagementData } from "./data/user-management.data";
import { UserManagementServices } from "./user-management.service";

@Injectable()

export  class UserManagementModel
{
    constructor(private client: UserManagementServices) { }

    retrieveColumnHeaders()
    {
      return [
            { field: "user_name", header: "ADMIN.USER_NAME", width: "8vw" },
            { field: "first_name", header: "ADMIN.FIRST_NAME", width: "8vw" },
            { field: "last_name", header: "ADMIN.LAST_NAME", width: "8vw" },
            { field: "email", header: "ADMIN.EMAIL", width: "18vw" },
            { field: "role_name", header: "ADMIN.ROLE", width: "8vw" },
            { field: "region", header: "ADMIN.REGION", width: "6vw" },
            { field: "status", header: "ADMIN.STATUS", width: "8vw"},
            { field: "edit", header: "", width: "5%" }
          ];

         
    }
    

    
  validateUserForm( data:UserManagementForm):UserManagementForm {
    data.user_name = data.user_name.trim();
    data.first_name = data.first_name.trim();
    data.last_name = data.last_name.trim();
    data.email = data.email.trim();
    data.validUserName = this.validInput(data.user_name) && this.validateUserName(data.user_name);
    data.validFirstName = this.validInput(data.first_name) && this.validateTextInput(data.first_name);
    data.validLastName = this.validInput(data.last_name) && this.validateTextInput(data.last_name);
    data.validRole = data.role_id!=undefined && data.role_id!=null && data.role_id>0;
    data.validUserEmailId = this.validInput(data.email) && this.ValidateEmail(data.email);
    return data;
  }
  private processSelectedRegion(selectedRegion: any, updateRegion: any[]) {
    selectedRegion.forEach((element:any) => {
        let dropdownData = {
            masterId: element,
            masterValue: "",
            masterOrderBy: 0
        };

        updateRegion.push(dropdownData);
    });
}


  onSaveUserData(data:UserManagementData, selectedRegion: any):Observable<string>
  {
    let subject=new Subject<string>();
    let updateRegion: any[] = [];
    this.processSelectedRegion(selectedRegion, updateRegion);

    if(data.role_id == 1) {
       data.is_admin = false;
      }
      let userInfo = {
        user_id: data.user_id,
        user_name: data.user_name,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        role_id: data.role_id,
        is_admin: data.is_admin,
        is_active: data.is_active,
        modified_by:data.modified_by,
        region_master: updateRegion
      }

      this.client.saveUsers(userInfo).subscribe({
        next:saveResponse => {
        let apiResponse:any=saveResponse;
        let apiResult:any=apiResponse.result;
        
        if(apiResult.status_id==200)
        {
          
          subject.next(apiResult.message);
        }
        else {
          subject.error(apiResult.message);
        }
        
      },
      error:error=>
      {
        subject.error(error);
      }
  });
      return subject.asObservable();
  }
  validateTextInput(validStr:any) {
    let inputText = /^[a-zA-Z\s]+$/;
    return inputText.test(validStr);
  }

  validInput(validStr:any) {
    return validStr.length > 1;
  }
  ValidateEmail(validStr:any) {
    let emailReg = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/;
    return emailReg.test(validStr);
  }
  validateUserName(validStr:any) {
    let unRegex = /^[a-zA-Z0-9]+$/;
    return unRegex.test(validStr);
  }
    retrieveUserCollection(requestedBy: number):Observable<UserManagementForm[]>
    {
        let subject=new Subject<UserManagementForm[]>();
        let resultCollection:UserManagementForm[]=[];
        this.client.retrieveUsersCollection(requestedBy).subscribe({
          next:(userCollectionResult:any)=>
            {
            let apiResponse:any=userCollectionResult;
                let apiResult:any=apiResponse.result;
                if(apiResult.status_id==200)
                {
                    let apiResultData:any=apiResponse.data;
                    apiResultData.forEach((element:any) => {
                        let resultdata:UserManagementForm= element as  UserManagementForm;
                        resultdata.edit=true;
                        resultdata.status=resultdata.is_active ? "Active" : "Inactive";
                          
                        resultCollection.push(resultdata);
                      });
                      subject.next(resultCollection);
                }
                else
                {
                    subject.error(apiResult.message)
                }
            },
            error:error=>
            {
                subject.error(error)
            }
    });
            return subject.asObservable();
    }
}