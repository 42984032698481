import { VendorResponseData } from "./vendor-response.data";

export class RailRateRequestVendorResponseData extends VendorResponseData
{
    routeCode:string="";
transmitTime:string="";
responseNeededBy:string="";
segment:string="";
eMail:string="";
rateoffer:string=""
interchange:string=""
fsc_miles:number=0;
}