export class SiteAccessPermission
{
    
    appAccess:boolean=false;
    homeAccess:boolean=false;
    lpAccess:boolean=false;
    requestAccess:boolean=false;
    adminAccess:boolean=false;
    vendorAccess:boolean=false;


}