<div *ngIf="region=='NA'">
  <app-dry-request-na-details></app-dry-request-na-details>
</div>
<div *ngIf="region=='SA'">
  <app-dry-request-sa-details></app-dry-request-sa-details>
</div>
<div *ngIf="region=='EMEA'">
  <app-dry-request-emea-details></app-dry-request-emea-details>
</div>
<div *ngIf="region=='AP'">
  <app-dry-request-ap-details></app-dry-request-ap-details>
</div>

