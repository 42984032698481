import { VendorResponseFormData } from "./vendor-response-form.data";


export class RequestVendorResponseForm {
    isFinal: boolean = false;
    isFinalstatus: boolean= false;
    logisticMailId: string = "";
    modifiedBy: number=0;
    isExternalUser: boolean=false;
    reasonForSelection: string="";
    referenceNumber: string=""
    requesterMailId: string = "";
    requestId: number=0;
    transportType: number = 0;
    vendorResponse!: VendorResponseFormData[];

    rateReqCalData:any="";
    rateReqCalFileName:string="";
    rateReqCalFileExtn:string="";

    viewBidUrl: string = "";
    region:number= Number(sessionStorage.getItem('regionId'));
}