import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isNullOrUndefined } from './shared/utils/nullorundefined';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'bulktms';
  languages = ['english', 'spanish', 'portuguese','japanese','korean','chinese'];

  languages1 = ['en', 'es', 'pt','ja','ko','zh'];
  currentLang:string='english' 
showheader:boolean=false;
  constructor(translate:TranslateService){
    if(sessionStorage['userId'] != "undefined" && sessionStorage['userId'] != undefined && sessionStorage['userId'] != null && sessionStorage['userId'] != "null" && sessionStorage['userId'] != "")
    
    this.showheader=true;  

    translate.addLangs(this.languages)
    translate.setDefaultLang('english');
    if(sessionStorage.getItem('selectedLanguage')!==null &&sessionStorage.getItem('selectedLanguage')!==undefined
     &&sessionStorage.getItem('selectedLanguage')!==''){
      this.currentLang=isNullOrUndefined(sessionStorage.getItem('selectedLanguage'))?'english' :sessionStorage['selectedLanguage'].toLowerCase();
     }
     
    translate.use(this.currentLang);
  }
}
