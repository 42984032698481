<div class="container-fluid cf-bg pe-0" id="header" *ngIf="accessObj.appAccess  ">
  <div class="container nav-class pe-0 mx-0 ps-0" style="position: relative; z-index:9997">
    <nav class="navbar navbar-expand-lg navbar-light" style="padding: 0px">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
        <span class="logotext">Logistics Procurement Rate Request</span>
      </button>
      <div *ngIf="accessObj.appAccess" class="collapse navbar-collapse" id="navbarSupportedContent"
        style="background-color: white; height:79px;">
        <ul class="navbar-nav me-auto">
          <li *ngIf="accessObj.homeAccess" class="nav-item" routerLinkActive="active">
            <a class="nav-link navwidth  pt-0 pb-0 " routerLink="/landingpage" (click)="homeclick()">
              <i class="pi pi-home"></i>
            </a>
          </li>
          <li *ngIf="accessObj.lpAccess && regionDD?.length>0 && !isLandingPage " class="nav-item"
            routerLinkActive="active">
            <a *ngIf="!accessObj.isvendorUser" class="nav-link navwidth  pt-0 pb-0"
              routerLink="home">{{'MENU.RATE_REQUEST_FORM' | translate}}</a>
          </li>
          <li *ngIf="regionDD?.length>0 && !isLandingPage" class="nav-item" routerLinkActive="active">
            <a class="nav-link navwidth  pt-0 pb-0" style="cursor: pointer;" routerLink="request-list"
              (click)="navigateToRequests()">{{'MENU.REQUESTS' | translate}}</a>
          </li>
          <li *ngIf="accessObj.adminAccess && !isLandingPage" class="nav-item" routerLinkActive="active">
            <a class="nav-link navwidth pt-0 pb-0" routerLink="admin">{{'MENU.ADMIN' | translate}}</a>
          </li>
        </ul>
      </div>

      <div class="row me-lg-0" id="headerid" style="display:flex;
          align-items:center;
          justify-content:center;">

        <div class="col col4 pe-0" *ngIf="regionDD?.length>0 && !isLandingPage" style="display:flex;
          align-items:center;
          justify-content:center">
          <img alt="" src="assets/images/icons/location.png" style="width:24px;height: 24px;" />

          <p-dropdown [options]="regionDD" [(ngModel)]="selectedRegions" optionLabel="label"
            (onChange)="onRegionChange($event)"></p-dropdown>
        </div>

        <div class="col col4 pe-0" *ngIf="languages?.length>0 && !isLandingPage">
          <p-dropdown [options]="languages" [(ngModel)]="selectedLang" (onChange)="onLanguageChange($event)"  id="regionDD">
            <ng-template let-item pTemplate="selectedItem">
              <img src="assets/images/icons/{{item.label}}.png" alt="{{item.label}}"
                style="width:16px;vertical-align:middle" />
              <span style="vertical-align:middle; margin-left: .5em">{{item.label}}</span>
            </ng-template>
            <ng-template let-language pTemplate="item">
              <div class="ui-helper-clearfix"
                style="position: relative;height: 25px;width:130px !important; border:0px !important">
                <img src="assets/images/icons/{{language.label}}.png" alt="{{language.label}}"
                  style="width:24px;position:absolute;top:1px;left:5px" />
                <div style="font-size:14px;float:right;margin-top:4px">{{language.label}}</div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>

        <div class="col col4">
          <a class="nav-link" style="font-size: 0.9rem; cursor: pointer; color: #004A96 !important;" (click)="logout()">LogOut</a>
        </div>
      </div>

      <a class="navbar-brand imglogo me-0" (click)="homeclick()">
        <div class="img-logo logotext d-flex align-items-center text-wrap">
          Logistics Procurement Rate Request</div>
        <img src="./assets/images/splitter.PNG" alt="brand" class="img-responsive img-logo" />

        <img src="./assets/images/BASFo_wh100tr.png" alt="brand" class="img-responsive img-logo basflogo" />
      </a>
    </nav>
  </div>
</div>