import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { CleaningCategoryService } from "./cleaning-category.service";
import { CleaningCategoryFormData } from "./data/cleaning-category-form.data";

@Injectable()

export  class  CleaningCategoryModel
{
    constructor(private client: CleaningCategoryService) { }
  
    retrieveCleaningCategoryStatus() {
         return [
            { label: "Active", value: true },
            { label: "InActive", value: false }
        ];
        
    }

    retrieveCleaningCategoryColumn()
    {
        return [
            { field: "mfg_description", header: "MFG Description", width: "25%" },
            { field: "mfg_code", header: "MFG Code", width: "15%" },
            { field: "cleaning_category_code", header: "Cleaning Category Code", width: "20%" },
            { field: "new_rate", header: "Fee", width: "10%" },
            { field: "freight_class", header: "Freight Class", width: "15%" },
            { field: "status", header: "Status", width: "13%" },
            { field: "edit", header: "", width: "5%" }
          ];
    }

    retrieveCleaningCategoryCollection(requestedBy:number):Observable<CleaningCategoryFormData[]>
    {
        let subject= new Subject<CleaningCategoryFormData[]>();
        let resultCollection:CleaningCategoryFormData[]=[];
        this.client.retrieveCleaningCategoryCollection(requestedBy).subscribe(
          {next:cleaningcategoryResponse=>{
            let apiResponse: any = cleaningcategoryResponse;
            let apiResult: any = apiResponse.result;
            if (apiResult.status_id == 200) {
                let apiResultData: any = apiResponse.data;
                apiResultData.forEach((element:any) => {
                    let resultdata = element as CleaningCategoryFormData;
                    resultdata.edit = true;
                    resultdata.status = resultdata.is_active ? "Active" : "Inactive";
                    resultCollection.push(resultdata);

                });
                subject.next(resultCollection);
            }
            else {
                subject.error(apiResult.message)
            }
        },
           error: error => {
                subject.error(error);
           }
            });
          
            return subject.asObservable();
    }

    
  validateClenaingCategoryForm(data:CleaningCategoryFormData):CleaningCategoryFormData{
    data.mfg_description = data.mfg_description.trim();
    data.mfg_code = data.mfg_code.trim();
    data.cleaning_category_code = data.cleaning_category_code.trim();
    data.new_rate = data.new_rate.trim();
    data.freight_class = data.freight_class.trim();
    data.validMFGDesc = this.validInput(data.mfg_description);
    data.validMFGCode = data.mfg_code.length < 5 && this.validateCodes(data.mfg_code);
    data.validCCCode = data.cleaning_category_code.length < 5 && this.validateCodes(data.cleaning_category_code);
    data.validCCFee = data.new_rate != null && data.new_rate != undefined && data.new_rate != "";// && this.validatePrice(this.cleaningCategoryFees);
    data.validFreightClass = data.freight_class.length < 5 && this.validateCodes(data.freight_class);
    return data;
  }
onSaveCleaningCategory(data:CleaningCategoryFormData,userId:number):Observable<string>
{
 let subject =new Subject<string>();
 let ccInfo = {
    mfg_id: data.mfg_id,
    mfg_code: data.mfg_code,
    mfg_description: data.mfg_description,
    freight_class: data.freight_class,
    cleaning_category_code: data.cleaning_category_code,
    new_rate: data.new_rate,
    is_active: data.is_active,
    modified_by:userId
  }
  if (data.mfg_id != 0) {
    this.client.updateCleaningCategory(ccInfo)
    .subscribe({
      next:updateCleaningCategoryResponse => {
      this.processSaveResponse(updateCleaningCategoryResponse,subject);
    },
    error: (error) => {
        subject.error(error)
    }});
  } else {
    this.client.createCleaningCategory(ccInfo).subscribe({
      next:createCleaningCategoryResponse => {
        this.processSaveResponse(createCleaningCategoryResponse,subject);
      },
      error: (error) => {
        subject.error(error)
    }});
  }
  return subject.asObservable();
}


private processSaveResponse(updatevendorResponse: any, subject: Subject<string>) {
    let apiResponse: any = updatevendorResponse;
    let apiResult: any = apiResponse.result;
    if (apiResult.status_id == 200) {
        subject.next(apiResult.message);
    }
    else {
        subject.error(apiResult.message);
    }
}
  validInput(validStr:any) {
    return validStr.length > 1;
  }
  ValidateEmail(validStr:any) {
    let emailReg = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/;
    return emailReg.test(validStr);
  }
  validateUserName(validStr:any) {
    let unRegex = /^[a-zA-Z0-9]+$/;
    return unRegex.test(validStr);
  }

  validateTextInput(validStr:any) {
    let inputText = /^[a-zA-Z\s]+$/;
    return inputText.test(validStr);
  }

  validateCodes(validStr:any) {
    let codeRegex = /^[a-zA-Z0-9]+$/;
    return codeRegex.test(validStr);
  }

  validatePrice(validStr:any) {
    let priceRegex = /^[1-9]\d((?!\1)[.]\d\d)?/;
    return priceRegex.test(validStr);
  }

  validNumber(validStr:any) {
    let numRegex = /^[0-9,]*$/;
    return numRegex.test(validStr);
  }


}
