import { PackageContainerBoxDrayageFromData } from "./package-container-box-drayage-form.data";

export class PackageContainerBoxDrayageDetails extends PackageContainerBoxDrayageFromData 
{

    annualVolumeUnit: string="";
    monthlyVolumeUnit: string="";
    reasonForSelection: string="";

    rateReqCalData:any="";
    rateReqCalFileName:string="";
    rateReqCalFileExtn:string="";
    
    requestType: string="";
    tempRequirement: string="";
    transportTypeName: string="";
   
    hazardousClass:string="";

   
    //Shipping ->Orgin
    orginLocationType:string="";

    //Shipping->Destination

    destinationLocationType:string="";


}