import { RailRateRequestFormData } from "./rail-rate-form.data";
export class RailRateRequestDetails extends RailRateRequestFormData
{
    railCartType:string="";

    annualVolumeUnit: string="";
    reasonForSelection: string="";
    rateReqCalData:any="";
    rateReqCalFileName:string="";
    rateReqCalFileExtn:string="";
    
    requestType: string="";
    transportTypeName: string="";
    orginSiteCompetitiveness:string="";
    destinationSiteCompetitiveness:string="";
    updatedPayerOptiob:string="";
}