import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { ISODryageTruckFormData } from "./data/formdata/iso-dryage-truck-form.data";
import { ISoDryageTruckRequestDetails } from "./data/formdata/iso-dryage-truck-request-details.data";
import { RequestFormData } from "./data/formdata/request-common.data";
import { RequestCommonListData } from "./data/request-list/common-request-list.data";
import { ISODryageTruckListData } from "./data/request-list/iso-dryage-truck-request-list.data";
import { RequestFormModel } from "./request-form.model";
import { RequestFormService } from "./request-form.services";
import { RequestListModel } from "./request-list/request-list.model";
@Injectable()

export class ISODryageTruckFormModel
 extends RequestFormModel implements RequestListModel {
    constructor(service: RequestFormService) {
        super(service);
        console.log("constructor")
    }
    retrieveListHeader():any {
      let  requestDetailCols:any = [
        { field: "referenceNumber", header: "ID", width: "5vw" },
        // { field: "transportType", header: "BULK_TYPE", width: "6vw" },
        { field: "requestType", header: "REQUEST_TYPE", width: "8vw" },
        { field: "requestor", header: "REQUESTOR", width: "7vw" },
        { field: "businessUnit", header: "BU", width: "4vw" },
        { field: "articleNumber", header: "ARTICLE_NUMBER", width: "5vw" },
        { field: "product", header: "PRODUCT", width: "8vw" },
        { field: "orgin", header: "ORIGIN", width: "8vw" },
        { field: "destination", header: "DESTINATION", width: "8vw" },
        { field: "status", header: "STATUS", width: "8vw" },
        { field: "requestedDate", header: "DATE", width: "6vw"}
          ];
          return requestDetailCols;
    }

   

    createRequest(data: ISODryageTruckFormData): Observable<string> {
        let subject = new Subject<string>();
        this.insertRequest(data).subscribe({
            next:result => { subject.next(result) },
            error:error => { subject.error(error) }
        }
        );
        return subject.asObservable();
    }

     OnRequestDetailSuccess(apiResponse: any, resolve: (value: RequestFormData | PromiseLike<RequestFormData>) => void) {
        let data: ISoDryageTruckRequestDetails = apiResponse.data as ISoDryageTruckRequestDetails;
        resolve(data);
    }

    
    responseOnSuccessRequestList(apiResponse: any, subject: Subject<RequestCommonListData[]>) {
        let requestCollection: ISODryageTruckListData[] = [];
        apiResponse.data.forEach((element:any) => {
           
            requestCollection.push(element as ISODryageTruckListData);
        });

        subject.next(requestCollection);
    }

}