import { LiquidTruckRequestFormData } from "./liquid-request-form.data";

export class LiquidRequestDetails extends LiquidTruckRequestFormData {

    campaignSeasonalVolumeUidValue:string="";
    annualVolumeUnit: string="";
    monthlyVolumeUnit: string="";
    reasonForSelection: string="";

    rateReqCalData:any="";
    rateReqCalFileName:string="";
    rateReqCalFileExtn:string="";
    
    requestType: string="";
    tempRequirement: string="";
    transportTypeName: string="";
    originPlantCode:string="";
    destinationPlantCode:string=""

    mandadoryDocsInsideTruckIdValue:string="";
    averageProductIdValue:string="";

    pumpName:string="";
    chassisName:string="";
    loadingPointName:string="";

    isoTankRentalRequirementName: string="";
    tankTypeName:string="";
    dangerousGoodsClassName: string="";

    totalWeightShipmentUidValue:string="";
    preferedTransportModeIdValue: string="";
    heatingDuringTransportIdValue:string="";
    groundOperatingEquipmentIdValue:string="";
    specialCertificationIdValue: string="";
    hoseLengthIdValue: string="";
    driverLanguageIdValue: string="";
    requiredCleaningProcedureIdValue: string="";
    vacuumTestConfirmationIdValue: string="";
    specificEquipmentTypeIdValue:string="";
    
}