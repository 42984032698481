<div class="form-wraper">
  <div *ngIf="!userNotFound" class="form-container card">
    <div class="form-body">
      <form [formGroup]="form" (ngSubmit)="onSubmit()"  autocomplete="off" class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div class="row" style="margin: 20px;">
          <div class="col col-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
              Username
            </label>
          </div>
          <div class="col col-6">
            <input
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              [ngClass]="{'border-red-500': submitted && form.controls['username'].invalid}" id="username" type="text"
              placeholder="Username" formControlName="username" />
          </div>          
        </div>
        <div class="row" style="margin:20px;">
          <div class="col col-4">
            <label class="block text-gray-700 text-sm font-bold mb-3" for="password">
              Password
            </label>
          </div>
          <div class="col col-6">
            <input
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              [ngClass]="{'border-red-500': submitted && form.controls['password'].invalid}" id="password" type="password"
              placeholder="Password" formControlName="password" />

            <span *ngIf="submitted && form.controls['email'].invalid" class="text-red-500 text-xs italic" id="email-error">
              Please enter a valid username.
            </span>
            <p *ngIf="noAccess" style="color: red;">
              No User Found
            </p>
            <p *ngIf="noAccessUser" style="color: red;">
              Unauthorised User
            </p>
          </div> 
        </div>
        <div class="flex items-center justify-between" style="text-align: -webkit-center">
          <button
            class=" btn custom-btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit">
            Login
          </button>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="userNotFound">
    <h3 style="text-align: center;margin-top: 100px;">You haven't registered for this application. Please Contact Application Administrator.</h3>
</div>
</div>