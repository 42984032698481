<div class="form-wraper" style="width:500px;margin:auto">
    <div class="form-container card">
        <div class="form-header" style="padding:20px;text-align: center;">
            <a class="navbar-brand nav-img p-0 m-0" href="#">

            </a>
        </div>
        <div class="form-body" style="padding:20px">
            <div class="flex items-center justify-between" style="text-align : center">
                <button (click)="SAMLService()" class="btn submit-btn">
                    BASF Log in
                </button>
                <p></p>
                <button (click)="SAMLExternalService()" class="btn submit-btn">
                    Vendor/Carrier Log in
                </button>

            </div>

        </div>
    </div>
   
</div>