import { Injectable } from "@angular/core";
import { LoggecInUserInfo } from "./data/loggedin-user-info";
@Injectable()

export class LoggedInUserInfoModel
{
    public retrieveLoggedInUserSitePermission()
    {
      let loggedInUserDetails=new  LoggecInUserInfo();
      let userName= sessionStorage.getItem('bulkTMSUser');

      if(userName!=undefined&& userName!=null && userName!="" && userName!=" ")
      {
         loggedInUserDetails.userId = Number(sessionStorage.getItem('userId'));
           loggedInUserDetails.username = sessionStorage['bulkTMSUser'];
           loggedInUserDetails.business_unit = sessionStorage.getItem('BU');
           loggedInUserDetails.email=sessionStorage['email'];
           loggedInUserDetails.firstname=sessionStorage['firstName'];
           loggedInUserDetails.lastname=sessionStorage['lastName'];
           loggedInUserDetails.roleId=sessionStorage.getItem('roleId');
           loggedInUserDetails.site_access.appAccess = (sessionStorage.getItem("appAccess") == "true");
           loggedInUserDetails.site_access.lpAccess = (sessionStorage.getItem("lpAccess") == "true");
           loggedInUserDetails.site_access.vendorAccess = (sessionStorage.getItem("vendorAccess") == "true");
           loggedInUserDetails.site_access.homeAccess= (sessionStorage.getItem("homeAccess") == "true");
           loggedInUserDetails.site_access.requestAccess= (sessionStorage.getItem("requestAccess") == "true");
           loggedInUserDetails.site_access.adminAccess= (sessionStorage.getItem("adminAccess") == "true");

      }
      return loggedInUserDetails;
    }
}