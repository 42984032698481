import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DropDownMaster } from "src/app/shared/dropdown-master.data";
import { LoggecInUserInfo } from "src/app/site-permissions/data/loggedin-user-info";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { CleaningCategoryModel } from "../../cleaning-category/cleaning-category.model";
import { CleaningCategoryFormData } from "../../cleaning-category/data/cleaning-category-form.data";
import { ArticleManagementModel } from "../article-management.model";
import { ArticleMappingFormData, ArticleRequest } from "../data/article-management-form.data";
import { ProductModel } from "src/app/shared/product/products.model";


@Component({
  selector: 'app-admin-article-management',
  templateUrl: './admin-article-management.component.html',
 
  encapsulation: ViewEncapsulation.None,
  providers: [LoggedInUserInfoModel, ArticleManagementModel, CleaningCategoryModel,ProductModel]
})

export class AdminArticleManagementComponent implements OnInit {

  @Input() loggedinUserinfo!: LoggecInUserInfo;

  articleMappingCollection: ArticleMappingFormData[] = []
  selectedMFG: any="";
  amEditing=false;
  mfgOpts: DropDownMaster[] = [];
  cleaningCategory:any;
  statusOpts: any;
  articleTableColumns: any;
  articleMappingData: ArticleMappingFormData = new ArticleMappingFormData();

  mfgList:any[]=[];
  selectedMfgList:any[]=[];
  articleRequest: ArticleRequest={
    first: 0,
    totalRecords: 10,
    rowsPerPage: 10,
    requestedBy: 0,
    filter: {
      article_number: "",
      product_name: "",
      mfg_code: "",
      mfg_description: "",
      status: ""
    }
  }
  loading: boolean = false;
  
  constructor(private articleModel: ArticleManagementModel,
    private cleaningCategoryModel: CleaningCategoryModel,
    private productModel:ProductModel,
    private spinner: NgxSpinnerService,
    private toast: ToastrService) {

  }
  ngOnInit(): void {

    this.retrieveArticleStatus();
    this.retrieveArticleGridColumn();
    this.retrieveMFGMaster();
  }
  onRowAMEditInit(rowData:any) {
    this.articleMappingData.editing = true;
    this.articleMappingData.article_id = rowData.article_id;
    this.articleMappingData.article_number = rowData.article_number;
    this.articleMappingData.product_name = rowData.product_name;
    this.articleMappingData.is_active = rowData.is_active;
    this.retrieveArticleMappedMfgCollection(rowData.article_number);
  }

  retrieveArticleMappedMfgCollection(articleNumber:string){
    this.spinner.show();
    this.productModel.retrieveProductForArticle(articleNumber).subscribe({
      next:result => {
        this.mfgList=result.map((mfg:any)=>{return {label:`${mfg.mfgCode},${mfg.mfg}`,value:mfg.mfgCode}});
        this.articleMappingData.mfg_codes=result.filter((am:any)=>am.isactive).map((mfg:any)=>mfg.mfgCode);
        this.mfgOpts = this.mfgOpts.filter((mfg: any) => !this.mfgList.some((m: any) => m.value === mfg.value ));
        this.spinner.hide();
      },
      error:error => {
        this.toast.error(error,"ERROR");
        this.spinner.hide();
      }
      
    });
  }
  onCancel()
  {
    this.retrieveMFGMaster();
    this.articleMappingData=new ArticleMappingFormData();
    this.selectedMFG="";
    this.mfgList=[];
    this.articleMappingData.editing = false;
  }

  onAddMfg(){
    this.mfgList.push(this.mfgOpts.find((mfg:any)=>mfg.value==this.selectedMFG));
    this.articleMappingData.mfg_codes.push(this.selectedMFG);
    this.mfgOpts=this.mfgOpts.filter((mfg:any)=>mfg.value!=this.selectedMFG);
    this.selectedMFG="";
  }

  updateArticleMapping()
  {  
        this.spinner.show();
        let artMappingCheck:any=this.articleMappingCollection.filter(am=>am.article_id!=this.articleMappingData.article_id)
        this.articleMappingData= this.articleModel.validateArticleForm(this.articleMappingData,this.selectedMFG)
        if(!this.articleMappingData.editing &&
           artMappingCheck.find((sc:any)=>sc.article_number==this.articleMappingData.article_number)){
          this.articleMappingData.validArticleNumber=false;
          this.toast.warning("There is already an Article Number with this name.")  ;
        }
        
        if (!(this.articleMappingData.validArticleNumber && this.articleMappingData.validProductName && this.articleMappingData.validMFG)) {
          this.spinner.hide();
          return false;
        }
        else{
        this.articleModel.onSave(this.articleMappingData,this.loggedinUserinfo.userId,this.cleaningCategory)
        .subscribe({
          next:(saveResponse:any)=>
          {
            this.toast.success(saveResponse, "Success");
            this.articleRequest.first=0;
            this.getArticleData();
            this.onCancel();            
          }
          ,
          error:(error)=>
          {
            this.toast.error(error, "Error!");
            this.spinner.hide();
          }});
          return true;
        }
     
  }
  retrieveArticleStatus() {
    this.statusOpts = this.articleModel.retrieveArticleStatus();
  }
  retrieveArticleGridColumn() {
    this.articleTableColumns = this.articleModel.retrieveArticleGridColumn();
    const screenWidth = window.innerWidth;
    if(screenWidth < 750) {
      this.articleTableColumns.forEach((col:any) => {
          col.width = (parseInt(col.width.replace('%','')) * 10).toString() + 'px';
      });
    }
  }  

  getArticleData(){
    this.articleModel.lazyLoadArticlesData(this.articleRequest).subscribe({
      next: (articleMappingResponse: any) => {
        this.articleRequest.totalRecords = articleMappingResponse[0]?.totalRecords;
        this.articleMappingCollection = articleMappingResponse;
        this.spinner.hide();
        this.loading = false; 
      },
      error: (error: any) => {
        console.log('error ', error);
        this.spinner.hide();
        this.loading = false; 

      }
    });
      
  }

  loadArticleMappingData($event: any) {
    console.log('event ', $event);
    this.articleRequest.first = $event.first;
    this.articleRequest.requestedBy = this.loggedinUserinfo.userId;
    this.loading = true;
    this.getArticleData();
  }

  filterArticleData(){
    this.loading = true;
    this.articleRequest={
      ...this.articleRequest,
      first:0,
      requestedBy:this.loggedinUserinfo.userId
    }
    this.getArticleData();
  }

  retrieveMFGMaster() {

    this.cleaningCategoryModel.retrieveCleaningCategoryCollection(this.loggedinUserinfo.userId).subscribe(CleaningCategoryresponse => {
      let resultCollection:CleaningCategoryFormData[]=CleaningCategoryresponse;
      this.cleaningCategory=resultCollection;
      resultCollection.forEach((element:any)=> {
        if(element.is_active){
          let result:DropDownMaster=new DropDownMaster();
          result.label=`${element.mfg_code}, ${element.mfg_description}`
          result.value=element.mfg_code;
          this.mfgOpts.push(result);   
        }
      });
    });
  }
} 
