import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppServiceService } from 'src/app/services/app-service.service';

@Component({
  selector: 'app-liquid-request-form-common',
  templateUrl: './liquid-request-form-common.component.html',
  styleUrls: ['./liquid-request-form-common.component.css']
})
export class LiquidRequestFormCommonComponent implements OnInit ,OnDestroy {

  private subscriptionName: Subscription; //important to create a subscription
  messageReceived: any;
  region !: string;


  
  constructor(private _appServ: AppServiceService) { 
     this.subscriptionName= this._appServ.getUpdate().subscribe(message => { //message contains the data sent from service
    this.messageReceived = message;
    this.region=sessionStorage['selectedRegion'];
    

    });
  }
  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    this.subscriptionName.unsubscribe();
}
  ngOnInit() {
    console.log('')
  }

}
