export class ArticleMappingData {
    article_id: number = 0;
    article_number: string = "";
    product_name: string = "";
    mfg_code: string = "";
    mfg_description: string = "";
    is_active: boolean = true;
    modified_by: number = 0;
    mfg_codes: string[] = [];
    mapped_mfg: MappedMFGData[] = [];
    totalRecords: number = 0;
}
export class MappedMFGData{
    mfg_code: string = "";
    mfg_description: string = "";
}