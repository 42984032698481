import { VendorManagementData } from "./vendor-management.data";

export class VendorManagementFormData extends VendorManagementData
{

    edit:boolean=true;
    editing:boolean=false;
    status:string="";

    validVendorName:boolean=true;
    validVendorEmail:boolean=true;
    validVendorTransportType:boolean=true;
    validRegion:boolean=true;


}