import { TruckLoadRequestFormData } from "./truck-load-request-form.data";

export class TruckLoadRequestDetails extends TruckLoadRequestFormData {

    annualVolumeUnit: string="";
    equipmentType: string="";
    monthlyVolumeUnit: string="";
    reasonForSelection: string=""; 

    rateReqCalData:any="";
    rateReqCalFileName:string="";
    rateReqCalFileExtn:string="";
    
    requestType: string="";
    transportTypeName: string="";

}