import { CleaningCategoryData } from "./cleaning-category.data";

export class CleaningCategoryFormData extends CleaningCategoryData
{
    edit:boolean=true;
    editing:boolean=false;
    status:string="";
    
    validFreightClass:boolean=true;
    validCCFee:boolean=true;
    validCCCode:boolean=true;
    validMFGCode:boolean=true;
    validMFGDesc:boolean=true;
}