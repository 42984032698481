import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { RailCarTypeFormData } from "./data/railcar-type-form.data";
import { RailCarTypeServices } from "./railcar-type.service";

@Injectable()

export class RailCarTypeModel {
    constructor(private client: RailCarTypeServices) { }
    retrieveRailCartTypeStatus() {
        return [
            { label: "Active", value: true },
            { label: "InActive", value: false }
        ];
        
    }

    retrieveRailCarTypeColumn() {
        return [
            { field: "rail_type_id", header: "Id", width: "10%" },
            { field: "rail_type_name", header: "Rail Type Name", width: "65%" },
            { field: "status", header: "Status", width: "20%" },
            { field: "edit", header: "", width: "5%" }
        ];
    }

    retrieveRailCarTypeCollection(requestedBy: number): Observable<RailCarTypeFormData[]> {
        let subject = new Subject<RailCarTypeFormData[]>();
        let resultCollection: RailCarTypeFormData[] = [];
        this.client.retrieveRailtypeList(requestedBy).subscribe({
            next:(railtypeListResponse:any) => {
            let apiResponse: any = railtypeListResponse;
            let apiResult: any = apiResponse.result;
            if (apiResult.status_id == 200) {
                let apiResultData: any = apiResponse.data;
                apiResultData.forEach((element:any) => {
                    let resultdata = element as RailCarTypeFormData;
                    resultdata.edit = true;
                    resultdata.status = resultdata.is_active ? "Active" : "Inactive";
                    resultCollection.push(resultdata);

                });
                subject.next(resultCollection);
            }
            else {
                subject.error(apiResult.message)
            }
        },
            error:(error:any) => {
                subject.error(error);
            }
    });
        return subject.asObservable();
    }


    validateRailCarTypeForm(data: RailCarTypeFormData): RailCarTypeFormData {
        data.validateRailTypename = this.validInput(data.rail_type_name);
        return data;
    }
    onSaveRailCarType(data: RailCarTypeFormData, userId: number): Observable<string> {
        let subject = new Subject<string>();
        let railCarTypeRequest = {
            "rail_type_id": data.rail_type_id,
            "rail_type_name": data.rail_type_name,
            "is_active": data.is_active,
            "modified_by": userId
        };

        this.client.submitRailCarType(railCarTypeRequest).subscribe({
            next:(saveRailCarTypeResponse:any) => {
            this.processSaveResponse(saveRailCarTypeResponse, subject);
        }, 
        error:(error:any) => {
            subject.error(error)
        }
    });


        return subject.asObservable();
    }


    private processSaveResponse(updatevendorResponse: any, subject: Subject<string>) {
        let apiResponse: any = updatevendorResponse;
        let apiResult: any = apiResponse.result;
        if (apiResult.status_id == 200) {
            subject.next(apiResult.message);
        }
        else {
            subject.error(apiResult.message);
        }
    }
    validInput(validStr:any) {
        return validStr.length > 1;
    }
}