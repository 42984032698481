import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { RequestVendorResponseForm } from "./data/request-vendor-response-form.data";
import { VendorResponseData } from "./data/vendor-response.data";
import { VendorResponseModel } from "./vendor-response.model";
import {VendorResponseServices } from "./vendor-response.services";
import { VendorApiSubmit } from "./data/vendor-api-submit.data";
import { PackageDrayageVendorResponseData } from "./data/package-drayage-vendor-response.data";

@Injectable()
export class PackageDrayageVendorResponseModel extends VendorResponseModel{

  constructor( service: VendorResponseServices) {
    super(service)
    console.log("constructor")

  }

  
   onretrieveVendorResponseSuccess(apiResponse: any, subject: Subject<VendorResponseData[]>) {
    let result: VendorResponseData[] = [];
    let reapdata: any = apiResponse.data;
    reapdata.forEach((element:any) => {
        result.push(element as PackageDrayageVendorResponseData);
    });

    subject.next(result);
}


  submitvendorResponse(vendorApiSubmitData:VendorApiSubmit,rateReqCalData:string,rateReqCalFileName:string,rateReqCalFileExtn:string): Observable<string> {

    let apiRequest: RequestVendorResponseForm =new RequestVendorResponseForm();
    apiRequest = this.onBindRequestVendorResponse(apiRequest,vendorApiSubmitData,rateReqCalData,rateReqCalFileName,rateReqCalFileExtn);
    return this.onSubmitVendorResponse(apiRequest);
  }
 

  notifyVendorResponse(vendorApiSubmitData:VendorApiSubmit): Observable<string> {
    let apiRequest: RequestVendorResponseForm =new RequestVendorResponseForm();
    apiRequest = this.onBindRequestVendorResponse(apiRequest,vendorApiSubmitData,"","","");
    return this.onNotifyVendorResponse(apiRequest);
  }
  
  submitBidByVendor(vendorApiSubmitData:VendorApiSubmit): Observable<string> {
    let apiRequest: RequestVendorResponseForm =new RequestVendorResponseForm();
    apiRequest = this.onBindRequestVendorResponse(apiRequest,vendorApiSubmitData,"","","");
    return this.onSubmitBidByVendor(apiRequest);
  }

  validateRank(ranks: any, vendorsData: any) {
    let rankSelected:boolean=true;
    vendorsData.forEach((v:any)=>{      
      v.validRank=true;
      if(v.isSelected && v.rankAssigned==0){
        rankSelected=false;
        v.validRank=false;
      }
    })
    
    if(rankSelected){
      ranks.forEach((r:any) => {
        let count = 0;
        vendorsData.forEach((v:any) => {
            if (v.rankAssigned == r.value) {
                if (count > 0) {
                    v.validRank = false;
                }
                count++;
            }
        });
     });
    }
    
    return vendorsData;
  }
}