import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { DeliveryTypeFormData } from "./data/delivery-type-form.data";
import { DeliveryTypeService } from "./delivery-type.service";

@Injectable()


export class DeliveryTypeModel
{
    constructor(private client: DeliveryTypeService) { }
    retrieveListHeader() {

        return [
             { field : "delivery_type_id", header:"Id", width: "10%"},
            { field: "delivery_type_name", header: "Delivery Type", width: "20%" },
            { field: "status", header: "Status", width: "20%" },
            { field: "transport_type", header: "Transport Type", width: "45%"},
            { field: "edit", header: "", width: "5%" }
        ];
    }

    retrieveDeliveryTypeCollection(requestedBy: number): Observable<DeliveryTypeFormData[]> {
        let subject = new Subject<DeliveryTypeFormData[]>();
        let resultCollection: DeliveryTypeFormData[] = [];
        this.client.retrieveDeliveryTypeList(requestedBy).subscribe({
            next:tempRequirementollectionResult => {
            let apiResponse: any = tempRequirementollectionResult;
            let apiResult: any = apiResponse.result;
            if (apiResult.status_id == 200) {
                let apiResultData: any = apiResponse.data;
                apiResultData.forEach((element:any) => {
                    let resultdata = element as DeliveryTypeFormData;
                    resultdata.edit = true;
                    resultdata.status = resultdata.is_active ? "Active" : "Inactive";
                    resultCollection.push(resultdata);

                });
                subject.next(resultCollection);
            }
            else {
                subject.error(apiResult.message)
            }
        },
        error:(error) => {
            subject.error(error);
        }});
    return subject.asObservable();
    }

    retrieveDeliveryTypeStatus() {
        return [
            { label: "Active", value: true },
            { label: "InActive", value: false }
        ];
    }

    validateDeliveryTypeForm(data: DeliveryTypeFormData, selectedTransportType: any): DeliveryTypeFormData {
         data.delivery_type_name = data.delivery_type_name.trim();
         data.validateDeliveryType = this.validInput(data.delivery_type_name) && this.validateTextInput(data.delivery_type_name);
         data.validTransportType = selectedTransportType.length > 0;
         return data;
    }


    OnsaveDeliveryType(data: DeliveryTypeFormData, selectedTransportType: any, userId: number): Observable<string> {

        let subject = new Subject<string>();

        let updateTransportType: any[] = [];
        this.processSelectedTransportType(selectedTransportType, updateTransportType);

        let temprequirementData = {
            modified_by: userId,
            delivery_type_id: data.delivery_type_id,
            delivery_type_name: data.delivery_type_name,
           
            is_active: data.is_active,
            transport_type_master: updateTransportType
        }


       
            this.client.submitDeliveyType(temprequirementData).subscribe({
            next:createVendorResponse => {
                this.processSaveResponse(createVendorResponse, subject);
            }
            ,
            error:(error) => {
                subject.error(error)

            }});
        
        return subject.asObservable();
    }


    private processSaveResponse(updatevendorResponse: any, subject: Subject<string>) {
        let apiResponse: any = updatevendorResponse;
        let apiResult: any = apiResponse.result;
        if (apiResult.status_id == 200) {
            subject.next(apiResult.message);
        }
        else {
            subject.error(apiResult.message);
        }
    }


    private processSelectedTransportType(selectedTransportType: any, updateTransportType: any[]) {
        selectedTransportType.forEach((element:any) => {
            let dropdownData = {
                masterId: element,
                masterValue: "",
                masterOrderBy: 0
            };

            updateTransportType.push(dropdownData);
        });
    }

    validInput(validStr:any) {
        return validStr.length > 1;
    }
    ValidateEmail(validStr:any) {
        let emailReg = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/;
        return emailReg.test(validStr);
    }
    validateUserName(validStr:any) {
        let unRegex = /^[a-zA-Z0-9]+$/;
        return unRegex.test(validStr);
    }

    validateTextInput(validStr:any) {
        let inputText = /^[a-zA-Z\s]+$/;
        return inputText.test(validStr);
    }

    validateCodes(validStr:any) {
        let codeRegex = /^[a-zA-Z0-9]+$/;
        return codeRegex.test(validStr);
    }
}
