import { DropDownMaster } from "./dropdown-master.data";

export class VendorDropDownMaster extends DropDownMaster
{
    email!:string;
}

export class VendorUserDropdownMaster
{
    Id!:number;
    Name!:string;
    VendorId!:number
}

export class CurrencyDropDownMaster extends DropDownMaster{
    country!:string;
}
