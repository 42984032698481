export class DropDownMaster
{
    value:any;
    label!:string;
    

}
export class DropDownLanguageMaster
{
    LanguageId:any;
    LanguageName!: string;
    RegionId:any;
    

}
export class DropDownRegionMaster
{
    value:any;
    label!:string;
    name:any;
    

}