<div *ngIf="region=='NA'" id="rateForm">
  <app-dry-request-na-form></app-dry-request-na-form>
</div>
<div *ngIf="region=='SA'" id="rateForm">
  <app-dry-request-sa-form></app-dry-request-sa-form>
</div>
<div *ngIf="region=='EMEA'" id="rateForm">
  <app-dry-request-emea-form></app-dry-request-emea-form>
</div>
<div *ngIf="region=='AP'" id="rateForm">
  <app-dry-request-ap-form></app-dry-request-ap-form>
</div>
