import { Injectable } from  '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PackageContainerBoxDrayageFromData } from './data/formdata/package-container-box-drayage-form.data';
import { PackageContainerBoxDrayageDetails } from './data/formdata/package-container-box-drayage-request-details.data';
import { RequestFormData} from './data/formdata/request-common.data';
import { RequestCommonListData } from './data/request-list/common-request-list.data';
import { PackageContainerBoxDrayageListData } from './data/request-list/package-container-box-dryage-list.data';
import { RequestFormModel } from "./request-form.model";
import { RequestFormService } from "./request-form.services";
import { RequestListModel } from "./request-list/request-list.model";

@Injectable()


export  class PackagaContainerBoxRequestFormModel extends RequestFormModel implements RequestListModel{
   
    _service:RequestFormService;
    constructor (service : RequestFormService){        
        super(service)
        this. _service=service;

    }
    createRequest(data: PackageContainerBoxDrayageFromData): Observable<string> {
        let subject = new Subject<string>();
        this.insertRequest(data).subscribe({
            next:result => { subject.next(result) },
            error:error => { subject.error(error) }
        }
        );
        return subject.asObservable()
    }
    responseOnSuccessRequestList(apiResponse: any, subject: Subject<RequestCommonListData[]>) {
        let requestCollection: PackageContainerBoxDrayageListData[] = [];
        apiResponse.data.forEach((element:any) => {
           
            requestCollection.push(element as PackageContainerBoxDrayageListData);
        });

        subject.next(requestCollection);
    }
    OnRequestDetailSuccess(apiResponse: any, resolve: (value: RequestFormData | PromiseLike<RequestFormData>) => void) {
        
        let data: PackageContainerBoxDrayageDetails = apiResponse.data as PackageContainerBoxDrayageDetails;
        resolve(data);
    }
    retrieveListHeader() {
        let  requestDetailCols:any = [
            { field: "referenceNumber", header: "ID", width: "5vw" },
        // { field: "transportType", header: "BULK_TYPE", width: "6vw" },
        { field: "requestType", header: "REQUEST_TYPE", width: "8vw" },
        { field: "requestor", header: "REQUESTOR", width: "7vw" },
        { field: "businessUnit", header: "BU", width: "4vw" },
        { field: "articleNumber", header: "ARTICLE_NUMBER", width: "5vw" },
        { field: "product", header: "PRODUCT", width: "8vw" },
        { field: "orgin", header: "ORIGIN", width: "8vw" },
        { field: "destination", header: "DESTINATION", width: "8vw" },
        { field: "status", header: "STATUS", width: "8vw" },
        { field: "requestedDate", header: "DATE", width: "6vw"}
          ];
          return requestDetailCols;
    }
    
}