export class DataFieldVisiblity {
    //General
    articleNumber: boolean = false;
    articleNumberMandatory:boolean = false;
    articleNumberfreeText: boolean = false;
    bussinessunit: boolean = false;
    cleaninCost: boolean = false;
    equipmentType: boolean = false;
    freightClass: boolean = false;
    interModel: boolean = false;
    mfg: boolean = false;
    mfgMandatory:boolean=false;
    mfgDescription: boolean = false;
    payerOption: boolean = false;

    product: boolean = false;
    railCarType: boolean = false;
    requestor: boolean = false;
    requestType: boolean = false;
    startDate: boolean = false;
    naCountry: boolean = false;
    typeOfShipment :boolean = false;
    monthlyKms :boolean =false;
    //Service provider
    annualVolume: boolean = false;
    centerRear: boolean = false;
    centerRearUnload: boolean = false;
    centerRearOptionIndistinct:boolean=false;
    centerRearUnloadMandatory: boolean=false;

    days: boolean = false;
    dropTrail: boolean = false;
    dropTrailMandatory:boolean =false;
    foodGradeWash: boolean = false;
    groundVapourRecovery: boolean = false;
    hazardous: boolean = false;
    hazardousMandatory: boolean = false;
    hazardousSDSMandatory: boolean = false;

    volumeType:boolean=false
    campaignSeasonalVolume:boolean=false;
    csVolumeTiming:boolean=false;
    cleaningProcedures:boolean=false;
    mandDocsInsideTruck:boolean=false;
    mandDocsInsideTruckDoc:boolean=false;
    mandDocsInsideTruckSa:boolean=false;
    averageProduct:boolean=false;
    pump:boolean=false;
    chassis:boolean=false;
    couplings:boolean=false;
    loadingPoint:boolean=false;
    gmp:boolean=false;
    fami:boolean=false;
    isopa:boolean=false;
    halalWash:boolean=false;
    unloadingHose:boolean=false;
    onboardCompressor:boolean=false;
    others:boolean=false;
    isoTankRentalRequirement:boolean=false;
    isoTankRentalPeriod:boolean=false;
    tankType:boolean=false;
    specificRemarkForTankType:boolean=false;
    topBottomLoad:boolean=false;
    topBottomUnload:boolean=false;
    cleaningService:boolean=false;
    cleaningFrequency:boolean=false;
    heatingService:boolean=false;
    heatingTemperature:boolean=false;
    priorProductRestrictionDetails:boolean=false;
    tariffZone:boolean=false;
    dangerousGoodsClass:boolean=false;
    otherAdditionalServiceRequirement:boolean=false;

    originPlant:boolean=false;
    destinationPlant:boolean=false;
    specificEquipmentType:boolean=false;

    kosher: boolean = false;
    loadingHrs: boolean = false;
    materialTopLoad: boolean = false;
    monthlyVolume: boolean = false;
    numberOfRailCarsRequired: boolean = false;
    nitrogenBlanket: boolean = false;
    priorProductRestriction: boolean = false;
    priorProdDetailsRestrictions : boolean = false;
    seasonal: boolean = false;
    seasonalComments: boolean = false;
    tankerEndorsement: boolean = false;
    tempControl: boolean = false;
    tempRange: boolean = false;
    tempRequirement: boolean = false;
    deliveryType:boolean=false;
    unloadingHrs: boolean = false;
    unNumber: boolean = false;
    vacuumTrailer: boolean = false;
    weekendDelivery: boolean = false;
    weekendDeliveryYN: boolean = false;
    isMaterialPackagedLiquidInTotes: boolean = false;
    hazmatclassId:boolean = false;
    dropContainerId:boolean = false;

    totalWeightShipment:boolean=false;
    isSpecialRequirement:boolean=false;
    specailRequirementAttachment:boolean=false;

    

    //shipping->Orgin
    orginAlternateServingRailRoad: boolean = false;

    orginCity: boolean = false;
    orginCityEMEA: boolean = false;
   orginStreetAddress: boolean=false;

    orginCountry: boolean = false;
    orginCountryEMEA: boolean = false;
    orginRailStation: boolean = false;
    orginServingRailRoad: boolean = false;
    orginSiteCompetitivenessId: boolean = false;
    orginState: boolean = false;
    orginZipCode: boolean = false;
    originZipCodeMandatory: boolean = false;
    shippingName: boolean = false;
    orginLocationType: boolean = false;
    //shipping->destination
    destinationAlternateServingRailRoad: boolean = false;

    consigneeName: boolean = false;
    destinationStreetAddress: boolean=false;
    destinationCity: boolean = false;
    destinationCityEMEA: boolean = false;
    destinationCountry: boolean = false;
    destinationCountryEMEA: boolean = false;
    destinationRailStation: boolean = false;
    destinationServingRailRoad: boolean = false;
    destinationSiteCompetitivenessId: boolean = false;
    destinationState: boolean = false;
    destinationZipCode: boolean = false;
    distinationZipCodeMandatory: boolean = false;
    destinationLocationType: boolean =false;

    multipleDestination : boolean =false;

    //Payer Options
    freightBillAddress: boolean = false;
    payer: boolean = false;
    routing: boolean = false;
    tariffNumber: boolean = false;
    //additional info
    additionalAttachment: boolean = false;
    additionalAttachment2: boolean = false;
    comments: boolean = false;
    costVsService: boolean = false;
    customerPreferedCarrier: boolean = false;
    materialDoc: boolean = false;
    materialLink: boolean = false;
    sdsLink: boolean = false;
    sdsAttachment: boolean = false;
    is_sds_fileUpload_mandatory:boolean=false;

    //Set redonly 
    IsProductReadOnly: boolean = true;
    preferedTransportMode:boolean=false;
    heatingDuringTransport:boolean=false;
    groundOperatingEquipment:boolean=false;
    specialCertification:boolean=false;
    hoseLength:boolean=false;
    driverLanguage:boolean=false;
    requiredCleaningProcedure:boolean=false;
    vacuumTestConfirmation:boolean=false;
    reactorLoading:boolean=false;
    twoStepBusiness:boolean=false;
    onlyOnecompartments:boolean=false;

    mfgDropdown:boolean=false;

   
}