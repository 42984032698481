import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PackageContainerBoxDrayageMasterData } from './data/masterdata/package-container-box-drayage-master.data';
import { RequestMasterData } from './data/masterdata/request-master.data';
import { RequestMasterModel } from './request-master.model';
import { RequestMasterService } from './request-master.service';

@Injectable()

export class PackageContainerBoxDrayageMasterModel extends RequestMasterModel{

    constructor(service: RequestMasterService){
        super(service);
        console.log("constructor")
    }


    public responseOnSuccess(apiResponse: any, subject: Subject<RequestMasterData>) {
        let dataCollection: PackageContainerBoxDrayageMasterData = new PackageContainerBoxDrayageMasterData();
        dataCollection.locationTypeMaster = this.convertResponsetoArray(apiResponse.data.locationTypeMaster,false);
        dataCollection.hazmatclassMaster = this.convertResponsetoArray(apiResponse.data.hazmatclass,false);
        dataCollection = this.processCommonResult(apiResponse,dataCollection) as PackageContainerBoxDrayageMasterData;
        subject.next(dataCollection);
    }

    
}