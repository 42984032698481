import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({providedIn:"root"})

export class VendorResponseServices
{
constructor (private client :HttpClient)
{

}

retrieveVendorResponse(requestId: number, transportType: number,isExternalUser:boolean,userId:number)
{
    let  api:string=environment.getVendorResponseDetails.replace("{0}",String(requestId)).replace("{1}",String(transportType)).replace("{isExternal}",String(isExternalUser)).replace("{userId}",String(userId))

    return this.client.get(api).pipe(map((response) => response));
   
}

submitVerdorResponse(data:any)
{
    return this.client.post(environment.submitVendors, data).pipe(map((response) => response));
}

notifyVendorResponse(data:any){
    return this.client.post(environment.notifyVendors,data).pipe(map((response)=> response));
}

submitBidByVendor(data:any){
    return this.client.post(environment.submitBidVendor,data).pipe(map((response)=> response));
}
}
