import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DropDownMaster } from "src/app/shared/dropdown-master.data";
import { TransportTypeMasterModel } from "src/app/shared/transport-type-master/transport-type-master.model";
import { LoggecInUserInfo } from "src/app/site-permissions/data/loggedin-user-info";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { DeliveryTypeFormData } from "../data/delivery-type-form.data";
import { DeliveryTypeModel } from "../delivery-type.model";


@Component({
    selector: 'app-admin-delivery-type-management',
    templateUrl: './delivery-type.componet.html',
    encapsulation: ViewEncapsulation.None,
    providers: [LoggedInUserInfoModel,DeliveryTypeModel,
      TransportTypeMasterModel
    ]
  })

export class AdminDeliveryTypeComponent implements OnInit
{

    @Input() loggedinUserinfo!: LoggecInUserInfo;

    deliveryTypeData: DeliveryTypeFormData= new DeliveryTypeFormData();
    deliveryTypeCollection:DeliveryTypeFormData[]=[];
    transportTypeMasterData:DropDownMaster[]=[];
    selectedTransportType:any;
    
  statusOpts:any;

  deliveryTypeColumns:any;
    constructor(private deliveryTypeModel:DeliveryTypeModel,
      private transportTypeMasterModel:TransportTypeMasterModel,
      private spinner: NgxSpinnerService,
    private toast: ToastrService )
    {

    }
    ngOnInit(): void {
    
      this.retrieveTransportType();
      this.retrieveListHeader();
      this.retrieveDeliveryTypeCollection();
this.retrieveDeliveryTypeStatus();

    }
retrieveDeliveryTypeStatus()
  {
   this. statusOpts=this.deliveryTypeModel.retrieveDeliveryTypeStatus();
  }
 retrieveListHeader()
 {
  this.deliveryTypeColumns=this.deliveryTypeModel.retrieveListHeader();
  const screenWidth = window.innerWidth;
  if(screenWidth < 750) {
    this.deliveryTypeColumns.forEach((col:any) => {
        col.width = (parseInt(col.width.replace('%','')) * 10).toString() + 'px';
    });
  }
 }

 retrieveDeliveryTypeCollection ()
 {
   this.deliveryTypeModel.retrieveDeliveryTypeCollection(this.loggedinUserinfo.userId).subscribe({
    next: (deliveryTypeCollectionResponse: any) => {
      this.deliveryTypeCollection=deliveryTypeCollectionResponse;
    },
    error: (error: any) => {
      console.log('error ', error);
     
    },
  });
 }
    retrieveTransportType()
    {
      this.transportTypeMasterModel.RetrieveTransportTypeMaster().subscribe({
        next: (transportTypeResponse:any)=>{
          this. transportTypeMasterData=transportTypeResponse;
        },
        error:(error:any)=>{
          console.error(error);
        }
      })
      

    }


    onRowDeliveryTypeEditInit(rowData:any) {
      this.deliveryTypeData.editing = true;
      this.deliveryTypeData.delivery_type_id = rowData.delivery_type_id;
      this.deliveryTypeData.delivery_type_name = rowData.delivery_type_name;
      this.deliveryTypeData.is_active = rowData.is_active;
      let data:any = rowData.transport_type_master;
      this.selectedTransportType=[];
      data.forEach((element:any)=>
        {
          let resultdata=new DropDownMaster();
          resultdata.value=Number(element.masterId);
        
          resultdata.label=element.masterValue;
          this.selectedTransportType.push(resultdata.value);
        }) 
       
    }

    onCancel() {

      this.deliveryTypeData= new DeliveryTypeFormData();
      this.selectedTransportType=null;
      this.selectedTransportType=[];
    }

    saveDeliveryType() {
      this.spinner.show();      
      this.deliveryTypeData=    this.deliveryTypeModel.validateDeliveryTypeForm(this.deliveryTypeData,this.selectedTransportType)
    if(!(this.deliveryTypeData.validTransportType && this.deliveryTypeData.validateDeliveryType))
    { 
        this.spinner.hide();
        return false;
      }
      else{
       
  
      this.deliveryTypeModel.OnsaveDeliveryType(this.deliveryTypeData,this.selectedTransportType,this.loggedinUserinfo.userId).subscribe({
next:(saveResponse:any)=>{
  this.toast.success(saveResponse, "Success");
  this.retrieveDeliveryTypeCollection();
  this.onCancel();
  this.spinner.hide();
 
},
error: (error:any)=> {
  this.toast.error(error, "Error!");
          this.spinner.hide();
          return false;
}, 
      })
      return true;
    }
   
  
    
    }
} 
