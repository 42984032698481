import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import { BehaviorSubject, Observable, Subject } from 'rxjs'; 

@Injectable({
  providedIn: 'root'
})
export class AppServiceService {
  public userName = "";
  public userDept = "";
  public userDetails: any;
  public appAccess: boolean = false;
  public homeAccess: boolean = true;
  public lpAccess: boolean = false;
  public requestAccess: boolean = false;
  public adminAccess: boolean = false;
  public vendorAccess: boolean = false;
  public isvendorUser: boolean = false;
  private userSubject = new Subject<any>();

  constructor(private _http: HttpClient) { }
  private _user: BehaviorSubject<any> = new BehaviorSubject(null);

  public setuser(value: any) {
    this._user.next(value);
  }
  public getuser(): Observable<any> {
    return this._user.asObservable();
  }
  userData = new BehaviorSubject({
    
    user_id: 0,
      user_name: "",
      first_name:"",
      last_name:"",
      email: "",
      department:"",
      is_admin:false,
      isExternalUser:false,
      is_active:false
  });
  sendUserData(value: any) {
    this.userData.next(value);
  }
  getUserData(): Observable<any> {
    return this.userData.asObservable();
  }

  getUserName() {
    return this.userName;
  }
  getUserDepartment() {
    return this.userDept;
  }
  getUserDetails() {
    return this.userDetails;
  }
  setUserDetails(userData: any) {
    this.userDetails = userData;
  }
  getAccess() {
    return  {
      "appAccess": this.appAccess,
      "homeAccess": this.homeAccess,
      "lpAccess": this.lpAccess,
      "requestAccess": this.requestAccess,
      "adminAccess": this.adminAccess,
      "vendorAccess": this.vendorAccess,
      "isvendorUser":this.isvendorUser
    };
   
  }
  setAccess(data: any) {
    this.appAccess = data.appAccess;
    this.homeAccess = data.homeAccess;
    this.lpAccess = data.lpAccess;
    this.requestAccess = data.requestAccess;
    this.adminAccess = data.adminAccess;
    this.vendorAccess = data.vendorAccess;
    this.isvendorUser=data.isvendorUser
  }
  sendUserAccessValues(data: any) {
    this.appAccess = data.appAccess;
    this.homeAccess = data.homeAccess;
    this.lpAccess = data.lpAccess;
    this.requestAccess = data.requestAccess;
    this.adminAccess = data.adminAccess;
    this.vendorAccess = data.vendorAccess;
    this.isvendorUser=data.isvendorUser;
    let accessObj = {
      "appAccess": this.appAccess,
      "homeAccess": this.homeAccess,
      "lpAccess": this.lpAccess,
      "requestAccess": this.requestAccess,
      "adminAccess": this.adminAccess,
      "vendorAccess": this.vendorAccess,
      'isvendorUser':this.isvendorUser
    };
    this.userSubject.next(accessObj);
  }

  getUserAccessValues(): Observable<any> {
    return this.userSubject.asObservable();
  }
  getUsers() {
    return this._http.get(environment.getUsers).pipe(map((response) => response));
  }

  getVendors() {
    return this._http.get(environment.getVendors).pipe(map((response) => response));
  }

  getCleaningCategoryData() {
    return this._http.get(environment.getCleaningCategories).pipe(map((response) => response));
  }

  getDropDownData() {
    return this._http.get(environment.getDropDownData).pipe(map((response) => response));
  }

  getRegionsData(data: any,isadmin: boolean,type:string) {
    return this._http.get(environment.getRegionsData +`${data}`+'/'+`${isadmin}`+'/'+`${type}`).pipe(map((response) => response));
  }

  getModesbasedonRegion(data: any,regionid: number) {
    return this._http.get(environment.getModesbasedonRegion +`${data}`+'/'+`${regionid}`).pipe(map((response) => response));
  }
  getLanguagesbasedonRegion(data: any,regionid: number) {
    return this._http.get(environment.getLanguagesbasedonRegion +`${data}`+'/'+`${regionid}`).pipe(map((response) => response));
  }

  getRoles() {
    return this._http.get(environment.getRoles).pipe(map((response) => response));
  }

  saveUsers(userInfo: any) {
    return this._http.post(environment.postUser, userInfo).pipe(map((response) => response));
  }

  createVendor(vendorInfo: any) {
    return this._http.post(environment.addVendor, vendorInfo).pipe(map((response) => response));
  }

  saveVendors(vendorInfo: any) {
    return this._http.post(environment.updateVendor, vendorInfo).pipe(map((response) => response));
  }

  addCleaningCategory(ccInfo: any) {
    return this._http.post(environment.addCleaningCategory, ccInfo).pipe(map((response) => response));
  }

  updateCleaningCategory(ccInfo: any) {
    return this._http.post(environment.updateCleaningCategory, ccInfo).pipe(map((response) => response));
  }

  submitBRRForm(formData: any) {
    return this._http.post(environment.postBRRForm, formData).pipe(map((response) => response));
  }

  updateBRRForm(formData: any) {
    return this._http.post(environment.updateBRRForm, formData).pipe(map((response) => response));
  }

  sendEmail(data: any) {
    return this._http.post(environment.emailExcel,data).pipe(map((response) => response));
  }
  deleteUser(userInfo: any) {
console.log("");
  }
  getRequests() {
    return this._http.get(environment.getRequests).pipe(map((response) => response));
  }
  getRequestInfo(data: any) {
    return this._http.get(environment.getRequestInfo +`${data}`).pipe(map((response) => response));
  }

  submitVendors(data: any) {
    return this._http.post(environment.submitVendors, data).pipe(map((response) => response));
  }
  loginQA(reqData?: any) {
    return this._http.post(environment.login_qa, JSON.stringify(reqData))
      .pipe(map((response) => response));
  }
  loginDev(reqData?: any) {
    return this._http.post(environment.login_dev, JSON.stringify(reqData))
      .pipe(map((response) => response));
  }
  login(reqData?: any) {
    return this._http.post(environment.login, JSON.stringify(reqData))
      .pipe(map((response) => response));
  }

  
  getSAMLService() {
    console.debug(environment.getSAMLURL);
    return this._http.get(environment.getSAMLURL).pipe(map((response) => response));
  }
  getSAMLExternalURL() {
    console.debug(environment.getSAMLURL);
    return this._http.get(environment.getSAMLExternalURL).pipe(map((response) => response));
  }
  getLoggedUserData(data: any) {
    return this._http.get(environment.getLoggedUserData +`/${data}`).pipe(map((response) => response));
  }
  getLoggedUserDatabyId(data: any,isExternalUser:any) {
    return this._http.get(environment.getLoggedUserDatabyId +`/${data}`+'/'+`${isExternalUser}` ).pipe(map((response) => response));
  }

  getArticlesList() {
    return this._http.get(environment.getArticleList).pipe(map((response) => response));
  }
  getArticleDetails(data: any) {
    return this._http.get(environment.getArticleDetails +`${data}`).pipe(map((response) => response));
  }
  getArticlesProductList() {
    return this._http.get(environment.getArticleProductList).pipe(map((response) => response));
  }
  submitArticleMapping(data: any) {
    return this._http.post(environment.submitArticleProuctList, data).pipe(map((response) => response));
  }

  getCountriesList() {
    return this._http.get(environment.getCountries).pipe(map((response) => response));
  }

  getUOMParams(data:any) {
    return this._http.get(environment.getUOMParams +`${data}`).pipe(map((response) => response));
  }

  getCurrencyType() {
    return this._http.get(environment.vendorCurrency).pipe(map((response) => response));
  }

  getDecryptedUserDetails(data: any) {
    return this._http.post(environment.decryptUserDetails, data).pipe(map((response) => response));
  }

  getQAUserDetails(data: any) {
    return this._http.post(environment.getQAUserDetails, data).pipe(map((response) => response));
  }

  
  getSiteCompetitveList(data: any){
      return this._http.get(environment.getSiteComptitiveList+`/${data}`).pipe(map((response)=>response));
  } 
  submitSiteCompetitve(data: any) {
    return this._http.post(environment.submitSiteComptivelist, data).pipe(map((response) => response));
  }

  getRailtypeList(data: any){
    return this._http.get(environment.getRailCarTypeList+`/${data}`).pipe(map((response)=>response));
  } 
  submitRailCarType(data: any) {
    return this._http.post(environment.submitRailCartype, data).pipe(map((response) => response));
  }

  private subjectName = new BehaviorSubject({}); //need to create a subject
    
  sendUpdate(message: any,language:any,region:any) { //the component that wants to update something, calls this fn
      this.subjectName.next({ text: message,languagedd:language, regiondd:region }); //next() will feed the value in Subject
  }

  getUpdate(): Observable<any> { //the receiver component calls this function 
      return this.subjectName.asObservable(); //it returns as an observable to which the receiver funtion will subscribe
  }
}
