import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppServiceService } from 'src/app/services/app-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  bulkTMSUser: any;
  encbulkTMSUser: any;
  bulkTMSUserparam: any;
  bulkTMSUserIdparam: any;
  bulkTMSUserisExternalparam: any;
  department: any;
  apiResp: any;
  firstName: any;
  lastName: any;
  email: any;
  roleId:any;
  encrypteDept : any;
  userNotFound = false;
  
  constructor(private appServ: AppServiceService, 
    private spinner: NgxSpinnerService, private toast: ToastrService,private translate:TranslateService, 
    private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.spinner.show();
    this.bulkTMSUser = sessionStorage.getItem('bulkTMSUser');
    this.bulkTMSUserparam= sessionStorage.getItem('bulkTMSUserparam');
    this.readQueryStringValues();
    this.checkIsUserAlreadyLoggedin();
    this.spinner.hide();
  }

  private readQueryStringValues() {
    this.route.queryParams.subscribe(params => {
      this.extractloggedinUser(params);

      this.retreiveUserInfoFromQueryString(params);
     
      if ((this.bulkTMSUser == "undefined" || this.bulkTMSUser == undefined || this.bulkTMSUser == null || this.bulkTMSUser == "null" || this.bulkTMSUser == "") && (this.bulkTMSUserparam == "undefined" || this.bulkTMSUserparam == undefined || this.bulkTMSUserparam == null) &&
        (this.department == "undefined" || this.department == undefined || this.department == null || this.department == "null" || this.department == "") &&
        (this.firstName == "undefined" || this.firstName == undefined || this.firstName == null || this.firstName == "null" || this.firstName == "") &&
        (this.lastName == "undefined" || this.lastName == undefined || this.lastName == null || this.lastName == "null" || this.lastName == "") &&
        (this.email == "undefined" || this.email == undefined || this.email == null || this.email == "null" || this.email == "")) {

        console.log('');
      }
      else {
        let reqData = {
          username: this.bulkTMSUser,
          department: this.department,
          firstname: this.firstName,
          lastname: this.lastName,
          email: this.email
        };
        this.retrieveDecryptedUserInfo(reqData);
      }
    });
  }

  private extractloggedinUser(params:any) {
    if (this.bulkTMSUserparam == null || this.bulkTMSUserparam == undefined || this.bulkTMSUser == null || this.bulkTMSUser == "undefined" || this.bulkTMSUser == "" || this.bulkTMSUser == " ") {
      this.bulkTMSUserparam = params.username;

    }
    if (this.bulkTMSUserparam == null || this.bulkTMSUserparam == undefined || this.bulkTMSUser == null || this.bulkTMSUser == "undefined" || this.bulkTMSUser == "" || this.bulkTMSUser == " ") {
       this.bulkTMSUserparam = params.uid;
     }

    if (this.bulkTMSUserIdparam == null || this.bulkTMSUserIdparam == undefined  || this.bulkTMSUserIdparam == "undefined" || this.bulkTMSUserIdparam == "" || this.bulkTMSUserIdparam == " ") {
      this.bulkTMSUserIdparam = params.userId;

    }
    if (this.bulkTMSUserisExternalparam == null || this.bulkTMSUserisExternalparam == undefined || this.bulkTMSUserisExternalparam == "undefined" || this.bulkTMSUserisExternalparam == "" || this.bulkTMSUserisExternalparam == " ") {
      this.bulkTMSUserisExternalparam = params.isExternalUser;

    }
    sessionStorage.setItem('bulkTMSUserparam', this.bulkTMSUserparam);
  }

  private retrieveDecryptedUserInfo(reqData: { username: any; department: any; firstname: any; lastname: any; email: any; }) {
    this.appServ.getDecryptedUserDetails(reqData).subscribe(response => {
      let apiResp: any = response;
      this.department = apiResp.result.department;
      sessionStorage.setItem('firstName', apiResp.result.firstname);
      sessionStorage.setItem('lastName', apiResp.result.lastname);
      sessionStorage.setItem('email', apiResp.result.email);
      sessionStorage.setItem('BU', this.department);
      sessionStorage.setItem('bulkTMSUser', apiResp.result.username);

      this.getLoggedUserDatabyId();
    });
  }

  private retreiveUserInfoFromQueryString(params:any) {
    if (this.bulkTMSUser == "undefined" || this.bulkTMSUser == undefined || this.bulkTMSUser == null || this.bulkTMSUser == "null" || this.bulkTMSUser == ""
    ||this.email == "undefined" || this.email == undefined || this.email == null || this.email == "null" || this.email == "" ) { //|| this.bulkTMSUser != sessionStorage.getItem('bulkTMSUser') ) {
      if (this.bulkTMSUserparam != "undefined" && this.bulkTMSUserparam != undefined && this.bulkTMSUserparam != null && this.bulkTMSUserparam != "null" && this.bulkTMSUserparam != "") {
        sessionStorage.setItem('bulkTMSUser', this.bulkTMSUserparam);
        this.department = params['department'];
        this.encrypteDept = params['department'];
        sessionStorage.setItem('BU', this.department);
        sessionStorage.setItem('isVendorUser', params['isExternalUser'].toLowerCase());
        sessionStorage.setItem('userId', params['userId']);
        this.firstName = params['firstname'];
        this.lastName = params['lastname'];
        this.email = params['email'];
        this.bulkTMSUser = sessionStorage.getItem('bulkTMSUser');
      }
    }
  }

  private getLoggedUserData() {
    this.appServ.getLoggedUserData(this.bulkTMSUserparam).subscribe({
      next:getLoggedUserDataResponse => {
      this.appServ.userName = sessionStorage['bulkTMSUser'];
      let apiGetLoggedUserDataResp: any = getLoggedUserDataResponse;
      if (apiGetLoggedUserDataResp.lstuser.length != 0) {
        this.appServ.setUserDetails(apiGetLoggedUserDataResp);
        let accessObj = {
          "appAccess": true,
          "lpAccess": sessionStorage['isVendorUser']=='true'? false:apiGetLoggedUserDataResp.lstroledata[0].enableAccess,
          "homeAccess": true,
          "requestAccess": apiGetLoggedUserDataResp.lstroledata[1].enableAccess,
          "adminAccess": apiGetLoggedUserDataResp.lstroledata[2].enableAccess,
          "vendorAccess": apiGetLoggedUserDataResp.lstroledata[3].enableAccess,
          "isvendorUser":(sessionStorage['isVendorUser']=='true')

        };
        this.appServ.setAccess(accessObj);
        this.appServ.sendUserAccessValues(accessObj);

        setUserAccessInSession(apiGetLoggedUserDataResp);
        this.bulkTMSUser = sessionStorage.getItem('bulkTMSUser');
        this.spinner.hide();
        let requestPath = sessionStorage.getItem('returnUrlpath');
        
        if(requestPath != null && requestPath != undefined && requestPath != ""){
        console.log(requestPath,"checking the return url-- request path",JSON.parse(requestPath),"--","/home")
          if(requestPath=='/home' || requestPath=='home' || JSON.parse(requestPath)=='/home' || JSON.parse(requestPath)=='home'){
            console.log("inside /home")
            this.router.navigate(['/landingpage']);
          }
          else{
            this.router.navigateByUrl(JSON.parse(requestPath));
          }
        }
        else{
          this.router.navigate(['/landingpage']);
        }

      } else {
        let loggedInUserData = {
          username: this.bulkTMSUser,
          department: this.encrypteDept,
          firstname: this.firstName,
          lastname: this.lastName,
          email: this.email
        };
        this.getDecryptedUserDetails(loggedInUserData);
      }
    }, 
    error:(error) => {
      this.toast.error(this.translate.instant(error), "User Not Found!");
      this.userNotFound = true;
      this.spinner.hide();
    }
    });
  }
  private getLoggedUserDatabyId() {
    this.appServ.getLoggedUserDatabyId(this.bulkTMSUserIdparam,this.bulkTMSUserisExternalparam).subscribe(
      {next:getLoggedUserDataResponse => {
      this.appServ.userName = JSON.stringify(sessionStorage.getItem('bulkTMSUser'));
      let apiGetLoggedUserDataResp: any = getLoggedUserDataResponse;
      if (apiGetLoggedUserDataResp.lstuser.length != 0) {
        
this.setuseraccess(apiGetLoggedUserDataResp);
      }
      else if (apiGetLoggedUserDataResp.lstuser.length == 0 && this.bulkTMSUserIdparam==0 ){
        apiGetLoggedUserDataResp.lstuser =[{user_id: 0,
          user_name: sessionStorage['bulkTMSUserparam'],
          first_name:sessionStorage['firstName'],
          last_name:sessionStorage['lastName'],
          email: sessionStorage['email'],
          department:this.department,
          is_admin:false,
          isExternalUser:false,
          is_active:true,
          role_id:1
        }]
this.setuseraccess(apiGetLoggedUserDataResp)
      } else {
        let loggedInUserData = {
          username: this.bulkTMSUser,
          department: this.encrypteDept,
          firstname: this.firstName,
          lastname: this.lastName,
          email: this.email
        };
        this.getDecryptedUserDetails(loggedInUserData);
      }
    }, 
    error:(error) => {
      this.toast.error(this.translate.instant(error), "User Not Found!");
      this.userNotFound = true;
      this.spinner.hide();
    }
    });
  }

  private setuseraccess(apiGetLoggedUserDataResp :any){
    this.appServ.setUserDetails(apiGetLoggedUserDataResp);       
        sessionStorage.setItem('userloggedin', window.btoa('true'));

        let accessObj = {
          "appAccess": true,
          "homeAccess": true,
           "lpAccess": apiGetLoggedUserDataResp.lstroledata.find((i:any)=>i.permissionComponent=='LPAccess').enableAccess,
          "requestAccess": apiGetLoggedUserDataResp.lstroledata.find((i:any)=>i.permissionComponent=='Request').enableAccess,
          "adminAccess": apiGetLoggedUserDataResp.lstroledata.find((i:any)=>i.permissionComponent=='Admin').enableAccess,
          "vendorAccess": apiGetLoggedUserDataResp.lstroledata.find((i:any)=>i.permissionComponent=='Vendor Response').enableAccess,
          "isvendorUser":apiGetLoggedUserDataResp.lstroledata.find((i:any)=>i.permissionComponent=='VAccess').enableAccess

        };
        this.appServ.setAccess(accessObj);
        this.appServ.sendUserAccessValues(accessObj);

        setUserAccessInSession(apiGetLoggedUserDataResp);
        this.bulkTMSUser = sessionStorage.getItem('bulkTMSUser');
        this.spinner.hide();
        let requestPath = sessionStorage.getItem('returnUrlpath');
        if (requestPath != null && requestPath != undefined && requestPath != "") {
          console.log(requestPath,"checking the return url-- request path",JSON.parse(requestPath),"--","/home")
          if(requestPath=='/home' || requestPath=='home' || JSON.parse(requestPath)=='/home' || JSON.parse(requestPath)=='home'){
            console.log("inside /home")
            this.router.navigate(['/landingpage']);
          }
          else{
            this.router.navigateByUrl(JSON.parse(requestPath));
          }
        }
        else{
          this.router.navigate(['/landingpage']);
        }
  }
  private getDecryptedUserDetails(loggedInUserData: { username: any; department: any; firstname: any; lastname: any; email: any; }) {
    this.appServ.getDecryptedUserDetails(loggedInUserData).subscribe(getDecryptedUserDetailsResponse => {
      let accessObj = {
        "appAccess": true,
        "homeAccess": true,
        "lpAccess": true,
        "requestAccess": true,
        "adminAccess": false,
        "vendorAccess": false,
        "isvendorUser": false
      };
      this.apiResp = getDecryptedUserDetailsResponse;
     
      this.department = this.apiResp.result.department;
      this.setUserInformation();
      this.appServ.setAccess(accessObj);
      this.appServ.sendUserAccessValues(accessObj);
      this.bulkTMSUser = this.apiResp.result.username;
      sessionStorage.setItem('bulkTMSUser', this.bulkTMSUser);
      this.spinner.hide();
      let requestPath = sessionStorage.getItem('returnUrlpath');
      if (requestPath != null && requestPath != undefined && requestPath != "") {
        console.log(requestPath,"checking the return url-- request path",JSON.parse(requestPath),"--","/home")
          if(requestPath=='/home' || requestPath=='home' || JSON.parse(requestPath)=='/home' || JSON.parse(requestPath)=='home'){
            console.log("inside /home")
            this.router.navigate(['/landingpage']);
          }
          else{
            this.router.navigateByUrl(JSON.parse(requestPath));
          }
      }
      else{
        this.router.navigate(['/landingpage']);
      }
    });
  }

  private setUserInformation() {
    sessionStorage.setItem('appAccess', "true");
    sessionStorage.setItem('homeAccess', "true");
    sessionStorage.setItem('requestAccess', "true");
    sessionStorage.setItem('lpAccess',sessionStorage['isVendorUser']=='true'? 'false':'true');
    sessionStorage.setItem('firstName', this.apiResp.result.firstname);
    sessionStorage.setItem('lastName', this.apiResp.result.lastname);
    sessionStorage.setItem('email', this.apiResp.result.email);
    sessionStorage.setItem('BU', this.department);  
  }

  private checkIsUserAlreadyLoggedin() {
    debugger;
    if (this.bulkTMSUserparam != null && this.bulkTMSUserparam != undefined &&
       this.bulkTMSUser != null && this.bulkTMSUser != "undefined" && 
       this.bulkTMSUser != "" && this.bulkTMSUser !== " ")
        {
      let requestPath = sessionStorage.getItem('returnUrlpath');
      if (requestPath != null && requestPath != undefined && requestPath != "") {
        console.log(requestPath,"checking the return url-- request path",JSON.parse(requestPath),"--","/home")
          if(requestPath=='/home' || requestPath=='home' || JSON.parse(requestPath)=='/home' || JSON.parse(requestPath)=='home'){
            console.log("inside /home")
            this.router.navigate(['/landingpage']);
          }
          else{
            this.router.navigateByUrl(JSON.parse(requestPath));
          }      }
      else {
        this.router.navigate(['/landingpage']);
      }
    }
    //  else{
    //   this.SAMLService()
    //  }
  }

  SAMLService() {
    this.spinner.show();
    this.appServ.getSAMLService().subscribe({
      next:(result:any) => {
      this.spinner.hide();
      window.location.href = result.toString();
    },
      error:(error) => {
        this.toast.error(this.translate.instant(error), "Error!");
        this.spinner.hide();
      }
      });
  }

  SAMLExternalService() {
    this.spinner.show();
    this.appServ.getSAMLExternalURL().subscribe({
      next:(result:any) => {
      this.spinner.hide();
      window.location.href = result.toString();
    },
      error:(error) => {
        this.toast.error(this.translate.instant(error), "Error!");
        this.spinner.hide();
      }
      });
  }

}
function setUserAccessInSession(apiGetLoggedUserDataResp: any) {
  sessionStorage.setItem('roleId', apiGetLoggedUserDataResp.lstuser[0].role_id);
  sessionStorage.setItem('appAccess', "true");
  sessionStorage.setItem('homeAccess', "true");
  sessionStorage.setItem('lpAccess', apiGetLoggedUserDataResp.lstroledata.find((i:any)=>i.permissionComponent=='LPAccess').enableAccess);
  
  sessionStorage.setItem('requestAccess', apiGetLoggedUserDataResp.lstroledata.find((i:any)=>i.permissionComponent=='Request').enableAccess);
  sessionStorage.setItem('adminAccess', apiGetLoggedUserDataResp.lstroledata.find((i:any)=>i.permissionComponent=='Admin').enableAccess);
  sessionStorage.setItem('vendorAccess', apiGetLoggedUserDataResp.lstroledata.find((i:any)=>i.permissionComponent=='Vendor Response').enableAccess);
  sessionStorage.setItem('isVendorUser', apiGetLoggedUserDataResp.lstroledata.find((i:any)=>i.permissionComponent=='VAccess').enableAccess);  
}

