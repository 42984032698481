import { ArticleMappingData } from "./article-management.data";

export class ArticleMappingFormData  extends ArticleMappingData
{
    edit:boolean=true;
    editing:boolean=false;
    status:string="";
    

    validArticleNumber:boolean=true;
    validProductName:boolean=true;
    validMFG:boolean=true;
}
export interface ArticleRequest{
    first:number;
    totalRecords:number;
    rowsPerPage:number;
    requestedBy:number;
    filter?:{
        article_number:string;
        product_name:string;
        mfg_code:string;
        mfg_description:string;
        status:string;
    }
}