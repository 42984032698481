import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/internal/operators/map";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn:"root"
})

export class DeliveryTypeService
{
    constructor(private client: HttpClient) { }
    
    retrieveDeliveryTypeList(requestedBy: number){
        let link=environment.getDeliveryType.replace("{requestedby}", requestedBy.toString())
     
        return this.client.get(link).pipe(map((response)=>response));
      } 
      
      submitDeliveyType(data: any) {
        return this.client.post(environment.submitDeliveryType, data).pipe(map((response) => response));
      }
}