export class CleaningCategoryData
{
    mfg_id:number=0;
    mfg_code:string="";
    mfg_description:string="";
    freight_class:string="";
    cleaning_category_code:string="";
    new_rate:string="";
    is_active:boolean=true;
    created_by!:number;
    created_date!:Date;
    modified_by!:number;
    modified_date!:Date;
    
}