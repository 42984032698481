import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { VendorManagementFormData } from "./data/vendor-management-form.data";
import { VenderManagementServices } from "./vendor-management.service";
import { DropDownMaster, DropDownRegionMaster } from "src/app/shared/dropdown-master.data";
import { AppServiceService } from "src/app/services/app-service.service";

@Injectable()

export class VenderManagementModel {
    constructor(private client: VenderManagementServices,private applicaionservice:AppServiceService) { }
    retrieveListHeader() {

        return [
            { field: "vendor_name", header: "Vendor Name", width: "24%" },
            { field: "transport_type", header: "Transport Type", width: "35%" },
            { field:"vendorRegion", header:"Region",width:"12%"},
            { field: "status", header: "Status", width: "12%" },
            { field: "vendor_users", header: "Vendor Users", width: "12%" },
            { field: "edit", header: "", width: "5%" }
        ];
    }

    retrievevendorCollection(requestedBy: number): Observable<VendorManagementFormData[]> {
        let subject = new Subject<VendorManagementFormData[]>();
        let resultCollection: VendorManagementFormData[] = [];
        this.client.retrieveVendorcollection(requestedBy).subscribe({
            next:vendorcollectionResult => {
            let apiResponse: any = vendorcollectionResult;
            let apiResult: any = apiResponse.result;
            if (apiResult.status_id == 200) {
                let apiResultData: any = apiResponse.data;
                apiResultData.forEach((element:any) => {
                    let resultdata = element as VendorManagementFormData;
                    resultdata.edit = true;
                    resultdata.status = resultdata.is_active ? "Active" : "Inactive";
                    resultCollection.push(resultdata);

                });
                subject.next(resultCollection);
            }
            else {
                subject.error(apiResult.message)
            }
        },
           error: error => {
                subject.error(error);
           }
            });
        return subject.asObservable();
    }

    retrieveVendorUsersCollection():Observable<any[]>{
        let subject = new Subject<any[]>();
        let result:any[]=[]
        this.client.retrieveVendorUsersCollection().subscribe({
            next:vendorUsers=>{  
            let apiResponse:any = vendorUsers;   
            if(apiResponse.result.status_id ===200){                
                result=apiResponse.data;
                subject.next(result)
            }
            else {
                subject.error(apiResponse.result.message)
            }
        },
        error:error=>{
            subject.error(error);
        }
        })
        return subject.asObservable();
    }

    retrieveVendorStatus() {
        return[
            { label: "Active", value: true },
            { label: "InActive", value: false }
        ];
       
    }

    validateVendorForm(data: VendorManagementFormData, selectedTransportType: any,selectedRegion:any): VendorManagementFormData {
        data.vendor_name = data.vendor_name.trim();
        data.vendor_email = data.vendor_email.trim();
        data.validVendorName = this.validInput(data.vendor_name) && this.validateTextInput(data.vendor_name);
        data.validVendorTransportType = selectedTransportType.length > 0;
        data.validRegion = selectedRegion.length>0
        return data;
    }


    OnsaveVendor(data: VendorManagementFormData, selectedTransportType: any,selectedRegion:any, userId: number): Observable<string> {

        let subject = new Subject<string>();

        let updateTransportType: any[] = [];
        this.processSelectedTransportType(selectedTransportType, updateTransportType);
        let updatedRegion: any[] =[];
        this.processSelectedRegion(selectedRegion,updatedRegion)
        let vendordata = {
            modified_by: userId,
            vendor_id: data.vendor_id,
            vendor_name: data.vendor_name,
            vendor_email: data.vendor_email,
            is_active: data.is_active,
            transport_type_master: updateTransportType,
            vendor_region :updatedRegion
        }


        if (data.vendor_id != 0 && data.vendor_id != undefined) {
            this.client.updateVendors(vendordata).subscribe({
                next:updatevendorResponse => {
                this.processSaveResponse(updatevendorResponse, subject);
            }
            ,
           error: error => {
                subject.error(error)
           }
            });

        } else {
            this.client.createVendor(vendordata).subscribe(
                {next:createVendorResponse => {
                this.processSaveResponse(createVendorResponse, subject);
            }
            ,
            error:error => {
                subject.error(error)
            }
            });
        }
        return subject.asObservable();
    }


    private processSaveResponse(updatevendorResponse: any, subject: Subject<string>) {
        let apiResponse: any = updatevendorResponse;
        let apiResult: any = apiResponse.result;
        if (apiResult.status_id == 200) {
            subject.next(apiResult.message);
        }
        else {
            subject.error(apiResult.message);
        }
    }


    private processSelectedTransportType(selectedTransportType: any, updateTransportType: any[]) {
        selectedTransportType.forEach((element:any) => {
            let dropdownData = {
                masterId: element,
                masterValue: "",
                masterOrderBy: 0
            };

            updateTransportType.push(dropdownData);
        });
    }

    private processSelectedRegion(selectedRegion: any, updateRegion: any[]) {
        selectedRegion.forEach((element:any) => {
            let dropdownData = {
                masterId: element,
                masterValue: "",
                masterName:"",
                masterOrderBy: 0
            };
    
            updateRegion.push(dropdownData);
        });
    }

    public RetrieveRegionMaster(requestedBy :number,isadmin:boolean,type:string):Observable<DropDownMaster[]>
    {
        let subject=new Subject<DropDownMaster[]>();
        this.applicaionservice.getRegionsData(requestedBy,isadmin,type).subscribe(response=>
            {
                let apiResponse:any =response;
                let apiResponseResult:any=apiResponse.result;
                if(apiResponseResult.status_id===200)
                {
                    let  resultdata:any=apiResponse.data;
                    let datacollection=this.convertResponsetoArray(resultdata);
                    subject.next(datacollection);
                }
                
            });

            return subject.asObservable();
    }

    private  convertResponsetoArray(response:any):DropDownRegionMaster[]
 {
    let result:DropDownRegionMaster[]=[];
    response.forEach((element:any) => {
       let resultdata=new DropDownRegionMaster();
        resultdata.value=Number(element.masterId);
      
        resultdata.label=element.masterValue;
        resultdata.name=element.masterName;
        result.push(resultdata);

     });
     return result;
 }

    validInput(validStr:any) {
        return validStr.length > 1;
    }
    ValidateEmail(validStr:any) {
        let emailReg = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/;
        return emailReg.test(validStr);
    }
    validateUserName(validStr:any) {
        let unRegex = /^[a-zA-Z0-9]+$/;
        return unRegex.test(validStr);
    }

    validateTextInput(validStr:any) {
        let inputText = /^[a-zA-Z\s]+$/;
        return inputText.test(validStr);
    }

    validateCodes(validStr:any) {
        let codeRegex = /^[a-zA-Z0-9]+$/;
        return codeRegex.test(validStr);
    }
}
