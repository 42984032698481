import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable(
    { 
       providedIn:"root" 
    }
)
export class ProductService
{
    constructor(private client:HttpClient)
    {

    }


    retrieveProductInfoBasedonArticleNumber(articleNumber:string)
    {
        return this.client.get(environment.getArticleDetails +`${articleNumber}`).pipe(map((response) => response));
    }
    

}