<div class="container" id="cleaning-category">
    <form autocomplete="off">
      <div class="row">
        <div class="form-group col-12 col-xl-4">
          <label for="mfgDesc">MFG Description</label>
          <input type="text"  autocomplete="off" class="form-control input-class" id="mfgDesc" name="mfgDesc" [(ngModel)]="cleaningCategoryData.mfg_description">
          <span *ngIf="!cleaningCategoryData.validMFGDesc" class="err">*Invalid MFG Description</span>
        </div>
        <div class="form-group col-12 col-xl-4">
          <label for="mfgCode">MFG Code</label>
          <input type="text"  autocomplete="off" class="form-control input-class" id="mfgCode" name="mfgCode" [(ngModel)]="cleaningCategoryData.mfg_code" maxlength="4">
          <span *ngIf="!cleaningCategoryData.validMFGCode" class="err">*Invalid MFG Code</span>
        </div>
        <div class="form-group col-12 col-xl-4">
          <label for="CleaningCode">Cleaning Category Code</label>
          <input type="text"  autocomplete="off" class="form-control input-class" id="CleaningCode" name="CleaningCode" [(ngModel)]="cleaningCategoryData.cleaning_category_code" maxlength="4">
          <span *ngIf="!cleaningCategoryData.validCCCode" class="err">*Invalid Cleaning Category Code</span>
        </div>
        <div class="form-group col-12 col-xl-4" style="display: grid">
          <label for="cleaningCategoryFees">Cleaning Category Fees</label>
          <input type="text"  autocomplete="off" class="form-control input-class" id="cleaningCategoryFees" name="cleaningCategoryFees" [(ngModel)]="cleaningCategoryData.new_rate">
          <span *ngIf="!cleaningCategoryData.validCCFee" class="err">*Invalid Cleaning Category Fee (Please Provide Numerical values)</span>
        </div>
        <div class="form-group col-12 col-xl-4" style="display: grid">
          <label for="freightClass">Freight Class</label>
          <input type="text"  autocomplete="off" class="form-control input-class" id="freightClass" name="freightClass" [(ngModel)]="cleaningCategoryData.freight_class" maxlength="4">
          <span *ngIf="!cleaningCategoryData.validFreightClass" class="err">*Invalid Freight Class</span>
        </div>
        <div class="form-group col-12 col-xl-4" style="display: grid">
          <label for="status">Status</label>
          <p-dropdown name="ccStatus" styleClass="cust-dropdown" [options]="statusOpts" [(ngModel)]="cleaningCategoryData.is_active"
            placeholder="Select Status"></p-dropdown>
        </div>
      </div>
      <div class="row mt-4">
        <div class="form-group col-6 col-md-6" style="margin-bottom: 10px;">
          <p style="text-align: right">
            <button type="button" class="btn btn-secondary cust-btns" (click)="onCancel()">Clear</button>
          </p>
        </div>
        <div class="form-group col-6 col-md-6" style="margin-bottom: 10px;">
          <p style="text-align: left">
            <input *ngIf="!cleaningCategoryData.editing" type="button" class="btn btn-primary cust-btns" style="background-color: #004A96 !important;" (click)="saveCleaningCategory()" value="Create">
            <input *ngIf="cleaningCategoryData.editing" type="button" class="btn btn-primary cust-btns" style="background-color: #004A96 !important;" (click)="saveCleaningCategory()" value="Update">
          </p>
        </div>
      </div>
    </form>
  </div>
  <div class="container" id="userTable" style="margin-top: 20px; margin-bottom: 100px;padding-bottom:20px">
    <p-table #dt2 [columns]="cleaningCategoryTableColumns" [value]="cleaningCategorycollection" [paginator]="true" [rows]="10"
    [pageLinks]="2" [selectionPageOnly]="true" [responsive]="false" [scrollable]="true" [resizableColumns]="true" columnResizeMode="expand"   [tableStyle]="{ 'min-width': '40rem' }">
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
        </colgroup>
    </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th id="col.header" *ngFor="let col of columns">
            {{col.header}}
          </th>
        </tr>
        <tr>
          <th id="thfilter" *ngFor="let col of columns" [ngSwitch]="col.field">
            <input *ngSwitchCase="'mfg_description'" pInputText type="text"
              (input)="dt2.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}">
            <input *ngSwitchCase="'mfg_code'" pInputText type="text"
              (input)="dt2.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}">
            <input *ngSwitchCase="'cleaning_category_code'" pInputText type="text"
              (input)="dt2.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}">
            <input *ngSwitchCase="'freight_class'" pInputText type="text"
              (input)="dt2.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}">
              <input *ngSwitchCase="'status'" pInputText type="text"
              (input)="dt2.filter($event.target.value, col.field, 'contains')" [ngStyle]="{'width': '100%'}">
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns">
            <span *ngIf="col.field == 'status'" class="status-text" [ngClass]="{'status-text-inactive':  rowData[col.field]=='Inactive'}">
              {{rowData[col.field]}}
            </span>
            <span *ngIf="col.field != 'IsActive' && col.field != 'edit' && col.field != 'status'">{{rowData[col.field]}}</span>
            <span *ngIf="col.field == 'IsActive'"><p *ngIf="rowData[col.field] == true">Active</p><p *ngIf="rowData[col.field] == false">InActive</p></span>
            <span *ngIf="col.field == 'edit'">
              <img *ngIf="!ccEditing" alt="Edit" class="accordion-img-icon pointer-cursor" title='Edit Cleaning Category'
                (click)="onRowCCEditInit(rowData)" src="./assets/images/icons/edit_icon.png">
              <img *ngIf="ccEditing && rowData['MfgId'] === cId" alt="Cancel" class="accordion-img-icon pointer-cursor"
                (click)="onCancel()" title='Cancel Editing' src="./assets/images/icons/close_icon.png">
            </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>