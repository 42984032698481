import { Component } from '@angular/core';

@Component({
  selector: 'app-cust-spinner',
  templateUrl: './cust-spinner.component.html',
  styleUrls: ['./cust-spinner.component.css']
})
export class CustSpinnerComponent{
  

}
