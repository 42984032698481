import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DropDownMaster } from "src/app/shared/dropdown-master.data";
import { TransportTypeMasterModel } from "src/app/shared/transport-type-master/transport-type-master.model";
import { LoggecInUserInfo } from "src/app/site-permissions/data/loggedin-user-info";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { VendorManagementFormData } from "../data/vendor-management-form.data";
import { VenderManagementModel } from "../vendor-management.model";
import { AppServiceService } from "src/app/services/app-service.service";
import { Subscription } from "rxjs";


@Component({
    selector: 'app-admin-vendor-management',
    templateUrl: './admin-vendor-management.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [LoggedInUserInfoModel,VenderManagementModel,
      TransportTypeMasterModel
    ]
  })

export class AdminVendorManagementComponent implements OnInit
{

    @Input() loggedinUserinfo!: LoggecInUserInfo;

    vendorFormData: VendorManagementFormData= new VendorManagementFormData();
    vendorCollection:VendorManagementFormData[]=[];
    transportTypeMasterData:DropDownMaster[]=[];
    selectedTransportType:any;

    vendorHeader:any='';
    vendorUsers:any[]=[];
    vendorUsersFiltered:any[]=[];
    displayUsers:boolean=false;
    regionsDD:DropDownMaster[]=[];
    selectedRegion:any;
    selectedRegionvalue:any='';
    
  statusOpts:any;

  messageReceived: any;
  region!: string;

    vendorTableColumns:any;
  vendorSubscriptionName: Subscription;
    constructor(private vendorModel:VenderManagementModel,
      private transportTypeMasterModel:TransportTypeMasterModel,
      private spinner: NgxSpinnerService,
    private toast: ToastrService,private applicaionservice:AppServiceService )
    {
      this.vendorSubscriptionName = this.applicaionservice.getUpdate().subscribe(message => { //message contains the data sent from service
        this.messageReceived = message;
        this.region=sessionStorage['selectedRegion'];
        this.ngOnInit();
        });
    }
    ngOnInit(): void {
      if(this.selectedRegionvalue != this.region){
    this.selectedRegionvalue=this.region;
      this.retrieveTransportType();
      this.retrieveRegionMaster();
      this.retrieveListHeader();
      this.retrieveVendorCollection();
      this.retrieveVendorUsersCollection();
      this.retrieveVendorStatus();
      }
    }
    ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
      this.vendorSubscriptionName.unsubscribe();
  }
retrieveVendorStatus()
  {
   this. statusOpts=this.vendorModel.retrieveVendorStatus();
  }
 retrieveListHeader()
 {
  this.vendorTableColumns=this.vendorModel.retrieveListHeader();
  const screenWidth = window.innerWidth;
  if(screenWidth < 750) {
    this.vendorTableColumns.forEach((col:any) => {
        col.width = (parseInt(col.width.replace('%','')) * 10).toString() + 'px';
    });
  }
 }

 retrieveVendorCollection ()
 {
   this.vendorModel.retrievevendorCollection(Number(sessionStorage['userId'])).subscribe({
   next: vendorCollectionResponse=>{
    this.vendorCollection=vendorCollectionResponse;
   },
   error:error=>
   {
     console.error(error);
   }
   })
 }

 retrieveRegionMaster()
    {
      this.vendorModel.RetrieveRegionMaster(Number(sessionStorage['userId']),sessionStorage["isVendorUser"]=='true','All' ).subscribe({
        next:(regionResponse)=>
        {
         this. regionsDD=regionResponse;
        },
        error:error=>
        {
          console.error(error);
        }
    })

    }

 retrieveVendorUsersCollection(){
    this.vendorModel.retrieveVendorUsersCollection().subscribe({
      next:vendorUserCollection=>{
      this.vendorUsers=vendorUserCollection;
    },
    error:error=>{
      console.error(error);
    }
 });
 }
    retrieveTransportType()
    {
      this.transportTypeMasterModel.RetrieveTransportTypeMaster().subscribe({
        next:transportTypeResponse=>
        {
         this. transportTypeMasterData=transportTypeResponse;
        },
        error:error=>
        {
          console.error(error);
        }
    })

    }


    onRowVendorEditInit(rowData:any) {
      this.vendorFormData.editing = true;
      this.vendorFormData.vendor_id = rowData.vendor_id;
      this.vendorFormData.vendor_name = rowData.vendor_name;
      this.vendorFormData.vendor_email = rowData.vendor_email;
      this.vendorFormData.is_active = rowData.is_active;
      let data:any = rowData.transport_type_master;
      let regionMapped:any=rowData.vendor_region;
      this.selectedTransportType=[];
      this.selectedRegion=[];
      data.forEach((element:any)=>
        {
          let resultdata=new DropDownMaster();
          resultdata.value=Number(element.masterId);
        
          resultdata.label=element.masterValue;
          this.selectedTransportType.push(resultdata.value);
        })
      regionMapped.forEach((element:any)=>{
        let resultdata=new DropDownMaster();
          resultdata.value=Number(element.masterId);
        
          resultdata.label=element.masterValue;
          this.selectedRegion.push(resultdata.value);
      })
    }

    onCancel() {

      this.vendorFormData= new VendorManagementFormData();
      this.selectedTransportType=null;
      this.selectedTransportType=[];
      this.selectedRegion=[];
    }

    displayVendorUsers(rowData:any){
      this.spinner.show();
      this.vendorHeader=rowData.vendor_name;
      this.vendorUsersFiltered=this.vendorUsers.filter(ven=>ven.vendor_id==rowData.vendor_id);
      this.displayUsers=true;
      this.spinner.hide();
      
    }

    saveVendor() {
      this.spinner.show();
      let vendorDatacheck:any=this.vendorCollection.filter((v:any)=>v.vendor_id!=this.vendorFormData.vendor_id)
      this.vendorFormData=    this.vendorModel.validateVendorForm(this.vendorFormData,this.selectedTransportType,this.selectedRegion)
      vendorDatacheck.forEach((v:any)=>{    
        if(this.vendorFormData.vendor_name==v.vendor_name){
          this.vendorFormData.validVendorName=false;
          this.toast.warning("There is already a Vendor with this name.")          
        }
      })
    if(!(this.vendorFormData.validVendorName && this.vendorFormData.validVendorEmail && this.vendorFormData.validRegion))
    {
        this.spinner.hide();
        return false;
      }
  else{
      this.vendorModel.OnsaveVendor(this.vendorFormData,this.selectedTransportType,this.selectedRegion,this.loggedinUserinfo.userId).subscribe({
        next:saveResponse=>
        {
          this.toast.success(saveResponse, "Success");
          this.retrieveVendorCollection();
          this.onCancel();
          this.spinner.hide();
        },
       error: error=>
        {
          this.toast.error(error, "Error!");
          this.spinner.hide();
        }
        })
  return true;
      }
    }
} 
