import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ISODryageTruckMasterData } from "./data/masterdata/iso-dryage-truck-master.data";
import { RequestMasterData } from "./data/masterdata/request-master.data";
import { RequestMasterModel } from "./request-master.model";
import { RequestMasterService } from "./request-master.service";

@Injectable()
/** Handles the funcanality for Rail Rate Request master **/

export class ISODryageTruckMasterModel extends RequestMasterModel
{

    constructor(service: RequestMasterService) {
        super(service);
        console.log("constructor")
    }
    
    public responseOnSuccess(apiResponse: any, subject: Subject<RequestMasterData>) {
        let datacollection: ISODryageTruckMasterData = new ISODryageTruckMasterData();
        datacollection.delivery_type_master=this.convertResponsetoArray(apiResponse.data.delivery_type_master,false);
        datacollection = this.processCommonResult(apiResponse, datacollection) as ISODryageTruckMasterData;
        subject.next(datacollection);
    }

}
