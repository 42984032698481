import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthguardServiceService {
  isLandingPage:boolean=false;
  gettoken() {
    return !!sessionStorage.getItem('appAccess');
    }

  private subjectName = new BehaviorSubject(this.isLandingPage); //need to create a subject
    
  setIsLandingPage(isLandingPage:boolean) { //the component that wants to update something, calls this fn
      this.subjectName.next(isLandingPage); //next() will feed the value in Subject
  }

  checkIsLandingPage(): Observable<any> { //the receiver component calls this function 
      return this.subjectName.asObservable(); //it returns as an observable to which the receiver funtion will subscribe
  }
}
