import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn:"root"
})
export  class ArticelManagementServices
{
    constructor(private client: HttpClient) { }
   

      submitArticleMapping(data: any) {
        return this.client.post(environment.submitArticleProuctList, data).pipe(map((response) => response));
      }

      lazyLoadArticleData(payLoad:any){
        return this.client.post(environment.getArticlesLazyList,payLoad).pipe(map((response) => response));
      }
}