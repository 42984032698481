import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

 @Injectable({providedIn:'root'})

 /**Handeled the  web API communitation for request TransportType */
 export class TransportTypeMasterServices
 {
 
    constructor(private client:HttpClient)
    {

    }
//Retrieve the transport Type from the web API
    RetrieveTransportTypeMaster()
    {
      let isExternalUser:boolean=sessionStorage.getItem('isVendorUser')=='true';
      let userId=sessionStorage.getItem('userId')
      let  api:string=environment.getBulkTransportMasterAPI.replace("{isExternal}",String(isExternalUser)).replace("{userId}",String(userId))
      return this.client.get(api).pipe(map((response)=> response));
    }
 }