import { DropDownMaster } from "src/app/shared/dropdown-master.data";

export class VendorManagementData {

    vendor_id!: number;
    vendor_name: string = "";
    vendor_email: string = "";
    created_by!: number;
    created_date!: Date;
    modified_by!: number;
    modified_date!: Date;
    role_id!: number
    is_active!: boolean;
    transport_type_master: DropDownMaster[] = [];
    vendor_region: DropDownMaster[]=[];

    transport_type:string="";
    vendorRegion:string="";

}