import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn:"root"
})
export  class VenderManagementServices
{
    constructor(private client: HttpClient) { }

    retrieveVendorcollection (requestedBy:number)
    {
        let link=environment.getVendors.replace("{requestedby}", requestedBy.toString())
        return this.client.get(link).pipe(map((response) => response));
        
    }

    retrieveVendorUsersCollection(){
      return this.client.get(environment.getAllVendorUsers.replace("{transportType}","0")).pipe(map((response)=>response));
    }
    createVendor(vendorInfo: any) {
        return this.client.post(environment.addVendor, vendorInfo).pipe(map((response) => response));
      }
    
      updateVendors(vendorInfo: any) {
        return this.client.post(environment.updateVendor, vendorInfo).pipe(map((response) => response));
      }
}