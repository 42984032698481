import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DryRequestDetails } from "src/app/requests/data/formdata/dry-request-details.data";
import { DryTruckRequestFormModel } from "src/app/requests/dry-request-form.model";
import { RequestFormModel } from "src/app/requests/request-form.model";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { RequestDetailsComponent } from "../../request-details-form.component";
import { Subscription } from "rxjs";
import { AppServiceService } from "src/app/services/app-service.service";
import { DryTruckRequestFormData } from "src/app/requests/data/formdata/dry-request-form.data";
import { DropDownMaster } from "src/app/shared/dropdown-master.data";


@Component({
    selector: 'app-dry-request-sa-details',
    templateUrl: '..\\..\\..\\shared-templates\\request-details-form-template.html',
    styleUrls: ['..\\..\\..\\shared-templates\\request-details-form-template.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [LoggedInUserInfoModel,
        { provide: RequestFormModel, useClass: DryTruckRequestFormModel }
    ]
})

export class DryRequestDetailsSAComponent extends RequestDetailsComponent implements OnInit,OnDestroy
{

 private subscriptionName: Subscription; //important to create a subscription
 messageReceived: any;
 pageLanguage:string="";
 is_FISPQ_fileUpload :boolean=false;
  selectedtruckIds: any[]=[];
  override requestData:any;
 
    constructor(userAccessModel: LoggedInUserInfoModel,  requestModel: RequestFormModel,
        toast: ToastrService, router: Router, spinner: NgxSpinnerService, route: ActivatedRoute,private _appServ: AppServiceService) {
        super(userAccessModel,requestModel, toast, router, spinner,route);
        this.subscriptionName= this._appServ.getUpdate().subscribe(message => { //message contains the data sent from service
        this.messageReceived = message;
        
        let newLang=sessionStorage.getItem('selectedLanguage');
        if(this.pageLanguage!=newLang && this.pageLanguage!=""){
          this.ngOnInit();
        }
        });
      }

    ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
        this.subscriptionName.unsubscribe();
    }
    
    ngOnInit(): void {
      this.pageLanguage=sessionStorage['selectedLanguage'];
        this.requestData = new DryTruckRequestFormData();
        this.transportType=2;
        this.validateloggedInUser();
        this.readQueryString();
        this.retrieveHeaderValues();
        this.bindfiledVisiblity();
        
    }
    downLoadSpecialReqAttachment(){console.log('')};
    bindfiledVisiblity()
    {
      
      this.fieldVisiblity.requestType=true;
      this.fieldVisiblity.startDate=true;
      this.fieldVisiblity.requestor=true;
      this.fieldVisiblity.bussinessunit=true;
      this.fieldVisiblity.articleNumber=true;
      this.fieldVisiblity.product=true;
      this.fieldVisiblity.mfg=true;
      this.fieldVisiblity.freightClass=true;
      this.fieldVisiblity.cleaninCost=true;
      this.fieldVisiblity.equipmentType=true;
      
      // Service Provider
      this.fieldVisiblity.volumeType=true;
      this.fieldVisiblity.campaignSeasonalVolume=true;
      this.fieldVisiblity.csVolumeTiming=true;
      this.fieldVisiblity.nitrogenBlanket=true;
      this.fieldVisiblity.vacuumTrailer=true;
      this.fieldVisiblity.cleaningProcedures=true;
      this.fieldVisiblity.mandDocsInsideTruck=true;  
      this.fieldVisiblity.mandDocsInsideTruckDoc=false;
      this.fieldVisiblity.mandDocsInsideTruckSa=true;
  
      
      this.fieldVisiblity.averageProduct=true;
      

      

      this.fieldVisiblity.monthlyVolume=false;
      this.fieldVisiblity.dropTrail=false;
      this.fieldVisiblity.days=false;
      this.fieldVisiblity.hazardous=false;
      this.fieldVisiblity.materialTopLoad=false;
      this.fieldVisiblity.loadingHrs=false;
      this.fieldVisiblity.unloadingHrs=false;

      this.fieldVisiblity.weekendDeliveryYN=false;
      this.fieldVisiblity.priorProductRestriction=false;
      this.fieldVisiblity.priorProdDetailsRestrictions=false;
      //Shipping->Orgin
      this.fieldVisiblity.originPlant=true;
      this.fieldVisiblity.shippingName=true;
      this.fieldVisiblity.orginCity=true;
      this.fieldVisiblity.orginState=true;
      this.fieldVisiblity.orginCountry=true;
      this.fieldVisiblity.orginZipCode=true;
      //shipping-> destination
      this.fieldVisiblity.destinationPlant=true;
      this.fieldVisiblity.consigneeName=true;
      this.fieldVisiblity.destinationCity=true;
      this.fieldVisiblity.destinationState=true;
      this.fieldVisiblity.destinationCountry=true;
      this.fieldVisiblity.destinationZipCode=true;
  
      //Additional Info
      this.fieldVisiblity.comments=true;  
      this.fieldVisiblity.materialDoc=true;
      this.fieldVisiblity.materialLink=true;
      this.fieldVisiblity.sdsLink=true;
      this.fieldVisiblity.sdsAttachment=true;
      this.fieldVisiblity.additionalAttachment=true;
      this.fieldVisiblity.additionalAttachment2=true;
      this.fieldVisiblity.customerPreferedCarrier=false;
      this.fieldVisiblity.costVsService=false;

      if(this.isVendorUser){
        this.fieldVisiblity.requestor=false;
        this.fieldVisiblity.customerPreferedCarrier=false;
        this.fieldVisiblity.costVsService=false;
      }
      
    }
    retrieveHeaderValues() {
        this.title = "Dry Truck";
      }
      bindConditionalFields(){
        let DryReqData=this.requestData as DryRequestDetails;
        
        if (DryReqData.FISPQFileName != undefined && DryReqData.FISPQFileName != "") {

          this.is_FISPQ_fileUpload = true;
        }
        if(this.requestData.mandadoryDocsInsideTruckDetails?.length>0)
        this.selectedtruckIds=[];
        this.requestData.mandadoryDocsInsideTruckDetails.forEach((element:any)=>
          {
            let resultdata=new DropDownMaster();
            resultdata.value=Number(element.masterId);
          
            resultdata.label=element.masterValue;
            this.selectedtruckIds.push(resultdata.value);
            
          })
          this.requestData.mandadoryDocsInsideTruckIdSelected= this.selectedtruckIds;

          if(DryReqData.mandadoryDocsInsideTruck=="Yes" && this.selectedtruckIds.includes(1))
          {
           this.fieldVisiblity.mandDocsInsideTruckDoc=true;
          } else{
           this.fieldVisiblity.mandDocsInsideTruckDoc=false;
         } 
      }
     override downLoadFISPQFile(){
        this.fileDownloaded = false;
        this.openpdfEdge((this.requestData as DryRequestDetails).FISPQAttachment, (this.requestData as DryRequestDetails).FISPQFileName, (this.requestData as DryRequestDetails).FISPQFileExtension);
        this.fileDownloaded = true;
      }
}
